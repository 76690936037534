import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  AddOrgRequest,
  OrganisationFilterType,
  createOrganisation,
  getOrganisationDetail,
  organisationFilter
} from "src/network/graphql/organisationService";
import { immediateToast } from "izitoast-react";
import { getUserByEmail, searchUser } from "src/network/graphql/userService";
import { Users } from "src/page/users/usersSlice";
import { UserDetail } from "../userDetail/userDetailSlice";
import { SubsListRequest, listSubscription } from "src/network/graphql/subscriptionServices";
import { SubsList } from "../plans/plansSlice";

export type orgList = {
  organisationId: string;
  id: number;
  type: string;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
};

const initialUserData: UserDetail = {
  user: {
    id: 0,
    cognitoUserName: "",
    displayId: "",
    active: false,
    email: "",
    userName: "",
    initial: "Mr",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    gender: "male",
    role: [],
    state: "",
    city: "",
    line1: "",
    line2: "",
    zipcode: "",
    country: "",
    accessCode: "",
    fullName: "",
    guardianId: 0,
    kycStatus: "Fail",
    profileImage: ""
  },
  guardian: null,
  canDoActiveInactive: false,
  canEditRole: false,
  canEditGuardian: false,
  canEditKycStatus: false
};

export const initialOrgList: orgList[] = [];

const initialOrgUser: Users[] = [];

const initialSubsList: SubsList[] = [];

const organisationSlice = createSlice({
  name: "organisation",
  initialState: {
    orgListLoading: false,
    addOrgLoading: false,
    orgUserLoading: false,
    subscriptionLoading: false,
    error: null,
    recentCreatedOrganisation: "",
    orgLists: {
      list: initialOrgList,
      total: 0
    },
    orgUser: initialOrgUser,
    userData: initialUserData,
    subscriptionList: {
      list: initialSubsList,
      size: 0
    }
  },
  reducers: {
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    addOrgStart: (state) => {
      state.addOrgLoading = true;
    },
    addOrgSuccess: (state) => {
      state.addOrgLoading = false;
    },
    addOrgFail: (state, action) => {
      state.addOrgLoading = false;
      state.error = action.payload;
    },
    getOrgUserStart: (state) => {
      state.orgUserLoading = true;
    },
    getOrgUserSuccess: (state, action) => {
      state.orgUserLoading = false;
      state.orgUser = action.payload ?? initialOrgUser;
    },
    getOrgUserFail: (state, action) => {
      state.orgUserLoading = false;
      state.orgUser = initialOrgUser;
      state.error = action.payload;
    },
    getUserByEmailStart: (state) => {
      state.orgListLoading = true;
    },
    getUserByEmailSuccess: (state, action) => {
      state.orgListLoading = false;
      state.userData = action.payload ?? initialUserData;
    },
    getUserByEmailFail: (state, action) => {
      state.orgListLoading = false;
      state.userData = initialUserData;
      state.error = action.payload;
    },
    fetchListSubscriptionStart: (state) => {
      state.subscriptionLoading = true;
    },
    fetchListSubscriptionSuccess: (state, action) => {
      state.subscriptionLoading = false;
      state.subscriptionList = action.payload;
    },
    fetchListSubscriptionFail: (state, action) => {
      state.subscriptionLoading = false;
      state.error = action.payload;
    },
    setRecentCreatedOrganisation: (state, action) => {
      state.recentCreatedOrganisation = action.payload;
    }
  }
});

const {
  fetchOrgListStart,
  fetchOrgListSuccess,
  fetchOrgListFail,
  addOrgStart,
  addOrgSuccess,
  addOrgFail,
  getOrgUserStart,
  getOrgUserSuccess,
  getOrgUserFail,
  getUserByEmailStart,
  getUserByEmailFail,
  getUserByEmailSuccess,
  fetchListSubscriptionStart,
  fetchListSubscriptionSuccess,
  fetchListSubscriptionFail
} = organisationSlice.actions;

export const { setRecentCreatedOrganisation } = organisationSlice.actions;

export const fetchOrgListAction = (data: OrganisationFilterType) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchOrgListStart());
    try {
      const response = await organisationFilter(data);

      const structuredResponse = response.data.search_organisation.organisation_listing.map((org: any) => ({
        organisationId: org.organisation_id,
        id: org.display_id,
        active: org.is_active,
        type: org.type,
        name: org.name,
        createdAt: org.created_at,
        referenceName: org.contact.name,
        phone: org.contact.phone
      }));

      dispatch(
        fetchOrgListSuccess({
          list: structuredResponse,
          total: response.data.search_organisation.total_size
        })
      );
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};

interface CreateOrganisationResponse {
  data?: {
    create_organisation: {
      organisation_id: string;
    };
  };
  errors?: Error[];
}

export const addOrgAction = (data: AddOrgRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addOrgStart());
    try {
      const response = (await createOrganisation(data)) as CreateOrganisationResponse;

      if (!response.errors) {
        const organisationDetail = await getOrganisationDetail(
          String(response?.data?.create_organisation.organisation_id)
        );

        dispatch(setRecentCreatedOrganisation(response?.data?.create_organisation.organisation_id));

        dispatch(addOrgSuccess());
        immediateToast("success", {
          message: "Organisation added Sucessfully",
          timeout: 3000,
          position: "topCenter"
        });

        if (cb) cb();

        const data = {
          limit: 100,
          page: 0,
          subscription_filter: {
            get_parent_subscription: true,
            organisation_type: organisationDetail.data.get_organisation.type,
            is_active: true
          }
        };

        fetchListSubscription(data);
      } else {
        immediateToast("error", {
          message: response.errors[0].message,
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(addOrgFail(""));
      }
    } catch (error) {
      dispatch(addOrgFail(error));
    }
  };
};

export const orgUserListAction = (data: { isActive: boolean; email: string }) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgUserStart());
    try {
      const response = await searchUser({
        user_filter: {
          is_active: data.isActive,
          email: data.email
        }
      });

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user.user_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        role: "not available",
        status: user.is_active,
        userId: user.user_id
      }));

      dispatch(getOrgUserSuccess(structuredResponse));
    } catch (error) {
      dispatch(getOrgUserFail(error));
    }
  };
};

export const getUserByEmailAction = (email: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getUserByEmailStart());

    try {
      const response = await getUserByEmail(email);
      const user = response.data.get_user;

      dispatch(
        getUserByEmailSuccess({
          user: {
            id: user.user_id,
            initial: user.attributes.initial,
            firstName: user.attributes.first_name,
            lastName: user.attributes.last_name,
            email: user.email,
            userName: user.username,
            gender: user.attributes.gender,
            dob: user.attributes.date_of_birth,
            role: user.roles,
            phoneNumber: user.phone,
            line1: user.attributes.address.line_1,
            line2: user.attributes.address.line_2,
            city: user.attributes.address.city,
            state: user.attributes.address.state,
            zipcode: user.attributes.address.post_code,
            country: user.attributes.address.country,
            active: user.is_active,
            guardianId: user.guardian_id,
            profileImage: user.attributes.profile_image,
            displayId: user.display_id
          }
        })
      );
    } catch (error) {
      dispatch(getUserByEmailFail(error));
      immediateToast("error", {
        message: "Email not found",
        timeout: 3000,
        position: "topCenter"
      });
    }
  };
};

export const fetchListSubscription = (data: SubsListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchListSubscriptionStart());
    try {
      const response = await listSubscription(data);
      const structuredResponse = response.data.search_subscriptions.items?.map((data: any) => ({
        subsId: data.subscription_id,
        title: data.title,
        subTitle: data.subtitle,
        description: data.description,
        date: data.created_at,
        orgType: data.organisation_type,
        status: data.is_active
      }));

      dispatch(
        fetchListSubscriptionSuccess({
          list: structuredResponse,
          size: response.data.search_subscriptions.total_size
        })
      );
    } catch (error) {
      dispatch(fetchListSubscriptionFail(error));
    }
  };
};

export default organisationSlice.reducer;
