import styles from "./organisationSubscriptionPlan.module.scss";
import { useAppDispatch, useAppSelector } from "src/page/store";
import PricingPlanCard from "src/components/subscriptionPlan/SubscriptionCard/PricingPlanCard";
import { fetchOrganisationSubscriptionAction, upgradePlanAction } from "src/page/auth/loginSlice";
import { Skeleton } from "@mui/material";
import swal from "sweetalert2";
import { useEffect } from "react";
import { SubsList } from "src/page/plans/plansSlice";
import CloseIcon from "@mui/icons-material/Close";

interface OrganisationSubscriptionPlanProp {
  hideNoData?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
}

export default function OrganisationSubscriptionPlan({
  hideNoData = false,
  onClose,
  hideCloseButton = false
}: OrganisationSubscriptionPlanProp) {
  const { cognitoConfig, OrgSubscription, OrgSubscriptionLoading, userInfo, upgradePlanLoading } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cognitoConfig?.organisationSubscriptions)
      dispatch(fetchOrganisationSubscriptionAction(cognitoConfig?.organisationSubscriptions));
  }, [cognitoConfig?.organisationSubscriptions]);

  const upgradePlan = (subscriptionId: string) => {
    console.log(subscriptionId);
    if (userInfo && userInfo?.email && cognitoConfig?.currentOrganisation) {
      const data = {
        user_name: userInfo?.fullName,
        user_email: userInfo?.email,
        user_id: userInfo?.id,
        organisation_name: cognitoConfig?.currentOrganisation?.organisation_name,
        organisation_id: cognitoConfig?.currentOrganisation?.organisation_id
      };
      dispatch(
        upgradePlanAction(data, () => {
          swal.fire({
            icon: "success",
            title: "Your upgrade request has been received. Our support team will reach out to you shortly.",
            showConfirmButton: false,
            timer: 3000
          });
        })
      );
    }
  };

  return (
    <>
      <div className={styles.referModalBox}>
        <main className={styles.referModalTop}>
          <form>
            <div className={styles.close}>
              <h5>Subscriptions</h5>
              {!hideCloseButton && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (onClose) onClose();
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </button>
              )}
            </div>
            {OrgSubscriptionLoading ? (
              <Skeleton variant="rounded" height={300} />
            ) : OrgSubscription?.list?.length > 0 ? (
              <div className={styles.subscriptionList}>
                {OrgSubscription?.list?.map((subscription: SubsList) => (
                  <PricingPlanCard
                    key={subscription.subsId}
                    title={subscription.title}
                    subTitle={subscription.subTitle}
                    description={subscription.description}
                    category={subscription.subscription_category}
                    containerStyles={styles.pricingPlanContainer}
                    titleStyles={styles.pricingPlanTitle}
                    subTitleStyles={styles.pricingPlanSubTitle}
                    onButtonClick={() => {
                      upgradePlan(subscription.subsId);
                    }}
                    loading={upgradePlanLoading}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.noSubs} style={{ display: hideNoData ? "none" : "" }}>
                <p>No Subscription Plan</p>
              </div>
            )}
          </form>
        </main>
      </div>
    </>
  );
}
