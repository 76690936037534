import { useEffect, useState } from "react";
import styles from "./home.module.scss";
import { CircularProgress } from "@mui/material";
import RecentActivity from "src/components/recentActivity/RecentActivity";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchActivityAction, fetchUserListAction, fetchWalletDetailsAction, getUserCardAction } from "./homeSlice";
import { SUPPORTER } from "src/components/constant/constant";
import HomeWalletCard from "src/components/homeWalletCard/HomeWalletCard";
import StackedCarousel from "src/components/homeWalletCard/carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function Home() {
  const dispatch = useAppDispatch();
  const { userInfo, cognitoConfig } = useAppSelector((state) => state.auth);
  const { userCards, transactions, userCardLoading } = useAppSelector((state) => state.home);
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserCardAction());
    }
  }, [dispatch, cognitoConfig?.currentOrganisation?.organisation_id, userInfo]);

  useEffect(() => {
    dispatch(
      fetchActivityAction({
        size: 1,
        from: 0,
        filter: {
          activityName: "NOVATTI_CARD_TRANSACTION",
          activityStatus: "APPROVED"
        },
        userFilter: {
          email: String(userInfo?.email)
        }
      })
    );
  }, [dispatch, userInfo?.email]);

  useEffect(() => {
    if (userInfo?.role === SUPPORTER) {
      const data = {
        size: 25,
        from: 0
      };

      dispatch(fetchUserListAction(data));
    }
  }, [dispatch, userInfo?.email, userInfo?.role]);

  useEffect(() => {
    dispatch(fetchWalletDetailsAction(userCards));
  }, [dispatch, userCards]);

  return (
    <div className={styles.home}>
      {userCardLoading ? (
        <div className={styles.CircularProgress}>
          <CircularProgress sx={{ color: "#0f172a" }} />
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {userCards.length > 1 ? (
            <div className={styles.cardWpagination}>
              <StackedCarousel
                carouselClassName={styles.carouselContainer}
                autoRotate={false}
                rotationInterval={3000}
                leftButton={<ArrowBackIosIcon />}
                rightButton={<ArrowForwardIosIcon />}
                onCardChange={(indexes) => {
                  setActiveCard(indexes.currentIndex);
                }}
              >
                {userCards.map((card, index) => (
                  <div key={`child ${index + 1}`} style={{ display: "flex" }}>
                    <HomeWalletCard key={index} value={index} activeCard={activeCard} card={card} />
                  </div>
                ))}
              </StackedCarousel>
              <div className={styles.pagination}>{`${activeCard + 1} / ${userCards.length}`}</div>
            </div>
          ) : (
            <HomeWalletCard
              key={0}
              value={0}
              activeCard={0}
              card={userCards[0]}
              cardContainerOver={styles.cardContainerOver}
            />
          )}
        </>
      )}

      <div className={`${styles.recentActivityContainer} ${userCards.length > 1 && styles.recentActivityOver}`}>
        <h3>Recent Activity</h3>
        <div className={styles.recentActivity}>
          {transactions?.list?.map((wallet) => <RecentActivity card={wallet} />)}
        </div>
      </div>
    </div>
  );
}
