import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./InternalTransactionListCard.module.scss";
import Button from "@mui/material/Button";
// import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./internalTransTable/TransTable";
import Modalui from "src/components/UI/modal/Modalui";
import TransactionEditModal from "src/components/transactionList/transactionEditModal/TransactionEditModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import FilterTransModal from "./internalFilterTransModal/InternalFilterTransactionModal";
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import {
  TXN_MIN_AMT,
  TXN_MAX_AMT,
  TXN_ORGANISATIONS,
  TXN_PAGE,
  TXN_LIMIT,
  TXN_RECIEPT,
  TXN_REVIEWED
} from "src/components/constant/queryConstants";
import { utc } from "moment";
import { CAN_EDIT_TRANSACTION } from "../constant/constant";
import MockModal from "src/components/transactionList/mockModal/MockModal";
import CircularLoader from "../UI/circularLoader/CircularLoader";
import FilterTag from "../UI/filterTag/FilterTag";
import { FilterTagType, generateFilterTags } from "../UI/filterTag/filterTagUtils";
import { setColumnFilter, setTransactionFilter } from "src/page/filtersSlice";
import { checkPermission, getTimeZone } from "src/utils/utils";
import { immediateToast } from "izitoast-react";
import { User } from "src/types/commonTypes";
import { Wallet } from "src/page/wallets/walletSlice";
import CircularProgress from "@mui/material/CircularProgress";
import TableColumnFilter from "../UI/tableColumnFIlter/TableColumnFilter";
import { Transaction } from "src/page/transactions/transactionSlice";
import {
  downloadTransactionAction,
  fetchTransDataAction,
  fetchTransListAction,
  fetchUserDetailAction,
  fetchWalletDetailAction,
  mockPurchaseAction
} from "src/page/internalTransactions/InternalTransactionSlice";

export default function InternalTransactionListCard() {
  const location = useLocation();
  const { role } = location.state || { role: "" };
  const { walletId, organisationId, userId, transactionId } = useParams();
  const [open, setOpen] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [mockModal, setMockModal] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedTranId, setSelectedTransId] = useState<Transaction>();
  // const [searchText, setSearchText] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { transList, transLoading, userDetail, walletDetail, paymentDataLoading } = useAppSelector(
    (state) => state.internalTransaction
  );
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const { transaction: transactionFilters, column: columnFilter } = useAppSelector((state) => state.filters);
  const dispatch = useAppDispatch();

  const organisationIds = useMemo(() => {
    if (searchParams.get(TXN_ORGANISATIONS)) {
      return searchParams.get(TXN_ORGANISATIONS)?.split("&").map(Number) || [];
    } else if (role === "OrganisationId") {
      return [Number(organisationId)];
    } else if (organisationId) {
      return [Number(organisationId)];
    } else if (cognitoConfig?.currentOrganisation?.organisation_id) {
      return [Number(cognitoConfig?.currentOrganisation?.organisation_id)];
    }
    return [];
  }, [cognitoConfig?.currentOrganisation?.organisation_id, organisationId, role, searchParams]);

  const fetchTransactionData = useCallback(() => {
    const minBalanceParam = searchParams.get(TXN_MIN_AMT);
    const maxBalanceParams = searchParams.get(TXN_MAX_AMT);
    const txnLimit = searchParams.get(TXN_LIMIT) ?? 25;
    const txnPage = searchParams.get(TXN_PAGE) ?? 0;

    let recieptPresent;

    if (searchParams.get(TXN_RECIEPT) === "true") {
      recieptPresent = true;
    } else if (searchParams.get(TXN_RECIEPT) === "false") {
      recieptPresent = false;
    } else {
      recieptPresent = null;
    }

    let isReviewed;

    if (searchParams.get(TXN_REVIEWED) === "true") {
      isReviewed = true;
    } else if (searchParams.get(TXN_REVIEWED) === "false") {
      isReviewed = false;
    } else {
      isReviewed = null;
    }

    const filter: any = {};

    if (transactionFilters.wallet && transactionFilters.wallet.length > 0) {
      filter.walletIds = transactionFilters.wallet.map((wallet) => wallet.id);
    }
    if (transactionFilters.user && transactionFilters.user.length > 0) {
      filter.userIds = transactionFilters.user.map((user) => user.id);
    }
    if (transactionFilters.activityName) {
      filter.activityName = transactionFilters.activityName;
    }
    if (transactionFilters.startDate) {
      filter.created_at = { ...(filter.created_at || {}), min: utc(transactionFilters.startDate).toISOString() };
    }
    if (transactionFilters.endDate) {
      filter.created_at = {
        ...(filter.created_at || {}),
        max: utc(transactionFilters.endDate).endOf("day").toISOString()
      };
    }
    if (walletId) {
      filter.walletIds = [walletId];
    }
    if (userId) {
      filter.userIds = [userId];
    }

    if (transactionId) {
      dispatch(fetchTransDataAction(transactionId));
    } else {
      const data = {
        minRequestedAmount: minBalanceParam ? Number(minBalanceParam) : null,
        maxRequestedAmount: maxBalanceParams ? Number(maxBalanceParams) : null,
        organisationIds,
        receiptPresent: recieptPresent || null,
        isReviewed: isReviewed || null,
        size: Number(txnLimit),
        from: Number(txnLimit) * Number(txnPage),
        filter
      };

      dispatch(fetchTransListAction(data));
    }
  }, [dispatch, organisationIds, searchParams, transactionFilters, userId, walletId, transactionId]);

  const handleOpen = (data: Transaction) => {
    setOpen(true);
    setSelectedTransId(data);
  };

  // const handleSearch = (value: string) => {
  //   setSearchText(value);
  // };

  const handleClose = () => {
    setOpen(false);
    fetchTransactionData();
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(TXN_PAGE, newPage + "");
    setSearchParams(searchParams);
    fetchTransactionData();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(TXN_LIMIT, event.target.value);
    searchParams.delete(TXN_PAGE);
    setSearchParams(searchParams);
    fetchTransactionData();
  };

  const handleOpenFilterTrans = () => {
    setOpenFilter(true);
  };

  const handleCloseFilterTrans = () => {
    setOpenFilter(false);
  };

  const dowmloadTransaction = () => {
    const filter: any = {};

    const startDate = transactionFilters.startDate
      ? utc(transactionFilters.startDate)
      : utc().subtract(90, "days").startOf("day");
    const endDate = transactionFilters.endDate ? utc(transactionFilters.endDate).endOf("day") : utc().endOf("day");

    if (endDate.diff(startDate, "days") > 90) {
      immediateToast("warning", {
        message: "You can only download transactions for a range of 90 days at a time.",
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }

    if (transactionFilters.wallet && transactionFilters.wallet.length > 0) {
      filter.wallet_ids = transactionFilters.wallet.map((wallet) => wallet.id);
    }
    if (transactionFilters.user && transactionFilters.user.length > 0) {
      filter.user_ids = transactionFilters.user.map((user) => user.id);
    }
    if (transactionFilters.activityName) {
      filter.activity_name = transactionFilters.activityName;
    }
    if (walletId) {
      filter.wallet_ids = [walletId];
    }
    if (userId) {
      filter.user_ids = [userId];
    }

    filter.created_at = {
      min: startDate.toISOString(),
      max: endDate.toISOString()
    };

    const data = {
      filter,
      timeZone: String(getTimeZone().name)
    };

    dispatch(downloadTransactionAction(data));
    setDownloadModal(false);
  };

  let operation;

  if (checkPermission(CAN_EDIT_TRANSACTION)) {
    operation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: Transaction) => (
        <div onClick={() => handleOpen(record)} className={styles.operation}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      width: 100
    };
  } else {
    operation = {};
  }

  useEffect(() => {
    fetchTransactionData();
  }, [location.pathname, fetchTransactionData, walletId, dispatch, transactionFilters]);

  const handleMockPurchase = (cardId: string, amount: number, close: boolean) => {
    dispatch(
      mockPurchaseAction(cardId, amount, () => {
        setMockModal(close);
      })
    );
  };

  useEffect(() => {
    if (walletId) {
      dispatch(fetchWalletDetailAction(walletId));
    }
  }, [dispatch, walletId, location.pathname]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetailAction(userId));
    }
  }, [dispatch, location.pathname, userId]);

  const removeTransactionFilter = (filterName: string) => {
    let newFilters;

    if (filterName === "date") {
      newFilters = {
        ...transactionFilters,
        startDate: null,
        endDate: null
      };
    } else if (filterName === "users") {
      newFilters = { ...transactionFilters, user: null };
    } else if (filterName === "wallets") {
      newFilters = { ...transactionFilters, wallet: null };
    } else {
      newFilters = { ...transactionFilters, [filterName]: null };
    }

    dispatch(setTransactionFilter(newFilters));
    fetchTransactionData();
  };

  const removeUserWallet = (userId: string, tagKey: string) => {
    if (tagKey === "users") {
      const newUsers = transactionFilters?.user?.filter((user: User) => user.id !== userId);

      dispatch(setTransactionFilter({ ...transactionFilters, user: newUsers }));
    }
    if (tagKey === "wallets") {
      const newWallets = transactionFilters?.wallet?.filter((wallet: Wallet) => wallet.id !== userId);

      dispatch(setTransactionFilter({ ...transactionFilters, wallet: newWallets }));
    }
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.transaction?.includes(col.key));
  }, [columnFilter?.transaction]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        transaction: selectedColumns
      })
    );
  };

  const updatedColumns = [...filteredColumns, operation];

  return (
    <>
      {selectedTranId && (
        <Modalui open={open} handleClose={handleClose} modaluiOver={styles.modaluiOver}>
          <TransactionEditModal transaction={selectedTranId} handleClose={handleClose} />
        </Modalui>
      )}
      <Modalui
        open={downloadModal}
        handleClose={() => {
          setDownloadModal(false);
        }}
      >
        <div className={styles.referModalBox}>
          <div className={styles.referModalTop}>
            <form>
              <div className={styles.close}>
                <h5>{`The transactions will be downloaded using these filters:`}</h5>
              </div>
              <div className={styles.setting}>
                {!transactionFilters.startDate && !transactionFilters.endDate && (
                  <div className={styles.tag}>
                    <label>{`Date in between ${utc()
                      .subtract(90, "days")
                      .startOf("day")
                      .format("DD MMM YYYY")} - ${utc().endOf("day").format("DD MMM YYYY")}`}</label>
                  </div>
                )}
                {walletId && (
                  <div className={styles.tag}>
                    <label>{"Wallet is " + walletDetail?.wallet?.name}</label>
                  </div>
                )}
                {userId && (
                  <div className={styles.tag}>
                    <label>{"User is " + userDetail?.user?.firstName + " " + userDetail?.user?.lastName}</label>
                  </div>
                )}
                {generateFilterTags(transactionFilters).map((filterTag: FilterTagType) => (
                  <FilterTag
                    key={filterTag.key}
                    tagKey={filterTag.key}
                    label={filterTag.label}
                    onRemove={() => removeTransactionFilter(filterTag.key)}
                    users={transactionFilters?.user}
                    wallets={transactionFilters?.wallet}
                    removeUserWallet={(userId) => removeUserWallet(userId, filterTag.key)}
                  />
                ))}
              </div>
              {!transactionFilters.startDate && !transactionFilters.endDate && (
                <div className={styles.caution}>
                  *Please choose a date range. If no dates are selected, the default range will be set to the past 90
                  days.
                </div>
              )}
            </form>
          </div>
          <div className={styles.referModalBottom}>
            <div className={styles.referModalBtn}>
              <div className={styles.btnL}>
                <Button
                  className={styles.reportBtn}
                  variant="text"
                  onClick={() => {
                    setDownloadModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className={styles.btnR}>
                <Button
                  className={styles.filterBtn}
                  variant="outlined"
                  onClick={() => {
                    setDownloadModal(false);
                    setOpenFilter(true);
                  }}
                >
                  Modify filters
                </Button>
                <Button className={styles.sendBtn} variant="contained" onClick={dowmloadTransaction}>
                  Sure
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modalui>
      <Modalui open={mockModal} handleClose={() => setMockModal(false)} modaluiOver={styles.modaluiOver}>
        <MockModal onAddWallet={handleMockPurchase} handleClose={() => setMockModal(false)} />
      </Modalui>
      <div className={styles.transactionList}>
        <div className={styles.transactionListHead}>
          <div className={styles.transactionListHeadIn}>
            <div className={styles.transactionListHeadD}>
              <h3>Transactions</h3>
              <p>Filter, select and download</p>
            </div>
            <div className={styles.transactionListHeadBtn}>
              {process.env.REACT_APP_BUILD_ENV !== "production" && (
                <Button variant="contained" className={styles.addTransactionBtn} onClick={() => setMockModal(true)}>
                  Mock Purchase
                </Button>
              )}
              {!transactionId && (
                <>
                  <Button variant="outlined" className={styles.filterBtn} onClick={handleOpenFilterTrans}>
                    <div>Filter Transactions</div>
                  </Button>

                  <Button
                    variant="contained"
                    className={styles.addTransactionBtn}
                    onClick={() => setDownloadModal(true)}
                    disabled={paymentDataLoading}
                  >
                    {paymentDataLoading ? (
                      <div className={styles.progress}>
                        <CircularProgress size={24} style={{ color: "white" }} />
                      </div>
                    ) : (
                      "Download Transactions"
                    )}
                  </Button>
                </>
              )}

              <Modalui open={openFilter} handleClose={handleCloseFilterTrans} modaluiOver={styles.filterModalOver}>
                <FilterTransModal handleClose={handleCloseFilterTrans} />
              </Modalui>
            </div>
          </div>
          <div className={styles.transactionListFilter}>
            <div className={styles.filterTagsContainer}>
              {walletId && (
                <div className={styles.tag}>
                  <label>{"Wallet is " + walletDetail?.wallet?.name}</label>
                </div>
              )}
              {userId && (
                <div className={styles.tag}>
                  <label>{"User is " + userDetail?.user?.firstName + " " + userDetail?.user?.lastName}</label>
                </div>
              )}
              {generateFilterTags(transactionFilters).map((filterTag: FilterTagType) => (
                <FilterTag
                  key={filterTag.key}
                  tagKey={filterTag.key}
                  label={filterTag.label}
                  onRemove={() => removeTransactionFilter(filterTag.key)}
                  users={transactionFilters?.user}
                  wallets={transactionFilters?.wallet}
                  removeUserWallet={(userId) => removeUserWallet(userId, filterTag.key)}
                />
              ))}
            </div>
            <TableColumnFilter
              columns={columns}
              selectedColumn={columnFilter?.transaction}
              mandatoryColumns={["id"]}
              onApply={handleColumnSelection}
            />
          </div>
        </div>
        <div className={styles.transactionListTable}>
          <Table
            columns={updatedColumns}
            data={transLoading ? [] : transList.list}
            rowKey={(record) => record.id}
            className={styles.tableIn}
            components={
              transLoading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={transList.total}
            page={searchParams.get(TXN_PAGE) ? Number(searchParams.get(TXN_PAGE)) : 0}
            rowsPerPage={searchParams.get(TXN_LIMIT) ? Number(searchParams.get(TXN_LIMIT)) : 25}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
