import { useState } from "react";
import styles from "./walletWelCard.module.scss";
import { Button } from "@mui/material";
import moneyLogo from "src/assets/images/moneyLogo.png";
import Modalui from "../UI/modal/Modalui";
import VideoModal from "../UI/videoModal/VideoModal";

export default function WalletWelCard() {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <Modalui open={open} handleClose={handleModal} modaluiOver={styles.modaluiOver}>
        <VideoModal handleClose={handleModal} videoLink={"https://www.youtube.com/embed/VB42GbXK4W4?rel=0&loop=1"} />
      </Modalui>
      <div className={styles.welCard} aria-label="Wallet Welcome Card">
        <div className={styles.welText}>
          <div className={styles.welCardTitle}>
            Manage Your Wallets
            <img src={moneyLogo} alt="Money logo"></img>
          </div>
          <div className={styles.welCardText}>Control all of your wallets in one place</div>
        </div>
        <div className={styles.welBtn}>
          <Button variant="contained" onClick={handleModal} aria-label="Watch Wallet demo video">
            Watch Wallet demo
          </Button>
        </div>
      </div>
    </>
  );
}
