import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { getSignedUrlFromS3 } from "src/utils/utils";

interface DownloadInternalFileProp {
  bucketName: string;
  bucketKey: string;
}

export default function DownloadInternalFile({ bucketName, bucketKey }: DownloadInternalFileProp) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = () => {
    setLoading(true);
    getSignedUrlFromS3(bucketName, bucketKey)
      .then((url: any) => {
        window.open(url, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  return (
    <Button
      variant="contained"
      onClick={handleDownload}
      disabled={loading}
      sx={{ backgroundColor: "#0f172a", textTransform: "none" }}
    >
      {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Download"}
    </Button>
  );
}
