import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  getActivity,
  searchAsset,
  searchAssetContent,
  searchAssetContentRequest,
  searchAssetRequest,
  settleTransaction
} from "src/network/graphql/transactionService";
import { InternalFiles } from "../internalFile/internalIFileSlice";
import { initialWalletDetail } from "../walletDetail/walletDetailSlice";
import { getWalletDetail } from "src/network/graphql/walletService";

export type InternalFilesContent = {
  contentId: string;
  assetType: string;
  processStatus: string;
  row: string;
  novattiAmount: string;
  spendableAmount: string;
  ppan: string;
  approvalCode: string;
  settlementStatus: string;
  linkId: string;
  linkType: string;
  prevBalance: string;
  currentBalance: string;
  data: any;
  errMsg: string;
};

const initialFile: InternalFilesContent[] = [];

const initialFileData: InternalFiles[] = [];

const internalfileContentSlice = createSlice({
  name: "files",
  initialState: {
    loading: false,
    walletDetailLoading: false,
    settleTransactionLoading: false,
    reloadFileContent: false,
    error: null,
    internalfilesContent: {
      list: initialFile,
      size: 0
    },
    internalfilesdetail: {
      list: initialFileData,
      size: 0
    },
    walletDetail: initialWalletDetail
  },
  reducers: {
    fetchFilesContentStart: (state) => {
      state.loading = true;
    },
    fetchFilesContentSuccess: (state, action) => {
      state.loading = false;
      state.internalfilesContent = action.payload;
    },
    fetchFilesContentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchFilesDataStart: (state) => {
      state.loading = true;
    },
    fetchFilesDataSuccess: (state, action) => {
      state.loading = false;
      state.internalfilesdetail = action.payload;
    },
    fetchFilesDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchWalletDetailStart: (state) => {
      state.walletDetailLoading = true;
    },
    fetchWalletDetailSuccess: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail.wallet = action.payload.wallet ?? initialWalletDetail.wallet;
    },
    fetchWalletDetailFail: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail = initialWalletDetail;
      state.error = action.payload;
    },
    settleTransactionStart: (state) => {
      state.settleTransactionLoading = true;
    },
    settleTransactionSuccess: (state) => {
      state.settleTransactionLoading = false;
    },
    settleTransactionFail: (state, action) => {
      state.settleTransactionLoading = false;
      state.error = action.payload;
    },
    setReloadFileContent: (state, action) => {
      state.reloadFileContent = action.payload;
    }
  }
});

export const {
  fetchFilesContentStart,
  fetchFilesContentSuccess,
  fetchFilesContentFail,
  fetchFilesDataStart,
  fetchFilesDataSuccess,
  fetchFilesDataFail,
  fetchWalletDetailStart,
  fetchWalletDetailSuccess,
  fetchWalletDetailFail,
  settleTransactionStart,
  settleTransactionSuccess,
  settleTransactionFail,
  setReloadFileContent
} = internalfileContentSlice.actions;

export const fetchFilesContentAction = (data: searchAssetContentRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchFilesContentStart());
    try {
      const response = await searchAssetContent(data);
      const structuredResponse = response.data.search_file_content.file_content_list?.map((data: any) => ({
        contentId: data.content_id,
        assetType: data.file_type,
        row: data.attributes.row_num,
        processStatus: data.process_status,
        novattiAmount: data.attributes.novatti_amount,
        spendableAmount: data.attributes.spendable_amount,
        ppan: data.attributes.ppan,
        approvalCode: data.attributes.approval_code,
        settlementStatus: data.attributes.settlement_status,
        linkId: data.link_id,
        linkType: data.link_type,
        prevBalance: data.attributes.balance_before_deposit,
        currentBalance: data.attributes.balance_after_deposit,
        data: JSON.parse(data.data),
        errMsg: data.err_message
      }));

      dispatch(
        fetchFilesContentSuccess({
          list: structuredResponse,
          size: response.data.search_file_content.total_size
        })
      );
    } catch (error) {
      dispatch(fetchFilesContentFail(error));
    }
  };
};

export const fetchFilesDataAction = (data: searchAssetRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchFilesDataStart());
    try {
      const response = await searchAsset(data);
      const structuredResponse = response.data.search_file.files?.map((data: any) => ({
        assetId: data.file_id,
        bucketKey: data.attributes.key,
        bucketName: data.attributes.bucket_name,
        date: data.attributes.date,
        processStatus: data.process_status,
        assetType: data?.file_type,
        fileName: data?.attributes?.original_filename,
        totalRow: data.attributes.total_rows
      }));

      dispatch(
        fetchFilesDataSuccess({
          list: structuredResponse,
          size: response.data.search_file.total_size
        })
      );
    } catch (error) {
      dispatch(fetchFilesDataFail(error));
    }
  };
};

export const fetchWalletDetailAction = (id: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletDetailStart());
    try {
      const response = await getActivity({ activity_id: id });
      const data = JSON.parse(response.data.get_activity.data);
      const walletResponse = await getWalletDetail(data?.wallet_id);
      const wallet = walletResponse.data.get_wallet;
      const structuredWalletResponse = {
        name: wallet?.name,
        description: wallet?.description,
        balance: wallet?.balance,
        id: wallet?.wallet_id,
        displayId: wallet?.display_id,
        participant: {
          id: wallet.user.user_id,
          fullName: `${wallet.user?.attributes?.first_name ?? "-"} ${wallet.user?.attributes?.last_name ?? "-"}`,
          firstName: wallet.user?.attributes?.first_name ?? "-",
          lastname: wallet.user?.attributes?.last_name ?? "-",
          role: wallet.user?.role ?? "PARTICIPANT",
          email: wallet.user?.email,
          phoneNumber: wallet.user?.phone
        }
      };

      dispatch(
        fetchWalletDetailSuccess({
          wallet: structuredWalletResponse
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchWalletDetailFail(error));
    }
  };
};

export const settleTransactionAction = (id: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(settleTransactionStart());
    try {
      await settleTransaction(id);
      dispatch(settleTransactionSuccess());
      dispatch(setReloadFileContent(true));
      if (cb) cb();
    } catch (error) {
      dispatch(settleTransactionFail(error));
    }
  };
};

export default internalfileContentSlice.reducer;
