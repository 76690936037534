import { lazy, useEffect, useState } from "react";
import styles from "./tableColumnFilter.module.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, FormControlLabel, Switch, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LazyModalui = lazy(() => import("../modal/Modalui"));

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0f172a",
          opacity: 1,
          border: 0
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5
        }
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff"
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 15,
      height: 16
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#0f172a5e" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500
      })
    }
  })
);

interface TableColumnFilterProps {
  columns: any[];
  selectedColumn: string[];
  mandatoryColumns?: string[];
  onApply: (selectedColumns: string[]) => void;
  iconContanerClass?: string;
}

export default function TableColumnFilter({
  columns,
  selectedColumn,
  mandatoryColumns = [],
  onApply,
  iconContanerClass
}: TableColumnFilterProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  useEffect(() => {
    setSelectedColumns(selectedColumn);
  }, [selectedColumn]);

  const handleToggle = (key: string) => {
    setSelectedColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key || mandatoryColumns.includes(col)) : [...prev, key]
    );
  };

  const handleSelectAll = () => {
    setSelectedColumns(columns.map((col) => col.key));
  };

  const handleDeselectAll = () => {
    setSelectedColumns([...mandatoryColumns]);
  };

  const handleApply = () => {
    onApply(selectedColumns);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LazyModalui open={open} handleClose={handleClose}>
        <div className={styles.columnFilter} role="dialog" aria-labelledby="column-filter-title">
          <header className={styles.columnFilterHeader}>
            <h2 id="column-filter-title" className={styles.columnFilterTitle}>
              Column Filter
            </h2>
            <button onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </button>
          </header>
          <main className={styles.columnFilterBody}>
            <div className={styles.columnFilterSelect}>
              <Button variant="contained" onClick={handleSelectAll} aria-label="Select All">
                Select All
              </Button>
              <Button variant="contained" onClick={handleDeselectAll} aria-label="Deselect All">
                Deselect All
              </Button>
            </div>
            <nav className={styles.columnFilterList} aria-label="Column selection">
              {columns.map(
                (column) =>
                  !mandatoryColumns.includes(column.key) && (
                    <FormControlLabel
                      key={column.key}
                      className={styles.columnFilterItem}
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={column.title}
                      labelPlacement="start"
                      checked={selectedColumns.includes(column.key)}
                      onChange={() => handleToggle(column.key)}
                    />
                  )
              )}
            </nav>
            <div className={styles.columnFilterBtn}>
              <Button variant="contained" onClick={handleApply} aria-label="Apply changes">
                Apply
              </Button>
            </div>
          </main>
        </div>
      </LazyModalui>
      <div className={`${styles.columnSettingBtn} ${iconContanerClass}`}>
        <button className={styles.icon} onClick={() => setOpen(true)} aria-label="Open column filter settings">
          <SettingsIcon />
        </button>
      </div>
    </>
  );
}
