import styles from "./internalTransactions.module.scss";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import InternalTransactionListCard from "src/components/internalTransactionList/InternalTransactionListCard";

function InternalTransactions() {
  return (
    <div className={styles.transactions}>
      <div className={styles.transactionWelCard}>
        <TransactionWelCard
          heading={"View Your Transactions"}
          subHeading={"View details of all the transactions on your wallets"}
        />
      </div>
      <div className={styles.transactionTableCard}>
        <InternalTransactionListCard />
      </div>
    </div>
  );
}

export default InternalTransactions;
