import { useCallback, useState } from "react";
import styles from "./addFund.module.scss";
import { addFundFields, walletDetailField } from "./data";
import { Autocomplete, Button, TextField } from "@mui/material";
import { debounce } from "lodash";
import {
  fetchParticipantWalletAction,
  fetchParticpantAction,
  resetParticipantWallet
} from "src/page/dashboard/dashboardSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { Users } from "src/page/users/usersSlice";
import { Wallet } from "src/page/wallets/walletSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function AddFund() {
  const dispatch = useAppDispatch();
  const { participants, participantWallet } = useAppSelector((state) => state.dashboard);
  const [showfunds, setShowfunds] = useState<boolean>(false);
  const [fundsmessage, setfundsmessage] = useState<boolean>(true);
  const [participant, setParticipant] = useState<Users | null>(null);
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [error, setEror] = useState({ participant: "", wallet: "" });

  const fetchWalletList = useCallback(
    (userId: string) => {
      dispatch(fetchParticipantWalletAction(userId));
    },
    [dispatch]
  );

  const handleUserChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    // fetchUserList();
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchParticpantAction(data));
  }, 500);

  const handleAddFund = () => {
    if (!participant?.id || !wallet?.id) {
      setEror({
        participant: participant?.id ? "" : "Please select a participant",
        wallet: wallet?.id ? "" : "Please select a wallet"
      });
      return;
    }
    setEror({ participant: "", wallet: "" });
    setShowfunds(!showfunds);
  };

  return (
    <main className={styles.modalBody}>
      {showfunds ? (
        <section aria-label="Fund Transfer Instructions">
          <div className={styles.bckBtn}>
            <p>
              Load money to your Spendable wallet by making a bank transfer to the below
              <strong> account details</strong>:
            </p>
            <Button variant="contained" onClick={() => setShowfunds(false)}>
              Back
            </Button>
          </div>
          <div className={styles.details}>
            {addFundFields(wallet).map((field) => (
              <div key={field.key} className={styles.fRow}>
                <div className={styles.item}>{field.key}</div>
                <div className={styles.item}>:</div>
                <div className={styles.item}>
                  <strong>{field.value}</strong>
                </div>
              </div>
            ))}
          </div>
          <p>Ensure you include the ID number in the description to make sure we get it to the right participant.</p>
          <p className={styles.note}>*Deposits can take 1-2 business days to process</p>
          <section className={styles.walletDetail} aria-label="Wallet Details">
            <h4>Wallet Details</h4>
            <div className={styles.details}>
              {walletDetailField(wallet).map((field) => (
                <div key={field.key} className={styles.fRow}>
                  <div className={styles.item}>{field.key}</div>
                  <div className={styles.item}>:</div>
                  <div className={styles.item}>
                    <strong>{field.value}</strong>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </section>
      ) : (
        <section className={styles.participantselect} aria-label="Participant and Wallet Selection">
          {fundsmessage ? (
            <div className={styles.modalMessage}>
              <h1 className={styles.modalHeader}>
                Depositing money to your SpendAble wallet is as easy as making a simple bank transfer
              </h1>
              <div className={styles.addFundBody}>
                1. Log into your online banking app <br></br>2. Transfer or pay to a new account <br></br> 3. Enter the
                account details of the wallet you want to deposit to
              </div>
              <p>Find the details of the wallet by selecticing participant & wallet you want.</p>
              <Button
                variant="contained"
                className={styles.fundBtn}
                onClick={() => {
                  setfundsmessage(false);
                }}
              >
                Select Wallet
              </Button>
            </div>
          ) : (
            <>
              <div>
                <Autocomplete
                  options={participants?.list}
                  getOptionLabel={(option) => option?.firstName}
                  filterOptions={(x) => x}
                  value={participant}
                  onInputChange={handleUserChange}
                  onChange={(event, newValue) => {
                    setParticipant(newValue);
                    setWallet(null);
                    dispatch(resetParticipantWallet({ list: [] }));
                    fetchWalletList(String(newValue?.userId));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Participant" placeholder="type to search...." />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <span>{option?.firstName + " " + option?.lastName}</span>
                      <span style={{ color: "#64748B" }}>{option?.email}</span>
                    </li>
                  )}
                />
                {error.participant && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.participant}
                  </div>
                )}
              </div>
              <div>
                <Autocomplete
                  options={participantWallet?.wallets}
                  getOptionLabel={(option) => option.name || ""}
                  value={wallet}
                  onChange={(event, newValue) => {
                    setWallet(newValue);
                    dispatch(resetParticipantWallet({ list: [] }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Wallet" placeholder="type to search...." />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <span>{option.name}</span>
                      <span style={{ color: "#64748B" }}>{option.participant?.email}</span>
                    </li>
                  )}
                />
                {error.wallet && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.wallet}
                  </div>
                )}
              </div>
              <Button variant="contained" onClick={handleAddFund} className={styles.fundBtn}>
                Add Fund
              </Button>
            </>
          )}
        </section>
      )}
      {/* <div className={styles.bckBtn}>
        <Button
          variant="contained"
          onClick={() => {
            setShowfunds(!showfunds);
          }}
        >
          {!showfunds ? "Add Fund" : "back"}
        </Button>
      </div> */}
    </main>
  );
}

export default AddFund;
