import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import styles from "./plans.module.scss";
import Table from "rc-table";
import { Button, TablePagination } from "@mui/material";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import { useAppDispatch, useAppSelector } from "../store";
import columns from "./plansConfig";
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LIMIT, PAGE } from "src/components/constant/queryConstants";
import Modalui from "src/components/UI/modal/Modalui";
import { setColumnFilter, setPlansFilters } from "../filtersSlice";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";
import AddIcon from "@mui/icons-material/Add";
import { SubsList, createSubscriptionAction, fetchListSubscription } from "./plansSlice";
import OnBoardingSuccess from "src/components/onBoardingComponent/OnBoardingSuccessCard";
import Success from "src/assets/images/success1.png";
import { SubsRequest } from "src/network/graphql/subscriptionServices";
import { FilterTagType, generateFilterTags } from "src/components/UI/filterTag/filterTagUtils";
import FilterTag from "src/components/UI/filterTag/FilterTag";
const CreatePlan = lazy(() => import("src/components/subscriptionPlan/createPlan/CreatePlan"));
const PlansFilter = lazy(() => import("src/components/subscriptionPlan/plansFilter/PlansFilter"));
const SubscriptionPreference = lazy(
  () => import("src/components/subscriptionPlan/subscriptionPreference/subscriptionPreference")
);
const OperationColumn = lazy(() => import("src/components/subscriptionPlan/operationColumn/OperationColumn"));

export default function Plans() {
  const dispatch = useAppDispatch();
  const { subscriptionList, loading, recentCreatedPlanSubId, createSubLoading } = useAppSelector(
    (state) => state.plans
  );
  const { column: columnFilter, plans: plansFilter } = useAppSelector((state) => state.filters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCreateSubscription, setOpenCreateSubscription] = useState<boolean>(false);
  const [openPlanPreference, setOpenPlanPreference] = useState<boolean>(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [createSubscriptionSuccess, setCreateSubscriptionSuccess] = useState<boolean>(false);
  const limit = searchParams.get(LIMIT) ?? "10";
  const page = searchParams.get(PAGE) ?? "0";

  const fetchSubscriptionList = useCallback(() => {
    const subscription_filter: any = {
      get_parent_subscription: true
    };

    if (plansFilter.category) {
      subscription_filter.subscription_category = plansFilter.category;
    }

    if (plansFilter.status === "ACTIVE") {
      subscription_filter.is_active = true;
    } else if (plansFilter.status === "INACTIVE") {
      subscription_filter.is_active = false;
    }

    if (plansFilter.type) {
      subscription_filter.organisation_type = plansFilter.type;
    }

    if (plansFilter.standard) {
      subscription_filter.standard = plansFilter.standard;
    }

    const data = {
      size: Number(limit),
      from: Number(page) * Number(limit),
      subscription_filter
    };

    dispatch(fetchListSubscription(data));
  }, [dispatch, page, limit, plansFilter]);

  useEffect(() => {
    fetchSubscriptionList();
  }, [plansFilter, location.pathname, fetchSubscriptionList]);

  const removeTransactionFilter = (filterName: string) => {
    const newFilters = { ...plansFilter, [filterName]: null };
    dispatch(setPlansFilters(newFilters));
  };

  const handleCreatePlan = (data: SubsRequest, standardSubscription: boolean) => {
    dispatch(
      createSubscriptionAction(data, standardSubscription, () => {
        setOpenCreateSubscription(false);
        fetchSubscriptionList();
        setCreateSubscriptionSuccess(true);
      })
    );
  };

  const handleOpenPlanPreference = () => {
    setCreateSubscriptionSuccess(false);
    setOpenPlanPreference(true);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(LIMIT, event.target.value as string);
    searchParams.set(PAGE, "0");
    setSearchParams(searchParams);
  };

  const operation = {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (text: any, card: SubsList) => (
      <Suspense fallback={<CircularLoader />}>
        <OperationColumn onUpdate={fetchSubscriptionList} data={card} />
      </Suspense>
    )
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.plans?.includes(col.key));
  }, [columnFilter?.plans]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        plans: selectedColumns
      })
    );
  };

  const updatedColumns = [...filteredColumns, operation];

  const rowClassName = (record: SubsList) => {
    return record.standard === "Y" ? styles.standardRow : "";
  };

  return (
    <>
      <Modalui open={openFilterCard} handleClose={() => setOpenFilterCard(false)} modaluiOver={styles.filterModalOver}>
        <Suspense fallback={<CircularLoader />}>
          <PlansFilter
            handleClose={() => {
              setOpenFilterCard(false);
            }}
          />
        </Suspense>
      </Modalui>
      <Modalui open={openCreateSubscription} handleClose={() => setOpenCreateSubscription(false)}>
        <Suspense fallback={<CircularLoader />}>
          <CreatePlan
            handleClose={() => setOpenCreateSubscription(false)}
            loading={createSubLoading}
            onSubmit={handleCreatePlan}
          />
        </Suspense>
      </Modalui>
      <Modalui open={openPlanPreference} handleClose={() => setOpenPlanPreference(false)}>
        <Suspense fallback={<CircularLoader />}>
          <SubscriptionPreference
            handleClose={() => setOpenPlanPreference(false)}
            onSuccess={() => {
              fetchSubscriptionList();
            }}
            subscriptionId={recentCreatedPlanSubId}
          />
        </Suspense>
      </Modalui>
      <Modalui
        open={createSubscriptionSuccess}
        handleClose={() => {
          setCreateSubscriptionSuccess(false);
        }}
      >
        <OnBoardingSuccess
          onNext={handleOpenPlanPreference}
          onBack={() => setCreateSubscriptionSuccess(false)}
          heading="Hurray! Your Subscription Plan has been created."
          subHeading="Just few Steps left for limit preference, permission preference and menu preference to your Subscription Plan."
          buttonText={{ primaryButton: "Sure", secondaryButton: "I'll do it later" }}
          image={Success}
        />
      </Modalui>
      <div className={styles.wrapper}>
        <div className={styles.WelCard}>
          <TransactionWelCard
            heading={"View Your Subscription Plans"}
            subHeading={"View details of all the subscription plans"}
          />
        </div>
        <div className={styles.list}>
          <div className={styles.listHead}>
            <div className={styles.listHeadIn}>
              <div className={styles.listHeadD}>
                <h3>Subscription Plans list</h3>
                <p>Manage all the subscription plans</p>
              </div>
              <div className={styles.listHeadBtn}>
                <Button
                  variant="contained"
                  className={styles.createPlan}
                  onClick={() => setOpenCreateSubscription(true)}
                >
                  <AddIcon fontSize="small" />
                  Create Plan
                </Button>
                <Button variant="contained" className={styles.filterBtn} onClick={() => setOpenFilterCard(true)}>
                  Filters
                </Button>
              </div>
            </div>
            <div className={styles.listFilter}>
              <div className={styles.filterTagsContainer}>
                {generateFilterTags(plansFilter).map((filterTag: FilterTagType) => (
                  <FilterTag
                    key={filterTag.key}
                    tagKey={filterTag.key}
                    label={filterTag.label}
                    onRemove={() => removeTransactionFilter(filterTag.key)}
                  />
                ))}
              </div>
              <TableColumnFilter
                columns={columns}
                selectedColumn={columnFilter?.plans}
                mandatoryColumns={["subsId"]}
                onApply={handleColumnSelection}
                iconContanerClass={styles.tableColumnContainer}
              />
            </div>
          </div>
          <div className={styles.listTable}>
            <Table
              columns={updatedColumns}
              data={loading ? [] : subscriptionList.list}
              className={styles.tableIn}
              rowClassName={rowClassName}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={subscriptionList.size ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(limit)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
