import styles from "src/page/activity/activity.module.scss";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Avatar } from "@mui/material";
import { bgColorbyName } from "src/variable/randomColor";
// import SpeedDial from "@mui/material/SpeedDial";
// import SpeedDialIcon from "@mui/material/SpeedDialIcon";
// import SpeedDialAction from "@mui/material/SpeedDialAction";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";

// import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState, useRef, useEffect } from "react";
import { Transaction } from "src/page/transactions/transactionSlice";
import poweredBy from "src/assets/images/no_image.png";
import { transactionPhoto } from "src/network/graphql/transactionService";
import { dateTimeFormat, getDecimalNumber, getSignedUrlFromS3, processFileName, uploadFileToS3 } from "src/utils/utils";
import { ListAsset } from "src/types/commonTypes";
import PhotoCard, { AssetUrl } from "../photoCard/PhotoCard";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { deleteFile } from "src/page/activity/activitySlice";
import LoadingModal from "../UI/loadingModal/LoadingModal";
import config from "src/config/Config";

interface Activityprops {
  card: Transaction;
  onSelectedCard: (_id: string) => void;
  onResetSelection: () => void;
  isSelected: boolean;
}

export default function ActivityCard({ card, onSelectedCard, onResetSelection, isSelected }: Activityprops) {
  const dispatch = useAppDispatch();
  const { deletePhotoLoading } = useAppSelector((state) => state.activity);
  const [photos, setPhotos] = useState<AssetUrl[]>([]);
  const [receipts, setReceipts] = useState<AssetUrl[]>([]);
  const [photoCard, setPhotoCard] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);
  const [photosUpdated, setPhotosUpdated] = useState(false);
  // const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const hiddenAddReceipt = useRef<HTMLInputElement>(null);
  const hiddenAddPhoto = useRef<HTMLInputElement>(null);

  // const handleAddPhotoClick = () => {
  //   const hiddenAddPhotoInput = document.getElementById("hiddenAddPhotoInput");

  //   if (hiddenAddPhotoInput) {
  //     hiddenAddPhotoInput.click();
  //   }
  // };

  // Function to simulate file input click for adding a receipt
  // const handleAddReceiptClick = () => {
  //   const hiddenAddReceiptInput = document.getElementById("hiddenAddReceiptInput");

  //   if (hiddenAddReceiptInput) {
  //     hiddenAddReceiptInput.click();
  //   }
  // };

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const photoResponse = await transactionPhoto("PHOTO", card?.id);
        const receiptResponse = await transactionPhoto("RECEIPT", card?.id);

        const photoAssets: ListAsset[] = photoResponse.data.list_assets.asset.filter(
          (asset: ListAsset) => asset.asset_status === "ACTIVE"
        );
        const receiptAssets: ListAsset[] = receiptResponse.data.list_assets.asset.filter(
          (asset: ListAsset) => asset.asset_status === "ACTIVE"
        );

        const photoUrls = await Promise.all(
          photoAssets.map((photo: ListAsset) => getSignedUrlFromS3(photo.attributes.bucket_name, photo.attributes.key))
        );

        const receiptUrls = await Promise.all(
          receiptAssets.map((receipt: ListAsset) =>
            getSignedUrlFromS3(receipt.attributes.bucket_name, receipt.attributes.key)
          )
        );

        setPhotos(
          photoAssets.map((photo: ListAsset, index: number) => ({
            id: photo.attributes.key,
            url: photoUrls[index],
            assetId: photo.asset_id
          }))
        );
        setReceipts(
          receiptAssets.map((receipt: ListAsset, index: number) => ({
            id: receipt.attributes.key,
            url: receiptUrls[index],
            assetId: receipt.asset_id
          }))
        );
      } catch (error) {
        console.error("Error fetching assets:", error);
      }

      setPhotosUpdated(false);
    };

    fetchAssets();
  }, [card?.id, photosUpdated]);

  const handleFileUpload = async (file: File, type: "PHOTO" | "RECEIPT") => {
    const data = {
      file: file,
      fileName: processFileName(file.name, card?.id),
      S3Bucket: config?.S3_ASSET_BUCKET,
      MetaData: {
        object_id: card?.id,
        object_type: "TRANSACTION",
        asset_type: type
      }
    };
    await uploadFileToS3(data);

    if (type === "PHOTO") {
      setPhotosUpdated(true);
      setSelectedButton(0);
    } else {
      setPhotosUpdated(true);
      setSelectedButton(1);
    }
  };

  const handlePhotoDelete = async (assetId: string) => {
    dispatch(
      deleteFile(assetId, () => {
        setPhotosUpdated(true);
      })
    );
  };

  const handleBack = () => {
    if (photoCard) {
      setPhotoCard(false);
    } else {
      onResetSelection();
    }
  };

  return (
    <>
      {deletePhotoLoading ? <LoadingModal /> : ""}
      {isSelected && (
        <div className={styles.back}>
          <Button variant="contained" className={styles.backbtn} onClick={handleBack}>
            <ArrowBackIcon />
            Back
          </Button>
        </div>
      )}
      <div className={styles.activityContainer}>
        {photoCard ? (
          <div className={styles.photoCard}>
            <div className={styles.togglebutton}>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                  style={{
                    backgroundColor: selectedButton === 0 ? "#1e293b" : "#D9D9D9",
                    border: selectedButton === 0 ? "1px solid #000" : "none",
                    borderRight: selectedButton === 0 ? "1px solid #000" : "none",
                    color: selectedButton === 0 ? "#ffff" : "#000000"
                  }}
                  onClick={() => {
                    setSelectedButton(0);
                  }}
                >
                  Photo
                </Button>
                <Button
                  style={{
                    backgroundColor: selectedButton === 1 ? "#1e293b" : "#D9D9D9",
                    border: selectedButton === 1 ? "1px solid #000" : "none",
                    color: selectedButton === 1 ? "#ffff" : "#000000",
                    marginLeft: "0px"
                  }}
                  onClick={() => {
                    setSelectedButton(1);
                  }}
                >
                  Receipt
                </Button>
              </ButtonGroup>
            </div>
            <div
              className={styles.photoReceipt}
              tabIndex={0}
              role="region"
              aria-label={selectedButton === 0 ? "Photo Gallery" : "Receipt Gallery"}
            >
              {selectedButton ? (
                <div className={styles.receipt}>
                  {receipts.length > 0 ? (
                    receipts.map((receipt) => (
                      <PhotoCard key={receipt.id} data={receipt} onDelete={handlePhotoDelete} />
                    ))
                  ) : (
                    <div className={styles.noData}>Add receipt</div>
                  )}
                  {/* )} */}
                </div>
              ) : (
                <div className={styles.photo}>
                  {photos.length > 0 ? (
                    photos.map((photo) => <PhotoCard key={photo.id} data={photo} onDelete={handlePhotoDelete} />)
                  ) : (
                    <div className={styles.noData}>Add photo</div>
                  )}
                  {/* )} */}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.activityCard}>
            {isSelected ? (
              <div className={styles.detailInfo}>
                <div className={styles.walletname}>{card?.data?.wallet ? card?.data?.wallet?.name : "---"}</div>
                <div className={styles.merchantname}>{card?.data?.merchantName}</div>
                {/* <div className={styles.date}>{dateTimeFormat(card?.data?.timestamp)?.datetime}</div> */}
                <div className={styles.participantname}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {card?.data?.transaction_user
                    ? card?.data?.transaction_user?.attributes?.first_name +
                      " " +
                      card?.data?.transaction_user?.attributes?.last_name
                    : card?.data?.user
                      ? card?.data?.user?.attributes?.first_name + " " + card?.data?.user?.attributes?.last_name
                      : "---"}
                </div>
                <Button
                  variant="contained"
                  className={styles.viewPhoto}
                  onClick={() => {
                    setPhotoCard(true);
                  }}
                >
                  View Photo/Reciept
                </Button>
              </div>
            ) : (
              <>
                <div className={styles.header}>
                  <div className={styles.username}>
                    <div className={styles.image}>
                      <Avatar
                        id="av"
                        role="img"
                        aria-label="Receipt"
                        style={{
                          backgroundColor: bgColorbyName(
                            card?.data?.transaction_user?.attributes?.first_name ||
                              card?.data?.user?.attributes?.first_name ||
                              ""
                          )
                        }}
                      >
                        {card?.data?.transaction_user?.attributes?.first_name.charAt(0).toLocaleUpperCase() ||
                          card?.data?.user?.attributes?.first_name.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className={styles.name}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {card?.data?.transaction_user
                        ? card?.data?.transaction_user?.attributes?.first_name +
                          " " +
                          card?.data?.transaction_user?.attributes?.last_name
                        : card?.data?.user
                          ? card?.data?.user?.attributes?.first_name + " " + card?.data?.user?.attributes?.last_name
                          : "---"}
                    </div>
                  </div>
                  <div className={styles.detailbtn}>
                    <Button
                      variant="contained"
                      className={styles.filterBtn}
                      onClick={() => {
                        onSelectedCard(card?.id);
                      }}
                    >
                      Details
                    </Button>
                  </div>
                </div>
                <div className={styles.imageContent}>
                  {receipts.length > 0 ? (
                    <img key={receipts[0]?.id} src={receipts[0]?.url} alt={receipts[0]?.id} />
                  ) : (
                    <img src={poweredBy} alt="photo" />
                  )}

                  {/* <div className={styles.addFile}>
                  <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: "absolute", color: "#fb8278", zIndex: 1 }}
                    className={styles.addIcon}
                    icon={<NoteAddOutlinedIcon />}
                    direction={"right"}
                    open={speedDialOpen}
                    onClose={() => setSpeedDialOpen(false)}
                    onOpen={() => setSpeedDialOpen(true)}
                  >
                    <SpeedDialAction
                      key={"photo"}
                      icon={<CameraAltOutlinedIcon />}
                      tooltipTitle={"add photo"}
                      onClick={() => {
                        if (hiddenAddPhoto.current) {
                          hiddenAddPhoto.current.click();
                        }
                      }}
                    />

                    <SpeedDialAction
                      key={"receipt"}
                      icon={<TextSnippetOutlinedIcon />}
                      tooltipTitle={"add receipt"}
                      onClick={() => {
                        if (hiddenAddReceipt.current) {
                          hiddenAddReceipt.current.click();
                        }
                      }}
                    />
                  </SpeedDial>
                </div> */}
                </div>
                <div className={styles.info}>
                  <div className={styles.amount}>
                    <div
                      className={styles.amountText}
                      style={{
                        color: card?.data?.amount < 0 ? "red" : "green"
                      }}
                    >
                      {card?.data?.amount < 0
                        ? `-$${getDecimalNumber(card.data.amount)}`
                        : `$${getDecimalNumber(card.data.amount)}`}
                    </div>

                    <div className={styles.date}>{dateTimeFormat(card?.data?.timestamp)?.datetime}</div>
                  </div>
                  <div className={styles.wallet}>
                    <div className={styles.walletText}>
                      {card?.data?.wallet ? "Purchased from " + card?.data?.wallet?.name : "---"}
                    </div>
                    <div
                      className={styles.status}
                      style={{
                        color: card?.activityStatus === "APPROVED" ? "green" : "red"
                      }}
                    >
                      {card?.activityStatus}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {isSelected && (
          <div className={styles.addbuttons}>
            <div className={styles.uploadphoto}>
              <Button
                variant="contained"
                className={styles.addReceipt}
                onClick={() => {
                  if (hiddenAddReceipt.current) {
                    hiddenAddReceipt.current.click();
                  }
                }}
              >
                <div className={styles.logo}>
                  <TextSnippetOutlinedIcon />
                </div>
                <div className={styles.text}>Add Receipt</div>
              </Button>
              <input
                id="hiddenAddReceiptInput"
                type="file"
                accept="image/*,application/pdf"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    handleFileUpload(e.target.files[0], "RECEIPT");
                  }
                }}
                ref={hiddenAddReceipt}
                style={{ display: "none" }}
              />

              <Button
                variant="contained"
                className={styles.addPhoto}
                onClick={() => {
                  if (hiddenAddPhoto.current) {
                    hiddenAddPhoto.current.click();
                  }
                }}
              >
                <div className={styles.logo}>
                  <CameraAltOutlinedIcon />
                </div>
                <div className={styles.text}>Add Photo</div>
              </Button>
              <input
                id="hiddenAddPhotoInput"
                type="file"
                accept="image/*,application/pdf"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    handleFileUpload(e.target.files[0], "PHOTO");
                  }
                }}
                ref={hiddenAddPhoto}
                style={{ display: "none" }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
