import React from "react";
import styles from "./VerticalTimeline.module.scss";

type Subsection = {
  id: string;
  name: string;
};

type TimelineStep = {
  id: number;
  name: string;
  subsections: Subsection[];
};

type VerticalTimelineProps = {
  TimelineSteps: TimelineStep[];
  currentNode: number;
  currentSubsection: number;
  fillLevels: number[];
};

const VerticalTimeline: React.FC<VerticalTimelineProps> = ({
  TimelineSteps,
  currentNode,
  currentSubsection,
  fillLevels
}) => {
  return (
    <div className={styles.sideBar}>
      {TimelineSteps.map((step, index) => (
        <div key={step.id} className={styles.thermometerSection} style={{ position: "relative" }}>
          <div className={`${styles.nodeCircle} ${index <= currentNode ? styles.active : ""}`}></div>

          <div className={styles.thermometerFill} style={{ height: `${fillLevels[index]}%` }}></div>
          {index <= currentNode ? (
            <div className={styles.sectionLabelActive}>{step.name}</div>
          ) : (
            <div className={styles.sectionLabel}>{step.name}</div>
          )}
          {index === currentNode && currentSubsection >= 0 && (
            <div className={styles.subsectionLabelContainer}>
              <div className={styles.subsectionLabel}>
                {TimelineSteps[currentNode].subsections[currentSubsection]?.name}
              </div>
            </div>
          )}
          {index === TimelineSteps.length - 1 && <div className={styles.lastSectionLabel}>Order a Card</div>}
        </div>
      ))}
    </div>
  );
};

export default VerticalTimeline;
