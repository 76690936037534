import { Suspense, lazy } from "react";
import { InternalFilesContent } from "./internalIFileContentSlice";
import styles from "./internalFIleContent.module.scss";
import { Link } from "react-router-dom";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import SettlementColumn from "src/components/internalFileContent/settlementColumn/SettlementColumn";
import { dateTimeFormat } from "src/utils/utils";
const DataModal = lazy(() => import("src/components/internalFileContent/dataModal/DataModal"));
const ErrorModal = lazy(() => import("src/components/internalFileContent/errorModal/ErrorModal"));

function getLink(linkId: string, linkType: string) {
  switch (linkType) {
    case "WALLET":
      return <Link to={`/wallets/${linkId}`}>{linkId}</Link>;
    case "ACTIVITY":
      return <Link to={`/transactions/${linkId}`}>{linkId}</Link>;
    case "USER":
      return <Link to={`/users/${linkId}`}>{linkId}</Link>;
    case "ORGANISATION":
      return <Link to={`/organisation/${linkId}`}>{linkId}</Link>;
    default:
      return linkId;
  }
}

const defaultColumns = [
  {
    title: "Id",
    dataIndex: "contentId",
    key: "contentId",
    render: (contentId: string) => <div className={styles.id}>{contentId}</div>
  },
  {
    title: "Row",
    dataIndex: "row",
    key: "row",
    render: (row: string) => <div>{row}</div>
  },
  {
    title: "Process Status",
    dataIndex: "processStatus",
    key: "processStatus"
  },
  {
    title: "Link",
    dataIndex: "linkId",
    key: "linkId",
    render: (linkId: string, data: InternalFilesContent) => {
      return linkId ? getLink(linkId, data?.linkType) : "----";
    }
  },
  {
    title: "Data",
    dataIndex: "data",
    key: "data",
    render: (data: object) => (
      <Suspense fallback={<CircularLoader />}>
        <DataModal data={data} />
      </Suspense>
    )
  },
  {
    title: "Error Message",
    dataIndex: "errMsg",
    key: "errMsg",
    render: (errMsg: string) =>
      errMsg ? (
        <Suspense fallback={<CircularLoader />}>
          <ErrorModal data={errMsg} />
        </Suspense>
      ) : (
        "----"
      )
  }
];

const additionalColumnsMap: Record<string, any[]> = {
  NOVATTI_DAILY_TRANSACTION: [
    {
      title: "Settlement Status",
      dataIndex: "settlementStatus",
      key: "settlementStatus",
      render: (settlementStatus: string, data: InternalFilesContent) => (
        <div>
          <SettlementColumn settlementStatus={settlementStatus} data={data} />
        </div>
      )
    },
    {
      title: "Date",
      dataIndex: "data",
      key: "data",
      render: (data: { time: string }) => <div>{dateTimeFormat(data?.time)?.datetime}</div>
    },
    {
      title: "Merchant",
      dataIndex: "data",
      key: "data",
      render: (data: { description: string }) => <div>{data?.description}</div>
    },
    {
      title: "PPAN",
      dataIndex: "ppan",
      key: "ppan",
      render: (ppan: string) => <div>{ppan ? ppan : "-----"}</div>
    },
    {
      title: "Approval Code",
      dataIndex: "approvalCode",
      key: "approvalCode",
      render: (approvalCode: string) => <div>{approvalCode ? approvalCode : "----"}</div>
    },
    {
      title: "Novatti Amount",
      dataIndex: "novattiAmount",
      key: "novattiAmount",
      render: (novattiAmount: string) => <div>{novattiAmount ? "$" + novattiAmount : "----"}</div>
    },
    {
      title: "SpendAble Amount",
      dataIndex: "spendableAmount",
      key: "spendableAmount",
      render: (spendableAmount: string) => <div>{spendableAmount ? "$" + spendableAmount : "----"}</div>
    }
  ],
  DEPOSIT: [
    {
      title: "Previous Balance",
      dataIndex: "prevBalance",
      key: "prevBalance",
      render: (prevBalance: string) => <div>{prevBalance ? "$" + prevBalance : "----"}</div>
    },
    {
      title: "Current Balance",
      dataIndex: "currentBalance",
      key: "currentBalance",
      render: (currentBalance: string) => <div>{currentBalance ? "$" + currentBalance : "----"}</div>
    }
  ]
};

export const getColumnsForFileType = (fileType?: string) => {
  if (!fileType || !(fileType in additionalColumnsMap)) {
    return defaultColumns;
  }

  const additionalColumns = additionalColumnsMap[fileType];
  return [...defaultColumns.slice(0, 3), ...additionalColumns, ...defaultColumns.slice(3)];
};
