// BulkUploadConfig.ts
import DownloadInternalFile from "src/components/downloadInternalFile/DownloadInternalFile";
import { dateTimeFormat } from "src/utils/utils";
import { Link } from "react-router-dom";
import { InternalFiles } from "../internalFile/internalIFileSlice";
import { useAppSelector } from "../store";

const Columns = () => {
  const { fileType } = useAppSelector((state) => state.auth);

  return [
    {
      title: "Id",
      dataIndex: "assetId",
      key: "assetId"
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => <div>{dateTimeFormat(date).date}</div>
    },
    {
      title: "Type",
      dataIndex: "assetType",
      key: "assetType",
      render: (assetType: string) => {
        const fileTypeObj = fileType?.find((type) => type.id === assetType);
        return <div>{fileTypeObj ? fileTypeObj.name : assetType}</div>;
      }
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      render: (fileName: string) => <div>{fileName ? fileName : "----"}</div>
    },
    {
      title: "Process Status",
      dataIndex: "processStatus",
      key: "processStatus"
    },
    {
      title: "Total Rows",
      dataIndex: "totalRow",
      key: "totalRow"
    },
    {
      title: "",
      dataIndex: "",
      key: "operation",
      render: (bucketName: string, data: InternalFiles) => {
        return (
          <Link
            to={`/internal-files/${data.assetId}/${data.assetType}`}
            aria-label={`View file content for ${data.fileName}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
                stroke="#64748B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        );
      }
    },
    {
      title: "",
      dataIndex: "",
      key: "download",
      render: (bucketName: string, data: InternalFiles) => {
        return <DownloadInternalFile bucketName={data.bucketName} bucketKey={data.bucketKey} />;
      }
    }
  ];
};

export default Columns;
