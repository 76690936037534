import { CircularProgress } from "@mui/material";
import styles from "./circularLoader.module.scss";

interface CircularLoadProp {
  progressStyles?: string;
}

function CircularLoader({ progressStyles }: CircularLoadProp) {
  return (
    <td className={`${styles.progress} ${progressStyles}`}>
      <CircularProgress sx={{ color: "#1e293b" }} />
    </td>
  );
}

export default CircularLoader;
