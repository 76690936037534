import { Suspense, useState } from "react";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import Modalui from "src/components/UI/modal/Modalui";
import styles from "./settlementColumn.module.scss";
import { Button, CircularProgress, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  InternalFilesContent,
  fetchWalletDetailAction,
  settleTransactionAction
} from "src/page/internalFileContent/internalIFileContentSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { getDecimalNumber } from "src/utils/utils";

interface SettlementColumnProp {
  settlementStatus: string;
  data: InternalFilesContent;
}

export default function SettlementColumn({ settlementStatus, data }: SettlementColumnProp) {
  const dispatch = useAppDispatch();
  const { walletDetail, walletDetailLoading, settleTransactionLoading } = useAppSelector(
    (state) => state.internalFileContent
  );
  const [misMatchModal, setMisMatchModal] = useState<boolean>(false);
  const [settledModal, setSettledModal] = useState<boolean>(false);
  const settlementAmount = Number(data?.spendableAmount) - Number(data?.novattiAmount);

  const openMisMatchModal = () => {
    setMisMatchModal(true);
    dispatch(fetchWalletDetailAction(data?.linkId));
  };

  const openSettledModal = () => {
    setSettledModal(true);
    dispatch(fetchWalletDetailAction(data?.linkId));
  };

  function handleSettle() {
    dispatch(
      settleTransactionAction(data?.contentId, () => {
        setMisMatchModal(false);
      })
    );
  }

  function getSettlementValue() {
    switch (settlementStatus) {
      case "MATCHED":
        return "Matched";
      case "MISMATCHED":
        return (
          <>
            <button className={styles.mismatchBtn} onClick={openMisMatchModal}>
              Mismatched
            </button>

            <Modalui open={misMatchModal} handleClose={() => setMisMatchModal(false)}>
              <Suspense fallback={<CircularLoader />}>
                <div className={styles.referModalBox}>
                  <div className={styles.referModalTop}>
                    <form>
                      <div className={styles.close}>
                        <h5>
                          Amount to be adjusted
                          <span style={{ color: settlementAmount > 0 ? "green" : "red" }}>
                            {settlementAmount > 0
                              ? " $" + getDecimalNumber(settlementAmount)
                              : " -$" + getDecimalNumber(settlementAmount)}
                          </span>
                        </h5>
                        <button
                          onClick={() => {
                            setMisMatchModal(false);
                          }}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                      {walletDetailLoading ? (
                        <div className={styles.loader}>
                          <Skeleton variant="rounded" width={300} height={150} />
                          <Skeleton variant="rounded" width={300} height={150} />
                        </div>
                      ) : (
                        <div className={styles.detailContainer}>
                          <div className={styles.userInfo}>
                            <h4>Participant information</h4>
                            <div className={styles.userdata}>
                              <div className={styles.item}>
                                <label>Name : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.fullName}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Email : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.email}</div>
                              </div>
                              <div className={styles.item}>
                                <label>User Id : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.id}</div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.walletInfo}>
                            <h4>Wallet information</h4>
                            <div className={styles.walletdata}>
                              <div className={styles.item}>
                                <label>Name : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.name}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Balance : </label>
                                <div className={styles.value}>${walletDetail?.wallet?.balance}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Wallet Display Id : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.displayId}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Wallet Id : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.id}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                  <div className={styles.referModalBottom}>
                    <div className={styles.referModalBtn}>
                      <Button className={styles.sendBtn} variant="contained" onClick={handleSettle}>
                        {settleTransactionLoading ? (
                          <CircularProgress size={24} style={{ color: "white" }} />
                        ) : (
                          "Settle"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Suspense>
            </Modalui>
          </>
        );
      case "PARTIAL_MATCHED":
        return "Partial Matched";
      case "NOVATTI_ONLY":
        return "Novatti Only";
      case "SETTLED":
        return (
          <>
            <button className={styles.mismatchBtn} onClick={openSettledModal}>
              Settled
            </button>

            <Modalui open={settledModal} handleClose={() => setSettledModal(false)}>
              <Suspense fallback={<CircularLoader />}>
                <div className={styles.referModalBox}>
                  <div className={styles.referModalTop}>
                    <form>
                      <div className={styles.close}>
                        <h5>
                          Adjusted Amount
                          <span style={{ color: settlementAmount > 0 ? "green" : "red" }}>
                            {settlementAmount > 0
                              ? " $" + getDecimalNumber(settlementAmount)
                              : " -$" + getDecimalNumber(settlementAmount)}
                          </span>
                        </h5>
                        <button
                          onClick={() => {
                            setSettledModal(false);
                          }}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                      {walletDetailLoading ? (
                        <div className={styles.loader}>
                          <Skeleton variant="rounded" width={300} height={150} />
                          <Skeleton variant="rounded" width={300} height={150} />
                        </div>
                      ) : (
                        <div className={styles.detailContainer}>
                          <div className={styles.userInfo}>
                            <h4>Participant information</h4>
                            <div className={styles.userdata}>
                              <div className={styles.item}>
                                <label>Name : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.fullName}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Email : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.email}</div>
                              </div>
                              <div className={styles.item}>
                                <label>User Id : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.id}</div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.walletInfo}>
                            <h4>Wallet information</h4>
                            <div className={styles.walletdata}>
                              <div className={styles.item}>
                                <label>Name : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.name}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Wallet Display Id : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.displayId}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Wallet Id : </label>
                                <div className={styles.value}>{walletDetail?.wallet?.participant?.id}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Balance before Settlement : </label>
                                <div className={styles.value}>${data.prevBalance}</div>
                              </div>
                              <div className={styles.item}>
                                <label>Balance after Settlement : </label>
                                <div className={styles.value}>${data.currentBalance}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </Suspense>
            </Modalui>
          </>
        );
      default:
        return settlementStatus;
    }
  }

  return getSettlementValue();
}
