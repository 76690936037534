import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { SearchUserRequest, searchUser, getUserWallets } from "src/network/graphql/userService";
import { Users } from "../users/usersSlice";
// import { UserListRequest } from "src/network/services/UsersServices";
import { Wallet } from "../wallets/walletSlice";
import { Card } from "../cards/cardSlice";
import { CardSearchRequest, cardSearch } from "src/network/graphql/cardService";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";
import { Transaction } from "../transactions/transactionSlice";
// import { PARTICIPANT } from "src/components/constant/constant";
// import { walletListRequest } from "src/network/services/walletService";

const initialUsers: Users[] = [];
const initialWallets: Wallet[] = [];
const initialCards: Card[] = [];
const initialTransaction: Transaction[] = [];

const budgetSlice = createSlice({
  name: "budget",
  initialState: {
    userLoading: false,
    walletLoading: false,
    cardLoading: false,
    activityLoading: false,
    error: null,
    users: {
      list: initialUsers,
      total: 0
    },
    wallets: {
      list: initialWallets,
      total: 0
    },
    cards: {
      list: initialCards,
      total: 0
    },
    transactions: {
      list: initialTransaction,
      total: 0
    }
  },
  reducers: {
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = action.payload?.list ?? [];
      state.wallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = [];
      state.wallets.total = 0;
      state.error = action.payload;
    },
    resetwalletListAction: (state, action) => {
      state.wallets.list = action.payload.list;
      state.wallets.total = action.payload?.total;
    },
    fetchCardsStart: (state) => {
      state.cardLoading = true;
    },
    fetchCardsSuccess: (state, action) => {
      state.cardLoading = false;
      state.cards.list = action.payload;
      state.error = null;
    },
    fetchCardsFail: (state, action) => {
      state.cardLoading = false;
      state.error = action.payload;
    },
    fetchActivityStart: (state) => {
      state.activityLoading = true;
    },
    fetchActivitySuccess: (state, action) => {
      state.activityLoading = false;
      state.transactions = action.payload;
    },
    fetchActivityFail: (state, action) => {
      state.activityLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchCardsStart,
  fetchCardsSuccess,
  fetchCardsFail,
  fetchActivityStart,
  fetchActivitySuccess,
  fetchActivityFail
} = budgetSlice.actions;

export const { resetwalletListAction } = budgetSlice.actions;

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser(data);

      const structuredResponse = response.data.search_user.user_listing
        // .filter((user: any) => user.current_organisation_role === PARTICIPANT)
        .map((user: any) => ({
          id: user.display_id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.email,
          role: "not available",
          status: user.is_active,
          userId: user.user_id
        }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchWalletListAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await getUserWallets(userId);

      const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        // status: wallet.wallet_status,
        name: wallet.name
        // participant: { role: "PARTICIPANT", fullName: wallet.description }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchCardListAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchCardsStart());
    try {
      const response = await cardSearch(data);
      const structuredResponse = response.data.search_card.card_listing?.map(
        (card: any) =>
          ({
            id: card.card_id,
            bsb: card.bsb,
            cardStatus: card.card_status,
            accessType: card.card_type,
            cardNumber: card.ppan,
            cardLimit: card.card_limit,
            user: {
              id: card.user_id,
              fullName: card.holder_name
            }
          }) as Card
      );

      dispatch(
        fetchCardsSuccess({
          list: structuredResponse,
          total: response.data.search_card.total_size
        })
      );
    } catch (error) {
      dispatch(fetchCardsFail(error));
    }
  };
};

export const fetchActivityAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchActivityStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        displayId: data.display_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        data: JSON.parse(data.data)
      }));

      dispatch(
        fetchActivitySuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchActivityFail(err));
    }
  };
};

export default budgetSlice.reducer;
