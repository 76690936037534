import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { Users } from "../users/usersSlice";
import { Wallet } from "../wallets/walletSlice";
import { SearchUserRequest, searchUser, getUserWallets } from "src/network/graphql/userService";
import { AddFundsRequest, addFunds, revokeFunds } from "src/network/graphql/fundsService";
import swal from "sweetalert2";
import { getWalletDetail } from "src/network/graphql/walletService";

const initialUsers: Users[] = [];
const initialWallets: Wallet[] = [];

const depositSlice = createSlice({
  name: "deposit",
  initialState: {
    userLoading: false,
    walletLoading: false,
    addFundsLoading: false,
    error: false,
    users: {
      list: initialUsers,
      total: 0
    },
    wallets: {
      list: initialWallets,
      total: 0
    },
    selectedWallet: null as Wallet | null
  },
  reducers: {
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = action.payload?.list ?? [];
      state.wallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = [];
      state.wallets.total = 0;
      state.error = action.payload;
    },
    fetchWalletByIdStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletByIdSuccess: (state, action) => {
      state.walletLoading = false;
      state.selectedWallet = action.payload ?? null;
    },
    fetchWalletByIdFail: (state, action) => {
      state.walletLoading = false;
      state.selectedWallet = null;
      state.error = action.payload;
    },
    resetwalletListAction: (state, action) => {
      state.wallets.list = action.payload.list;
      state.wallets.total = action.payload?.total;
    },
    setAddFundsLoading: (state, action) => {
      state.addFundsLoading = action.payload;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchWalletByIdStart,
  fetchWalletByIdSuccess,
  fetchWalletByIdFail,
  setAddFundsLoading
} = depositSlice.actions;

export const { resetwalletListAction } = depositSlice.actions;

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        user_filter: data.user_filter,
        size: data.size,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user.display_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        currentOrganisationRole: user.current_organisation_role,
        status: user.is_active,
        userId: user.user_id,
        phone: user.phone
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchWalletListAction = (userId: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await getUserWallets(userId);

      const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        name: wallet.name,
        participant: { email: wallet?.user?.email }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchWalletByIdAction = (walletId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletByIdStart());
    try {
      const response = await getWalletDetail(walletId);

      const walletData = response.data.get_wallet;
      const structuredResponse = {
        id: walletData.wallet_id,
        displayId: walletData.display_id,
        description: walletData.description,
        balance: walletData.balance,
        status: walletData.wallet_status,
        name: walletData.name,
        participant: {
          id: walletData.user.user_id,
          fullName: `${walletData.user?.attributes?.first_name ?? "-"} ${walletData.user?.attributes?.last_name ?? "-"}`,
          firstName: walletData.user?.attributes?.first_name ?? "-",
          lastname: walletData.user?.attributes?.last_name ?? "-",
          role: walletData.user?.role ?? "PARTICIPANT",
          email: walletData.user?.email,
          display_id: walletData.user?.display_id,
          phone: walletData.user?.phone
        },
        walletType: walletData.wallet_type,
        organisationId: walletData.organisation_id ?? 0
      };

      dispatch(fetchWalletByIdSuccess(structuredResponse));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchWalletByIdFail(error.message));
      } else {
        dispatch(fetchWalletByIdFail("An unknown error occurred"));
      }
    }
  };
};

export const addFundsAction = (data: AddFundsRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(setAddFundsLoading(true));
    try {
      await addFunds(data);
      swal.fire({
        position: "center",
        icon: "success",
        title: "Funds Sucessfully added",
        showConfirmButton: false,
        timer: 2500
      });
      if (cb) cb();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setAddFundsLoading(false));
    }
  };
};

export const revokeFundsAction = (data: AddFundsRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(setAddFundsLoading(true));
    try {
      await revokeFunds(data);
      swal.fire({
        position: "center",
        icon: "success",
        title: "Funds Sucessfully revoked",
        showConfirmButton: false,
        timer: 2500
      });
      if (cb) cb();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setAddFundsLoading(false));
    }
  };
};

export default depositSlice.reducer;
