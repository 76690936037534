import { useState } from "react";
import styles from "./listSubscription.module.scss";
import PricingPlanCard from "../subscriptionPlan/SubscriptionCard/PricingPlanCard";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { SubsList } from "src/page/plans/plansSlice";
import { Button, Skeleton, Stack } from "@mui/material";
import { connectOrganisationToSubscriptionAction } from "src/page/childrenPlans/childrenPlansSlice";

interface ListSubscriptionProp {
  handleClose: () => void;
  onSubmit: () => void;
}

export default function ListSubscription({ handleClose, onSubmit }: ListSubscriptionProp) {
  const dispatch = useAppDispatch();
  const { subscriptionList, subscriptionLoading, recentCreatedOrganisation } = useAppSelector(
    (state) => state.organisation
  );
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [buttonLabels, setButtonLabels] = useState<{ [key: string]: string }>({});
  const [buttonStyles, setButtonStyles] = useState<{ [key: string]: string }>({});

  const handleSubmit = () => {
    dispatch(
      connectOrganisationToSubscriptionAction(recentCreatedOrganisation, selectedPlan, () => {
        onSubmit();
      })
    );
  };

  const handleSelectPlan = (subsId: string) => {
    setSelectedPlan(subsId);

    const newButtonLabels = subscriptionList?.list?.reduce((acc: { [key: string]: string }, subscription: SubsList) => {
      acc[subscription.subsId] = subscription.subsId === subsId ? "Selected" : "Select";
      return acc;
    }, {});

    const newButtonStyles = subscriptionList?.list?.reduce((acc: { [key: string]: string }, subscription: SubsList) => {
      acc[subscription.subsId] = subscription.subsId === subsId ? styles.selectedButton : "";
      return acc;
    }, {});

    setButtonLabels(newButtonLabels || {});
    setButtonStyles(newButtonStyles || {});
  };

  return (
    <>
      <div className={styles.referModalBox}>
        <main className={styles.referModalTop}>
          <form>
            <div className={styles.close}>
              <h5>Subscriptions</h5>
            </div>
            {subscriptionLoading ? (
              <Skeleton variant="rounded" height={300} />
            ) : (
              <div className={styles.subscriptionList}>
                {subscriptionList?.list?.map((subscription: SubsList) => (
                  <PricingPlanCard
                    key={subscription.subsId}
                    title={subscription.title}
                    subTitle={subscription.subTitle}
                    description={subscription.description}
                    category={subscription.subscription_category}
                    containerStyles={styles.pricingPlanContainer}
                    titleStyles={styles.pricingPlanTitle}
                    subTitleStyles={styles.pricingPlanSubTitle}
                    buttonLabel={buttonLabels[subscription.subsId] || "Select"}
                    buttonStyles={buttonStyles[subscription.subsId]}
                    onButtonClick={() => {
                      handleSelectPlan(subscription.subsId);
                    }}
                  />
                ))}
              </div>
            )}
          </form>
        </main>
        <footer className={styles.referModalBottom}>
          <div className={styles.referModalBtn}>
            <Stack spacing={2} direction="row">
              <Button className={styles.cancelBtn} variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button className={styles.sendBtn} variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Stack>
          </div>
        </footer>
      </div>
    </>
  );
}
