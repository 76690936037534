import { useCallback, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./supportModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { debounce } from "lodash";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  fetchParticipantWalletAction,
  fetchParticpantAction,
  resetParticipantWallet
} from "src/page/userDetail/userDetailSlice";
import { Wallet } from "src/page/wallets/walletSlice";

interface Iprops {
  handleClose: () => void;
  participantName: string;
  organisationId?: number;
  onAddSupporter: (_email: string, _userId: string, _close: boolean, _connectedToParticipant: boolean) => void;
  closeButton?: boolean;
  secondaryButton?: boolean;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
}

export default function WalletUserModal({
  handleClose,
  participantName,
  onAddSupporter,
  closeButton = true,
  secondaryButton = true,
  buttonText
}: Iprops) {
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [error, setError] = useState({ email: "" });

  const { participantWallet, participants } = useAppSelector((state) => state.userDetail);
  const dispatch = useAppDispatch();

  const handleAddAndClose = () => {
    if (!wallet?.participant?.email.trim()) {
      setError({
        email: wallet?.participant?.email ? "" : "Wallet is required"
      });
      return;
    }
    onAddSupporter(wallet?.participant?.email, String(wallet?.id), true, false);
  };

  const handleAddAndStay = () => {
    if (!wallet?.participant?.email.trim()) {
      setError({
        email: wallet?.participant?.email ? "" : "Wallet is required"
      });
      return;
    }
    onAddSupporter(wallet?.participant?.email, String(wallet?.id), false, false);
  };

  const handleCancelClose = () => {
    handleClose();
  };

  const handleUserChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchParticpantAction(data));
  }, 500);

  const fetchWalletList = useCallback(
    (userId: string) => {
      dispatch(fetchParticipantWalletAction(userId));
    },
    [dispatch]
  );

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect Wallet to {participantName}</h5>
            {closeButton && (
              <button onClick={handleCancelClose} aria-label="Close Button">
                <CloseIcon />
              </button>
            )}
          </div>
          <div className={styles.email}>
            <label>Particpant</label>
            <Autocomplete
              options={participants?.list}
              getOptionLabel={(option) => option.firstName ?? option}
              onInputChange={handleUserChange}
              onChange={(event, newValue) => {
                dispatch(resetParticipantWallet({ list: [] }));
                if (newValue?.userId) {
                  fetchWalletList(String(newValue?.userId));
                } else {
                  setWallet(null);
                }
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Search Participant..." />}
              ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <span>{option?.firstName + " " + option?.lastName}</span>
                  <span style={{ color: "#64748B" }}>{option?.email}</span>
                </li>
              )}
            />
          </div>
          <div className={styles.email}>
            <label>Wallet Name</label>
            <Autocomplete
              options={participantWallet?.wallets}
              getOptionLabel={(option) => option.name ?? option}
              value={wallet}
              onChange={(event, newValue) => {
                setWallet(newValue);
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Search Wallet..." />}
              ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <span>{option?.name}</span>
                  <span style={{ color: "#64748B", fontSize: "14px" }}>{option?.participant?.email}</span>
                </li>
              )}
            />
            {error.email && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.email}
              </div>
            )}
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            {secondaryButton && (
              <Button
                className={styles.cancelBtn}
                variant="text"
                onClick={handleAddAndClose}
                aria-label="Secondary button"
              >
                {buttonText?.secondaryButton || "Connect & Back To List"}
              </Button>
            )}
            <Button
              className={styles.sendBtn}
              variant="contained"
              onClick={handleAddAndStay}
              aria-label="primary button"
            >
              {buttonText?.primaryButton || "Connect & Add Another Wallet"}
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
