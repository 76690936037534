import React, { useState, useEffect, useCallback, useMemo, Suspense } from "react";
import styles from "./orgListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Button from "@mui/material/Button";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./listTable/orgListTable";
import Modalui from "src/components/UI/modal/Modalui";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { FormControl, MenuItem, Select } from "@mui/material";
import AddOrgModal, { orgList } from "./addOrgModal/AddOrgModal";
import { fetchOrgListAction, addOrgAction } from "src/page/organisation/organisationSlice";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { useSearchParams } from "react-router-dom";
import { ORG_STATUS, ORG_PAGE, ORG_LIMIT, ORG_SEARCHBY, ORG_TYPE } from "src/components/constant/queryConstants";
import LoadingModal from "../UI/loadingModal/LoadingModal";
import { CAN_ADD_ORGANSIATION } from "../constant/constant";
import CircularLoader from "../UI/circularLoader/CircularLoader";
import { checkPermission } from "src/utils/utils";
import TableColumnFilter from "../UI/tableColumnFIlter/TableColumnFilter";
import { setColumnFilter } from "src/page/filtersSlice";
import ListSubscription from "../listSubscription/ListSubscription";

export default function OrgListCard() {
  const [open, setOpen] = useState(false);
  const [openListSubscription, setOpenListSubscription] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get(ORG_STATUS) || null;
  const type = searchParams.get(ORG_TYPE) || null;
  const searchby = searchParams.get(ORG_SEARCHBY) || "orgName";
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const { column: columnFilter } = useAppSelector((state) => state.filters);

  const [searchText, setSearchText] = useState("");
  const { orgLists, addOrgLoading, orgListLoading } = useAppSelector((state) => state.organisation);
  const { switchOrgLoading } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const fetchOrganisationData = useCallback(() => {
    const orgLimit = searchParams.get(ORG_LIMIT) ?? 10;
    const orgPage = searchParams.get(ORG_PAGE) ?? 0;

    let statusPresent = null;
    const typePresent = searchParams.get(ORG_TYPE) ?? undefined;

    if (searchParams.get(ORG_STATUS) === "true") {
      statusPresent = true;
    } else if (searchParams.get(ORG_STATUS) === "false") {
      statusPresent = false;
    } else {
      statusPresent = null;
    }

    const text = searchText.trim().length > 0 ? searchText.trim() : undefined;

    const data = {
      page: orgPage ? Number(orgPage) : 0,
      limit: orgLimit ? Number(orgLimit) : 10,
      from: Number(orgPage) * Number(orgLimit),
      active: statusPresent,
      displayId: searchby === "orgId" ? text : undefined,
      searchName: searchby === "orgName" ? text : undefined,
      type: typePresent
    };

    dispatch(fetchOrgListAction(data));
  }, [searchParams, searchText, dispatch]);

  const handleOrg = () => {
    setOpen(!open);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(ORG_PAGE, newPage + "");

    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(ORG_LIMIT, event.target.value);
    searchParams.delete(ORG_PAGE);
    setSearchParams(searchParams);
  };

  const handleAddOrg = (input: orgList, cb?: () => void) => {
    dispatch(
      addOrgAction(input, () => {
        if (cb) cb();
        setOpenListSubscription(true);
        fetchOrganisationData();
      })
    );
  };

  useEffect(() => {
    fetchOrganisationData();
  }, [fetchOrganisationData]);

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.organisation?.includes(col.key));
  }, [columnFilter?.organisation]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        organisation: selectedColumns
      })
    );
  };

  return (
    <>
      <Modalui open={openListSubscription} handleClose={() => {}}>
        <Suspense fallback={<CircularLoader />}>
          <ListSubscription
            handleClose={() => {}}
            onSubmit={() => {
              setOpenListSubscription(false);
              fetchOrganisationData();
            }}
          />
        </Suspense>
      </Modalui>
      <div className={styles.orgList} aria-label="Organisation List">
        {addOrgLoading || switchOrgLoading ? <LoadingModal /> : ""}

        <div className={styles.orgListHead}>
          <div className={styles.orgListHeadIn}>
            <div className={styles.orgListHeadD}>
              <h3>Organisation list</h3>
              <p>See information about all of your connected Organisation</p>
            </div>
            {checkPermission(CAN_ADD_ORGANSIATION) &&
              cognitoConfig.currentOrganisation?.organisation_id === cognitoConfig.defaultOrgId && (
                <div className={styles.orgListHeadBtn}>
                  <Button
                    variant="contained"
                    onClick={handleOrg}
                    className={styles.addorgBtn}
                    startIcon={<PersonAddAlt1Icon />}
                    aria-label="Add Organisation"
                  >
                    Add Organisation
                  </Button>
                  <Modalui open={open} handleClose={handleOrg} modaluiOver={styles.modaluiOver}>
                    <AddOrgModal handleClose={handleOrg} onAddOrg={handleAddOrg} addUser={true} />
                  </Modalui>
                </div>
              )}
          </div>
          <div className={styles.orgListFilter}>
            <div className={styles.filterSearch}>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={status || "status"}
                  onChange={(e) => {
                    searchParams.set(ORG_STATUS, e.target.value);
                    setSearchParams(searchParams);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Organisation Status Filter" }}
                  sx={{
                    height: 40,
                    color: {
                      true: "",
                      false: "",
                      status: "grey",
                      default: "grey"
                    }[status ?? "default"]
                  }}
                >
                  <MenuItem value="status" sx={{ color: "grey" }}>
                    Status
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={type || "type"}
                  onChange={(e) => {
                    if (e.target.value === "type") {
                      searchParams.delete(ORG_TYPE);
                    } else {
                      searchParams.set(ORG_TYPE, e.target.value);
                    }
                    setSearchParams(searchParams);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 40,
                    color: {
                      CORPORATE: "",
                      INDIVIDUAL: "",
                      type: "grey",
                      default: "grey"
                    }[type ?? "default"]
                  }}
                >
                  <MenuItem value="type" sx={{ color: "grey" }}>
                    Type
                  </MenuItem>
                  <MenuItem value="CORPORATE">Corporate</MenuItem>
                  <MenuItem value="INDIVIDUAL">Individual</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.orgListSearch}>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={searchby || "orgName"}
                  onChange={(e) => {
                    searchParams.set(ORG_SEARCHBY, e.target.value);
                    setSearchParams(searchParams);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Search By Filter" }}
                  sx={{
                    height: 40
                  }}
                >
                  <MenuItem value="orgName">Name</MenuItem>
                  <MenuItem value="orgId">Id</MenuItem>
                </Select>
              </FormControl>
              <div className={styles.searchBar}>
                <SearchBar onSearch={handleSearch} />
              </div>
            </div>
          </div>
          <TableColumnFilter
            columns={columns}
            selectedColumn={columnFilter?.organisation}
            mandatoryColumns={["organisationId", "id", "operations"]}
            onApply={handleColumnSelection}
          />
        </div>
        <div className={styles.orgListTable} aria-live="polite">
          <Table
            columns={filteredColumns}
            data={orgListLoading ? [] : orgLists.list}
            className={styles.tableIn}
            components={
              orgListLoading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100, 500]}
            component="div"
            count={orgLists.total}
            page={searchParams.get(ORG_PAGE) ? Number(searchParams.get(ORG_PAGE)) : 0}
            onPageChange={handleChangePage}
            rowsPerPage={searchParams.get(ORG_LIMIT) ? Number(searchParams.get(ORG_LIMIT)) : 10}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
