// import { PaginationRequest } from "src/types/commonTypes";
import client from "../client";

export type WalletTransferRequest = {
  amount: number;
  designationWalletId: string;
  sourceWalletId: string;
};

export const internalWalletTransfer = (data: WalletTransferRequest) => {
  return client({
    query: `mutation WalletTransfer($input: WalletTransferInput) { 
        wallet_transfer(input: $input) { 
            destination { 
                balance 
                wallet_id 
                is_default 
                display_id
            } 
            source { 
                balance 
                display_id 
                name 
                wallet_id 
            } 
        } 
    }`,
    variables: {
      input: {
        amount: data.amount,
        destination_wallet_id: data.designationWalletId,
        source_wallet_id: data.sourceWalletId
      }
    }
  });
};

export type SinglePayRequest = {
  userId?: string;
  cardId: string;
  walletId: string;
  expireAt?: string;
  linkType?: string;
};

export const singlePay = (data: SinglePayRequest) => {
  return client({
    query: `mutation CreateCardSetting($input: CreateCardSettingInput) { 
        create_card_setting(input: $input) { 
            card_id 
            ppan 
            wallet_id 
            card_setting_status 
            card_setting_id
        } 
    }`,
    variables: {
      input: {
        user_id: data.userId,
        card_id: data.cardId,
        expires_at: data.expireAt,
        link_type: data.linkType,
        wallet_id: data.walletId
      }
    }
  });
};

export type groupPayRequest = {
  destination: {
    walletId: string;
  }[];
  source: {
    amount: string;
    walletId: string;
  }[];
};

export const groupPay = (data: groupPayRequest) => {
  return client({
    query: `mutation GroupWalletTransfer($input: GroupWalletTransferInput) { group_wallet_transfer(input: $input) { sources { balance } } } `,
    variables: {
      input: {
        destinations: data.destination.map((destination: any) => ({
          wallet_id: destination.walletId
        })),
        sources: data.source.map((source: any) => ({
          amount: source.amount,
          wallet_id: source.walletId
        }))
      }
    }
  });
};
