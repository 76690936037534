import { ChangeEvent, useState } from "react";
import styles from "./userNameInput.module.scss";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { userNameValidation } from "src/utils/utils";

interface Iprops {
  value: string;
  setValue: (_value: string) => void;
  error: string;
  setError: (_value: string) => void;
}
function UserNameInput({ value, setValue, error, setError }: Iprops) {
  const [message, setMessage] = useState<string | undefined>();
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue(inputValue);

    setError(userNameValidation(inputValue));

    setMessage(undefined);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.username}>
          <label htmlFor="username">Username :</label>
          <div className={styles.inputWerror}>
            <input
              id="username"
              type="text"
              placeholder="Enter Username Here"
              value={value}
              name="username"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <ErrorOutlineIcon className={styles.errIcon} />
          {error}
        </div>
      )}
      {message && (
        <div className={styles.message}>
          <ErrorOutlineIcon className={styles.messageIcon} />
          {message}
        </div>
      )}
    </>
  );
}

export default UserNameInput;
