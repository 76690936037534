import { Wallet } from "src/page/wallets/walletSlice";

export const addFundFields = (wallet: Wallet | null) => [
  {
    key: "Account Name",
    value: "Gimme IT Pty Ltd"
  },
  {
    key: "BSB",
    value: "062 692"
  },
  {
    key: "Account #",
    value: "4531 7702"
  },
  {
    key: "Description",
    value: wallet ? wallet?.displayId : "[Wallet ID]"
  }
];

export const walletDetailField = (wallet: Wallet | null) => [
  {
    key: "Name",
    value: wallet?.participant?.fullName
  },
  {
    key: "Email",
    value: wallet?.participant?.email
  },
  {
    key: "Display ID",
    value: wallet?.displayId
  }
];
