import styles from "../InternalTransactionListCard.module.scss";
import { Link } from "react-router-dom";
import { Transaction } from "src/page/transactions/transactionSlice";
import { dateTimeFormat, formatTimestamp, getDecimalNumber, getTransactionValue } from "src/utils/utils";
import { activityNameMapping } from "src/components/constant/constant";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (id: string) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Date / Time",
    dataIndex: "name",
    key: "startDate",
    width: 150,
    render: (name: string, data: Transaction) => {
      let dateValue;

      if (name === "NOVATTI_CARD_TRANSACTION") {
        dateValue = formatTimestamp(data.data.timestamp);
      } else {
        dateValue = data.startDate;
      }

      if (dateValue === undefined || null) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }

      return (
        <>
          <div className={styles.tableDate}>{dateTimeFormat(dateValue)?.date}</div>
          <div className={styles.tableDate}>{dateTimeFormat(dateValue)?.time}</div>
        </>
      );
    }
  },
  {
    title: "Ppan",
    dataIndex: "data",
    key: "ppan",
    render: (data: { ppan: string }) => (
      <div className={styles.approvalNumber}>{data?.ppan ? "****" + data.ppan.slice(-4) : "---"}</div>
    )
  },
  {
    title: "Purchase Made by",
    dataIndex: "name",
    key: "purchasemadeby",
    render: (name: string, transaction: Transaction) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>
            {transaction?.data?.transaction_user
              ? transaction?.data?.transaction_user?.attributes?.first_name +
                " " +
                transaction?.data?.transaction_user?.attributes?.last_name
              : transaction?.data?.user
                ? transaction?.data?.user?.attributes?.first_name + " " + transaction?.data?.user?.attributes?.last_name
                : "---"}
          </div>
          <div className={styles.tableMemberEmail}>
            {" "}
            {transaction?.data?.transaction_user
              ? transaction?.data?.transaction_user?.email
              : transaction?.data?.user
                ? transaction?.data?.user?.email
                : "---"}
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Approval Number",
    dataIndex: "approval_number",
    key: "approval_number",
    render: (approval_number: string | undefined) => (
      <div className={styles.approvalNumber}>{approval_number ? approval_number : "---"}</div>
    )
  },
  {
    title: "Activity Name",
    dataIndex: "name",
    key: "name",
    render: (name: string) => <span className={styles.activityname}>{activityNameMapping[name]}</span>
  },
  {
    title: "Transaction Partner",
    dataIndex: "merchant",
    key: "merchant",
    // width: 150,
    render: (merchantName: string, data: Transaction) => (
      <span className={styles.tableMerchent}>{getTransactionValue(data)?.value}</span>
    )
  },
  // {
  //   title: "Wallet",
  //   dataIndex: "wallet",
  //   key: "wallet",
  //   render: (img: string, data: Transaction) => (
  //     <div className={styles.tableWallet}>
  //       <div className={styles.tableWalletImg}>
  //         {/* <img src={wallet.img} alt="Member" /> */}
  //         <Avatar
  //           id="av"
  //           aria-label="Recipe"
  //           style={{ backgroundColor: bgColorbyName(data.wallet.participant.fullName) }}
  //         >
  //           {data.wallet.participant.fullName?.charAt(0).toLocaleUpperCase()}
  //         </Avatar>
  //       </div>
  //       <div className={styles.tableWalletDetail}>
  //         <div className={styles.tableMemberName}>{data.wallet.participant.fullName}</div>
  //         <div className={styles.tableWalletName}>{data.wallet.description}</div>
  //       </div>
  //     </div>
  //   )
  // },
  {
    title: "Wallet",
    dataIndex: "wallet",
    key: "wallet",
    render: (wallet: string, data: Transaction) => (
      <div className={styles.tableWallet}>
        {data?.data?.wallet_id ? (
          <Link to={`/wallets/${data?.data?.wallet_id}`}>
            {data?.data?.wallet ? data?.data?.wallet?.name : data?.data?.wallet_id}
          </Link>
        ) : (
          "---"
        )}
      </div>
    )
  },
  {
    title: "Activity Status",
    dataIndex: "activityStatus",
    key: "activityStatus",
    render: (activityStatus: string) => <div className={styles.activityStatus}>{activityStatus}</div>
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount: number, data: Transaction) => (
      <div className={styles.tableAmount} style={{ color: getTransactionValue(data)?.amountColor }}>
        {getTransactionValue(data)?.amount}
      </div>
    )
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance: number, data: Transaction) => {
      const cutOffDate = "2024-03-13T00:00:00Z";

      const rednerBalance = () => {
        if (data?.endDate > cutOffDate) {
          if (data?.data?.wallet?.balance) {
            if (data?.data?.wallet?.balance > 0) {
              return "$" + getDecimalNumber(data?.data?.wallet?.balance);
            } else {
              return "-$" + getDecimalNumber(data?.data?.wallet?.balance);
            }
          } else {
            return "$0.00";
          }
        } else {
          return "---";
        }
      };

      return (
        <div
          className={styles.tableBalance}
          style={{ color: data?.endDate > cutOffDate && data?.data?.wallet?.balance > 0 ? "green" : "red" }}
        >
          {rednerBalance()}
        </div>
      );
    }
  },
  {
    title: "Receipt",
    dataIndex: "receipt_available",
    key: "receipt_available",
    render: (receipt_available: boolean) => (
      <div className={styles.tableReceipt}>
        {receipt_available ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="#34A853">
            <path d="M27.8549 13.4252L26.1549 11.4502C25.8299 11.0752 25.5674 10.3752 25.5674 9.8752V7.7502C25.5674 6.4252 24.4799 5.3377 23.1549 5.3377H21.0299C20.5424 5.3377 19.8299 5.0752 19.4549 4.7502L17.4799 3.0502C16.6174 2.3127 15.2049 2.3127 14.3299 3.0502L12.3674 4.7627C11.9924 5.0752 11.2799 5.3377 10.7924 5.3377H8.62988C7.30488 5.3377 6.21738 6.4252 6.21738 7.7502V9.8877C6.21738 10.3752 5.95488 11.0752 5.64238 11.4502L3.95488 13.4377C3.22988 14.3002 3.22988 15.7002 3.95488 16.5627L5.64238 18.5502C5.95488 18.9252 6.21738 19.6252 6.21738 20.1127V22.2502C6.21738 23.5752 7.30488 24.6627 8.62988 24.6627H10.7924C11.2799 24.6627 11.9924 24.9252 12.3674 25.2502L14.3424 26.9502C15.2049 27.6877 16.6174 27.6877 17.4924 26.9502L19.4674 25.2502C19.8424 24.9252 20.5424 24.6627 21.0424 24.6627H23.1674C24.4924 24.6627 25.5799 23.5752 25.5799 22.2502V20.1252C25.5799 19.6377 25.8424 18.9252 26.1674 18.5502L27.8674 16.5752C28.5924 15.7127 28.5924 14.2877 27.8549 13.4252ZM21.1049 12.6377L15.0674 18.6752C14.8924 18.8502 14.6549 18.9502 14.4049 18.9502C14.1549 18.9502 13.9174 18.8502 13.7424 18.6752L10.7174 15.6502C10.3549 15.2877 10.3549 14.6877 10.7174 14.3252C11.0799 13.9627 11.6799 13.9627 12.0424 14.3252L14.4049 16.6877L19.7799 11.3127C20.1424 10.9502 20.7424 10.9502 21.1049 11.3127C21.4674 11.6752 21.4674 12.2752 21.1049 12.6377Z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="#EE712B">
            <path d="M27.8549 13.4246L26.1674 11.4496C25.8549 11.0746 25.5924 10.3746 25.5924 9.87461V7.74961C25.5924 6.42461 24.5049 5.33711 23.1799 5.33711H21.0549C20.5549 5.33711 19.8424 5.07461 19.4674 4.76211L17.4924 3.07461C16.6299 2.33711 15.2174 2.33711 14.3549 3.07461L12.3549 4.76211C11.9799 5.07461 11.2799 5.33711 10.7799 5.33711H8.61738C7.29238 5.33711 6.20488 6.42461 6.20488 7.74961V9.87461C6.20488 10.3621 5.95488 11.0621 5.64238 11.4371L3.95488 13.4246C3.22988 14.2996 3.22988 15.6996 3.95488 16.5496L5.64238 18.5371C5.95488 18.8996 6.20488 19.6121 6.20488 20.0996V22.2371C6.20488 23.5621 7.29238 24.6496 8.61738 24.6496H10.7924C11.2799 24.6496 11.9924 24.9121 12.3674 25.2246L14.3424 26.9121C15.2049 27.6496 16.6174 27.6496 17.4799 26.9121L19.4549 25.2246C19.8299 24.9121 20.5299 24.6496 21.0299 24.6496H23.1549C24.4799 24.6496 25.5674 23.5621 25.5674 22.2371V20.1121C25.5674 19.6121 25.8299 18.9121 26.1424 18.5371L27.8299 16.5621C28.5924 15.7121 28.5924 14.2996 27.8549 13.4246ZM14.9674 10.1621C14.9674 9.64961 15.3924 9.22461 15.9049 9.22461C16.4174 9.22461 16.8424 9.64961 16.8424 10.1621V16.1996C16.8424 16.7121 16.4174 17.1371 15.9049 17.1371C15.3924 17.1371 14.9674 16.7121 14.9674 16.1996V10.1621ZM15.9049 21.0871C15.2174 21.0871 14.6549 20.5246 14.6549 19.8371C14.6549 19.1496 15.2049 18.5871 15.9049 18.5871C16.5924 18.5871 17.1549 19.1496 17.1549 19.8371C17.1549 20.5246 16.6049 21.0871 15.9049 21.0871Z" />
          </svg>
        )}
      </div>
    )
  }
];

export default columns;
