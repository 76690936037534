import { dateTimeFormat } from "src/utils/utils";
import styles from "./cardSetting.module.scss";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Id",
    dataIndex: "card_setting_id",
    key: "card_setting_id"
  },
  {
    title: "Ppan",
    dataIndex: "ppan",
    key: "ppan",
    render: (ppan: string) => <div>{ppan ? "****" + ppan.slice(-4) : ""}</div>
  },
  {
    title: "Card Setting Status",
    dataIndex: "card_setting_status",
    key: "card_setting_status"
  },
  {
    title: "Card Id",
    dataIndex: "card_id",
    key: "card_id"
  },
  {
    title: "Expiry At",
    dataIndex: "expires_at",
    key: "expires_at",
    render: (expires_at: string) => <div>{dateTimeFormat(Number(expires_at)).datetime}</div>
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => (
      <div className={styles.tableCardStatus}>
        <div className={`${status === "ACTIVE" ? styles.green : styles.grey}`}>{status}</div>
      </div>
    )
  },
  {
    title: "User",
    dataIndex: "user_id",
    key: "user_id",
    render: (user_id: string) => <Link to={`/users/${user_id}`}>{user_id}</Link>
  },
  {
    title: "Wallet",
    dataIndex: "wallet_id",
    key: "wallet_id",
    render: (wallet_id: string) => <Link to={`/wallets/${wallet_id}`}>{wallet_id}</Link>
  },
  {
    title: "Type",
    dataIndex: "sub_type",
    key: "sub_type"
  }
];

export default columns;
