import { User } from "src/types/commonTypes";
import styles from "./internalcards.module.scss";
import { Card } from "./internalcardSlice";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Card No.",
    dataIndex: "cardNumber",
    key: "cardNumber",
    render: (text: string, card: Card) => (
      <div>
        {card?.cardHolderName ? card?.cardHolderName + "-" : ""}{" "}
        {card.cardNumber ? "****" + card.cardNumber.slice(-4) : ""}
      </div>
    )
  },
  {
    title: "Client Id",
    dataIndex: "clientId",
    key: "clientId"
  },
  {
    title: "Organisation",
    dataIndex: "organisationId",
    key: "organisationId",
    render: (organisationId: string) => (
      <div className={styles.edit}>
        <Link to={`/organisation/${organisationId}`}>{organisationId}</Link>
      </div>
    )
  },
  {
    title: "Order Id",
    dataIndex: "orderId",
    key: "orderId"
  },
  {
    title: "Status",
    dataIndex: "cardStatus",
    key: "cardStatus",
    render: (cardStatus: string) => (
      <div className={styles.tableCardStatus}>
        <div className={`${cardStatus === "ACTIVE" ? styles.green : styles.grey}`}>{cardStatus}</div>
      </div>
    )
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    render: (user?: User) => (
      <div>
        <div>{user?.firstName + " " + user?.lastName}</div>
        <div>{user?.email}</div>
      </div>
    )
  },
  {
    title: "Access Type",
    dataIndex: "accessType",
    key: "accessType"
  }
];

export default columns;
