import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import swal from "sweetalert2";
import { User, Gender, Initial, kycStatusType } from "src/types/commonTypes";
import {
  getUserById,
  updateUser,
  getUserWallets,
  UpdateUserRequest,
  updateUserRole,
  UpdateUserRoleRequest,
  searchUser
} from "src/network/graphql/userService";
import { Transaction } from "../transactions/transactionSlice";
import {
  SearchWalletRequest,
  connectUserToWallet,
  removeUserFromWallet,
  searchWallet
} from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";
import { immediateToast } from "izitoast-react";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";
import { resetPassword } from "src/network/graphql/configService";
import { Card } from "../cards/cardSlice";
import { CardSearchRequest, cardSearch } from "src/network/graphql/cardService";
import { Users } from "../users/usersSlice";

export type UserDetail = {
  user: {
    id: number;
    cognitoUserName: string;
    displayId: string;
    active: boolean;
    email: string;
    userName: string;
    initial: Initial | null;
    firstName: string;
    lastName: string;
    dob: string;
    phoneNumber: string;
    gender: Gender | null;
    role: {
      organisation_id: string;
      value: string;
    }[];
    state: string;
    city: string;
    line1: string;
    line2: string;
    zipcode: string;
    country: string;
    accessCode: string;
    fullName: string;
    guardianId: number;
    kycStatus: kycStatusType | null;
    profileImage: string;
  };
  guardian: User | null;
  canDoActiveInactive: boolean;
  canEditRole: boolean;
  canEditGuardian: boolean;
  canEditKycStatus: boolean;
};

export const initialUserDetail: UserDetail = {
  user: {
    id: 0,
    cognitoUserName: "",
    displayId: "",
    active: false,
    email: "",
    userName: "",
    initial: "Mr",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    gender: "male",
    role: [],
    state: "",
    city: "",
    line1: "",
    line2: "",
    zipcode: "",
    country: "",
    accessCode: "",
    fullName: "",
    guardianId: 0,
    kycStatus: "Fail",
    profileImage: ""
  },
  guardian: null,
  canDoActiveInactive: false,
  canEditRole: false,
  canEditGuardian: false,
  canEditKycStatus: false
};

type GuardianDetail = {
  user: {
    id: number;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  };
};

const initialGuardianDetail: GuardianDetail = {
  user: {
    id: 0,
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    role: ""
  }
};

const initialWallets: Wallet[] = [];

const initialTransaction: Transaction[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: walletTransaction[] = [];
const initialCards: Card[] = [];
const initialUsers: Users[] = [];

const userDetailSlice = createSlice({
  name: "userDetail",
  initialState: {
    userDetailLoading: false,
    walletLoading: false,
    walletListLoading: false,
    transLoading: false,
    walletTransLoading: false,
    userLoading: false,
    guardianDetailloading: false,
    resetPasswordLoading: false,
    participantLoading: false,
    participantWalletLoading: false,
    loading: false,
    error: null,
    userDetail: initialUserDetail,
    WalletConnect: {
      wallets: initialWallets,
      total: 0
    },
    walletList: {
      list: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    walletTransaction: initialwalletTransaction,
    guardianDetail: initialGuardianDetail,
    cards: {
      list: initialCards,
      total: 0
    },
    participants: {
      list: initialUsers,
      total: 0
    },
    participantWallet: {
      wallets: initialWallets,
      total: 0
    }
  },
  reducers: {
    fetchUserDetailStart: (state) => {
      state.userDetailLoading = true;
    },
    fetchUserDetailSuccess: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = action.payload ?? initialUserDetail;
    },
    fetchUserDetailFail: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = initialUserDetail;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = action.payload?.list ?? [];
      state.WalletConnect.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = [];
      state.WalletConnect.total = 0;
      state.error = action.payload;
    },
    fetchWalletListStart: (state) => {
      state.walletListLoading = true;
    },
    fetchWalletListSuccess: (state, action) => {
      state.walletListLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletListFail: (state, action) => {
      state.walletListLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = [];
      state.error = action.payload;
    },
    updateUserDetailStart: (state) => {
      state.userDetailLoading = true;
    },
    updateUserDetailSuccess: (state) => {
      state.userDetailLoading = false;
    },
    updateUserDetailFail: (state, action) => {
      state.userDetailLoading = false;
      state.error = action.payload;
    },
    updateUserRoleStart: (state) => {
      state.userDetailLoading = true;
    },
    updateUserRoleSuccess: (state) => {
      state.userDetailLoading = false;
    },
    updateUserRoleFail: (state, action) => {
      state.userDetailLoading = false;
      state.error = action.payload;
    },
    addSupporterStart: (state) => {
      state.walletLoading = true;
    },
    addSupporterSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addSupporterFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    deleteSupporterStart: (state) => {
      state.walletLoading = true;
    },
    deleteSupporterSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    deleteSupporterFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    resetPasswordStart: (state) => {
      state.resetPasswordLoading = true;
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordLoading = false;
    },
    resetPasswordFail: (state, action) => {
      state.resetPasswordLoading = false;
      state.error = action.payload;
    },
    getAllCardsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllCardsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllCardsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.cards = action.payload;
    },
    fetchParticpantStart: (state) => {
      state.participantLoading = true;
    },
    fetchParticpantSuccess: (state, action) => {
      state.participantLoading = false;
      state.participants.list = action.payload?.list ?? [];
      state.participants.total = action.payload?.total ?? 0;
    },
    fetchParticpantFail: (state, action) => {
      state.participantLoading = false;
      state.participants.list = [];
      state.participants.total = 0;
      state.error = action.payload;
    },
    fetchParticipantWalletStart: (state) => {
      state.participantWalletLoading = true;
    },
    fetchParticipantWalletSuccess: (state, action) => {
      state.participantWalletLoading = false;
      state.participantWallet.wallets = action.payload?.list ?? [];
      state.participantWallet.total = action.payload?.total ?? 0;
    },
    fetchParticipantWalletFail: (state, action) => {
      state.participantWalletLoading = false;
      state.participantWallet.wallets = [];
      state.participantWallet.total = 0;
      state.error = action.payload;
    },
    resetParticipantWallet: (state, action) => {
      state.participantWallet.wallets = action.payload.list;
      state.participantWallet.total = action.payload?.total;
    }
  }
});

const {
  fetchUserDetailStart,
  fetchUserDetailSuccess,
  fetchUserDetailFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchWalletListStart,
  fetchWalletListSuccess,
  fetchWalletListFail,
  updateUserDetailStart,
  updateUserDetailSuccess,
  updateUserDetailFail,
  updateUserRoleStart,
  updateUserRoleSuccess,
  updateUserRoleFail,
  addSupporterStart,
  addSupporterSuccess,
  addSupporterFail,
  deleteSupporterStart,
  deleteSupporterSuccess,
  deleteSupporterFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  resetPasswordStart,
  resetPasswordFail,
  resetPasswordSuccess,
  getAllCardsStart,
  getAllCardsSuccess,
  getAllCardsFail,
  fetchParticpantStart,
  fetchParticpantSuccess,
  fetchParticpantFail,
  fetchParticipantWalletStart,
  fetchParticipantWalletSuccess,
  fetchParticipantWalletFail
} = userDetailSlice.actions;

export const { resetParticipantWallet } = userDetailSlice.actions;

export const fetchUserDetailAction = (data: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserDetailStart());
    try {
      const response = await getUserById(data);
      const user = response.data.get_user;

      dispatch(
        fetchUserDetailSuccess({
          user: {
            id: user.user_id,
            cognitoUserName: user.cognito_username,
            initial: user.attributes.initial,
            firstName: user.attributes.first_name,
            lastName: user.attributes.last_name,
            email: user.email,
            userName: user.username,
            gender: user.attributes.gender,
            dob: user.attributes.date_of_birth,
            role: user.roles,
            phoneNumber: user.phone,
            line1: user.attributes.address.line_1,
            line2: user.attributes.address.line_2,
            city: user.attributes.address.city,
            state: user.attributes.address.state,
            zipcode: user.attributes.address.post_code,
            country: user.attributes.address.country,
            active: user.is_active,
            kycStatus: user.security_status,
            guardianId: user.guardian_id,
            profileImage: user.attributes.profile_image,
            displayId: user.display_id
          }
        })
      );
    } catch (error) {
      dispatch(fetchUserDetailFail(error));
    }
  };
};

export const fetchWalletConnectAction = (data: { userId: string }) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      if (data.userId != undefined) {
        const response = await getUserWallets(data.userId);

        const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
          id: wallet.wallet_id,
          displayId: wallet.display_id,
          description: wallet.description,
          name: wallet.name,
          walletType: wallet.wallet_type,
          participant: { fullName: wallet?.user?.attributes?.first_name + " " + wallet?.user?.attributes?.last_name },
          balance: wallet.balance
        }));

        dispatch(
          fetchWalletsSuccess({
            list: structuredResponse
          })
        );
      }
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletListStart());
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        walletType: wallet.wallet_type,
        name: wallet.name,
        participant: {
          role: "PARTICIPANT",
          fullName: `${wallet.user?.attributes?.first_name ?? "-"} ${wallet.user?.attributes?.last_name ?? "-"}`,
          email: wallet?.user?.email
        }
      }));

      dispatch(
        fetchWalletListSuccess({
          list: structuredResponse,
          total: response.data.search_wallet.total_size
        })
      );
    } catch (error) {
      dispatch(fetchWalletListFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        approval_number: data.approval_number,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchTransListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};

export const fetchWalletTransactionAction = (data: any) => {
  return data;
};

export const updateUserDetailAction = (data: UpdateUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateUserDetailStart());
    try {
      await updateUser(data);

      dispatch(updateUserDetailSuccess());
      if (cb) cb();

      swal.fire({
        position: "center",
        icon: "success",
        title: "User info updated Sucessfully",
        showConfirmButton: false,
        timer: 2500
      });
    } catch (error) {
      dispatch(updateUserDetailFail(error));
    }
  };
};

export const updateUserRoleAction = (data: UpdateUserRoleRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateUserRoleStart());
    try {
      const response = await updateUserRole(data);

      dispatch(updateUserRoleSuccess());
      return response;
    } catch (error) {
      dispatch(updateUserRoleFail(error));
    }
  };
};

export const addWalletSupporterAction = (walletId: string, userId: string, toAll: boolean = false, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addSupporterStart());
    try {
      await connectUserToWallet(walletId, userId, toAll);

      dispatch(addSupporterSuccess());
      immediateToast("success", {
        message: "User connected successfully.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
    } catch (error) {
      dispatch(addSupporterFail(error));
    }
  };
};

export const deleteWalletSupporterAction = (walletId: string, userId: string, removeFromAll: boolean) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deleteSupporterStart());
    try {
      await removeUserFromWallet(walletId, userId, removeFromAll);

      dispatch(deleteSupporterSuccess());
      immediateToast("success", {
        message: "User Removed Successfully",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(deleteSupporterFail(error));
    }
  };
};

export const resetPasswordAction = (userId: string, cognitoUserName: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(resetPasswordStart());
    try {
      await resetPassword(userId, cognitoUserName);

      dispatch(resetPasswordSuccess());
      if (cb) cb();

      swal.fire({
        position: "center",
        icon: "success",
        title: "Reset password Sucessfull",
        showConfirmButton: false,
        timer: 2500
      });
    } catch (error) {
      dispatch(resetPasswordFail(error));
    }
  };
};

export const getAllCardsAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getAllCardsStart());
    try {
      const response = await cardSearch(data);
      const structuredResponse = response.data.search_card.card_listing?.map(
        (card: any) =>
          ({
            id: card.card_id,
            bsb: card.bsb,
            cardStatus: card.card_status,
            accessType: card.card_type,
            cardNumber: card.ppan,
            orderId: card.order_id,
            clientId: card.clientId,
            cardLimit: card.card_limit,
            cardHolderName: card.holder_name,
            user: {
              id: card?.user_id,
              firstName: card?.user?.attributes?.first_name ?? "---",
              lastName: card?.user?.attributes?.last_name ?? " ",
              email: card?.user?.email ?? " "
            }
          }) as Card
      );

      dispatch(
        getAllCardsSuccess({
          list: structuredResponse,
          total: response.data.search_card.total_size
        })
      );
    } catch (error) {
      dispatch(getAllCardsFail(error));
    }
  };
};

export const fetchParticpantAction = (
  data: {
    isActive?: boolean | null;
    email?: string;
    fullName?: string;
    organisationId?: string | null;
    limit?: number;
    from?: number;
    role?: string;
  },
  cb?: () => void
) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchParticpantStart());
    try {
      const response = await searchUser(data);

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user?.display_id,
        firstName: user?.attributes?.first_name,
        lastName: user?.attributes?.last_name,
        email: user?.email,
        role: user?.current_organisation_role,
        status: user?.is_active,
        userId: user?.user_id,
        createdAt: user?.created_at
      }));

      dispatch(
        fetchParticpantSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchParticpantFail(error));
    }
  };
};

export const fetchParticipantWalletAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchParticipantWalletStart());
    try {
      const response = await getUserWallets(userId);

      const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        // status: wallet.wallet_status,
        walletType: wallet.wallet_type,
        name: wallet.name,
        participant: {
          role: "PARTICIPANT",
          email: wallet?.user?.email,
          fullName: wallet?.user?.attributes?.first_name + " " + wallet?.user?.attributes?.last_name
        }
      }));

      dispatch(
        fetchParticipantWalletSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
    } catch (error) {
      dispatch(fetchParticipantWalletFail(error));
    }
  };
};

export default userDetailSlice.reducer;
