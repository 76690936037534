import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./mockmodal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface MockModalProp {
  handleClose: () => void;
  onAddWallet: (_cardId: string, _amount: number, _close: boolean) => void;
}

export default function MockModal({ handleClose, onAddWallet }: MockModalProp) {
  const [cardId, setCardId] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [error, setError] = useState({
    card: "",
    //  participant: "",
    amount: ""
  });

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    if (
      !cardId.trim() ||
      !amount.trim()
      // || !participant
    ) {
      setError({
        card: cardId ? "" : "card Id is required",
        amount: amount ? "" : "amount is required"
      });
      return;
    }

    // if (Number(amount) <= 0) {
    //   setError({
    //     ...error,
    //     amount: amount ? "" : "amount can't be less that or equal to 0"
    //   });
    //   return;
    // }

    onAddWallet(cardId, Number(amount), false);
    // }
  };

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Mock Purchase</h5>
            <button onClick={handleCancel} aria-label="close">
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.text}>
            <label htmlFor="cardId">Card Id</label>
            <input
              type="text"
              id="cardId"
              placeholder="Enter wallet name here"
              value={cardId}
              onChange={(e) => setCardId(e.target.value)}
              required
            />
            {error.card && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.card}
              </div>
            )}
          </div>
          <div className={styles.text}>
            <label htmlFor="amount">amount</label>
            <input
              type="number"
              id="amount"
              placeholder="Enter description here"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
            {error.amount && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.amount}
              </div>
            )}
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleSave}>
              Submit
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
