import { Avatar } from "@mui/material";
import styles from "../UserListCard.module.scss";
import { Link } from "react-router-dom";
import { bgColorbyName, roleColors } from "src/variable/randomColor";
import { roleMapping } from "src/components/constant/constant";
import { Users } from "src/page/users/usersSlice";
import HighLighter from "src/components/UI/highlighter/HighLighter";
import moment from "moment";
// import { dateTimeFormat } from "src/utils/utils";

const columns = (searchText?: string) => [
  {
    title: "Id",
    dataIndex: "userId",
    key: "userId",
    render: (userId: number) => <div className={styles.id}>{userId}</div>
  },
  {
    title: "Display Id",
    dataIndex: "id",
    key: "id",
    render: (id: number) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Date / Time",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    render: (createdAt: string) => {
      if (createdAt === undefined || null) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }
      const formattedDate = moment(createdAt).format("DD/MM/YYYY");
      const formattedTime = moment(createdAt).format("hh:mm A");

      return (
        <>
          {/* <div className={styles.tableDate}>{dateTimeFormat(createdAt)?.date}</div>
          <div className={styles.tableDate}>{dateTimeFormat(createdAt)?.time}</div> */}
          <div className={styles.tableDate}>{formattedDate}</div>
          <div className={styles.tableDate}>{formattedTime}</div>
        </>
      );
    }
  },
  {
    title: "Member",
    dataIndex: "fullName",
    key: "fullName",
    // width: 150,
    render: (email: string, data: Users) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberImg}>
          {/* <img src={} alt="Member" /> */}
          <Avatar id="av" aria-label="Recipe" style={{ backgroundColor: bgColorbyName(data?.firstName) }}>
            {/* {this.props.userName.charAt(0).toLocaleUpperCase()} */}
            {data?.firstName?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>
            <HighLighter searchText={searchText || ""} text={`${data?.firstName} ${data?.lastName}`} />
          </div>
          <div className={styles.tableMemberEmail}>
            <HighLighter searchText={searchText || ""} text={`${data?.email}`} />
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    // width: 100,
    render: (role: string) => {
      const userRole = role;

      return (
        <div className={styles.tableRole}>
          <div
            className={styles.tableRoleName}
            style={{
              backgroundColor: `${roleColors[userRole || "NOROLE"]}33`,
              borderColor: `${roleColors[userRole || "NOROLE"]}73`
            }}
          >
            {roleMapping[userRole || "NOROLE"]}
          </div>
        </div>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    // width: 150,
    render: (status: boolean) => (
      <div className={styles.tableStatus}>
        <div className={`${status ? styles.green : styles.grey}`}>{status ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  // {
  //   title: "Last Active",
  //   dataIndex: "lastActive",
  //   key: "lastActive",
  //   // width: 200,
  //   render: () => <div className={styles.tableLastActive}>{"22/03/2023"}</div>
  // },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (data: Users) => {
      return (
        <Link
          to={`/users/${data.userId}`}
          aria-label={`View details for user ${data.firstName} ${data.lastName}`}
          title={`View details for user ${data.firstName} ${data.lastName}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      );
    }
    // width: 100,
  }
];

export default columns;
