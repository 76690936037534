import TablePagination from "@mui/material/TablePagination";
import styles from "./cardSetting.module.scss";
import Button from "@mui/material/Button";
import Table from "rc-table";
import columns from "./cardSettingConfig";
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import { CARDSETTING_LIMIT, CARDSETTING_PAGE } from "src/components/constant/queryConstants";
import { useLocation, useSearchParams } from "react-router-dom";
import Modalui from "src/components/UI/modal/Modalui";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import FilterTag from "src/components/UI/filterTag/FilterTag";
import { FilterTagType, generateFilterTags } from "src/components/UI/filterTag/filterTagUtils";
import { setCardSettingFilters, setColumnFilter } from "../filtersSlice";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";
import { CardSettingType, deleteCardSettingAction, getCardSettingAction } from "./cardSettingSlice";
import AddIcon from "@mui/icons-material/Add";
import SinglePay from "src/components/newPaymentCards/SinglePay";
import swal from "sweetalert2";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const CardSettingFilter = lazy(() => import("src/components/cardSetting/cardSettingFilter/CardSettingFilter"));

export default function CardSetting() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { cards, loading } = useAppSelector((state) => state.cardSetting);
  const dispatch = useAppDispatch();
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  const { cardSetting: cardFilters, column: columnFilter } = useAppSelector((state) => state.filters);
  const { cognitoConfig } = useAppSelector((state) => state.auth);

  const page = searchParams.get(CARDSETTING_PAGE) ?? "0";
  const rowsPerPage = searchParams.get(CARDSETTING_LIMIT) ?? "10";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(CARDSETTING_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(CARDSETTING_LIMIT, event.target.value as string);
    searchParams.set(CARDSETTING_PAGE, "0");
    setSearchParams(searchParams);
  };

  const fetchCardSetting = useCallback(() => {
    const pageNo = parseInt(page);
    const limit = parseInt(rowsPerPage);
    const cardSettingFilter: any = {
      organisation_id: cognitoConfig?.currentOrganisation?.organisation_id
    };

    if (cardFilters.status) {
      cardSettingFilter.status = cardFilters.status;
    }
    if (cardFilters.wallet) {
      cardSettingFilter.wallet_id = cardFilters.wallet.id;
    }
    if (cardFilters.card) {
      cardSettingFilter.card_id = cardFilters.card.id;
    }

    dispatch(
      getCardSettingAction({
        cardSettingFilter,
        size: limit,
        from: pageNo * limit
      })
    );
  }, [page, rowsPerPage, cardFilters, dispatch]);

  useEffect(() => {
    fetchCardSetting();
  }, [fetchCardSetting, location.pathname, cardFilters]);

  const removeTransactionFilter = (filterName: string) => {
    const newFilters = { ...cardFilters, [filterName]: null };
    dispatch(setCardSettingFilters(newFilters));
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.cardSetting?.includes(col.key));
  }, [columnFilter?.cardSetting]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        cardSetting: selectedColumns
      })
    );
  };

  const deleteTransaction = (data: CardSettingType) => {
    swal
      .fire({
        title: "Are you sure! you want to remove this card setting?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#1e293b",
        confirmButtonText: "Yes, remove it!",
        customClass: {
          popup: "swal2-popup-custom"
        }
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteCardSettingAction(data.card_id, data.wallet_id, () => {
              fetchCardSetting();
            })
          );
        }
      });
  };

  const operation = {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (record: CardSettingType) => (
      <>
        {record.status === "ACTIVE" && (
          <div onClick={() => deleteTransaction(record)} className={styles.operation}>
            <DeleteOutlineOutlinedIcon sx={{ color: "#e61e1e" }} />
          </div>
        )}
      </>
    )
  };

  const updatedColumns = [...filteredColumns, operation];

  return (
    <>
      <Modalui open={openFilterCard} handleClose={() => setOpenFilterCard(false)} modaluiOver={styles.filterModalOver}>
        <Suspense fallback={<CircularLoader />}>
          <CardSettingFilter
            handleClose={() => {
              setOpenFilterCard(false);
            }}
          />
        </Suspense>
      </Modalui>
      <Modalui open={openCreateCard} handleClose={() => setOpenCreateCard(false)} modaluiOver={styles.filterModalOver}>
        <Suspense fallback={<CircularLoader />}>
          <SinglePay
            onClose={() => setOpenCreateCard(false)}
            cb={() => {
              fetchCardSetting();
              setOpenCreateCard(false);
            }}
          />
        </Suspense>
      </Modalui>
      <div className={styles.cardsWrapper}>
        <div className={styles.WelCard}>
          <TransactionWelCard heading={"View Your Card Setting"} subHeading={"View details of all the card setting"} />
        </div>
        <div className={styles.cardList}>
          <div className={styles.cardListHead}>
            <div className={styles.cardListHeadIn}>
              <div className={styles.cardListHeadD}>
                <h3>Card Setting list</h3>
                <p>Manage all the card setting</p>
              </div>
              <div className={styles.cardListHeadBtn}>
                <Button variant="contained" className={styles.addcardSetting} onClick={() => setOpenCreateCard(true)}>
                  <AddIcon fontSize="small" />
                  Load Cards
                </Button>
                <Button variant="contained" className={styles.filterBtn} onClick={() => setOpenFilterCard(true)}>
                  Filters
                </Button>
              </div>
            </div>
            <div className={styles.cardListFilter}>
              <div className={styles.filterTagsContainer}>
                {generateFilterTags(cardFilters).map((filterTag: FilterTagType) => (
                  <FilterTag
                    key={filterTag.key}
                    tagKey={filterTag.key}
                    label={filterTag.label}
                    onRemove={() => removeTransactionFilter(filterTag.key)}
                  />
                ))}
              </div>
            </div>
            <TableColumnFilter
              columns={columns}
              selectedColumn={columnFilter?.cardSetting}
              mandatoryColumns={["card_setting_id", "card_id"]}
              onApply={handleColumnSelection}
            />
          </div>
          <div className={styles.cardListTable}>
            <Table
              columns={updatedColumns}
              data={loading ? [] : cards.list}
              className={styles.tableIn}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={cards.total ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(rowsPerPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
