import styles from "./personWelCard.module.scss";
import { Avatar, Button } from "@mui/material";
import { bgColorbyName } from "src/variable/randomColor";
import { checkPermission } from "src/utils/utils";
import { CAN_DEPOSIT } from "../constant/constant";
import { Suspense, useState } from "react";
import Modalui from "../UI/modal/Modalui";
import CircularLoader from "../UI/circularLoader/CircularLoader";
import DepositModal from "../depositModal/DepositModal";
import { fetchDepositListAction, fetchWalletDetailAction, walletDetail } from "src/page/walletDetail/walletDetailSlice";
import { useAppDispatch } from "src/page/store";

type Iprops = {
  id?: number | string;
  img?: string;
  imgName?: string;
  heading?: string;
  subHeading1?: string;
  subHeading2?: string;
  connectedTo?: string;
  welCardLOver?: string;
  status?: boolean;
  walletDetail?: walletDetail;
};

export default function PersonWelCard({
  img,
  imgName,
  heading,
  subHeading1,
  subHeading2,
  welCardLOver,
  connectedTo,
  walletDetail
}: Iprops) {
  const dispatch = useAppDispatch();
  const [openDepositModal, setOpenDepositModal] = useState<boolean>(false);

  return (
    <>
      <Modalui
        open={openDepositModal}
        handleClose={() => {
          setOpenDepositModal(false);
        }}
      >
        <Suspense fallback={<CircularLoader />}>
          <DepositModal
            particpant={{
              name: walletDetail?.wallet?.participant?.firstName + " " + walletDetail?.wallet?.participant?.lastname,
              email: String(walletDetail?.wallet?.participant?.email),
              userId: String(walletDetail?.wallet?.participant?.id),
              displayId: String(walletDetail?.wallet?.participant?.displayId)
            }}
            wallet={{
              walletId: String(walletDetail?.wallet?.id),
              displayId: String(walletDetail?.wallet?.displayId),
              type: String(walletDetail?.wallet?.walletType),
              name: String(walletDetail?.wallet?.name),
              description: String(walletDetail?.wallet?.description)
            }}
            handleClose={() => {
              setOpenDepositModal(false);
            }}
            cb={() => {
              if (walletDetail?.wallet?.id) {
                dispatch(fetchWalletDetailAction(String(walletDetail?.wallet?.id)));
                dispatch(
                  fetchDepositListAction({
                    size: 5,
                    from: 0,
                    filter: {
                      transactionType: "DEPOSIT",
                      walletIds: [String(walletDetail?.wallet?.id)]
                    }
                  })
                );
              }
            }}
          />
        </Suspense>
      </Modalui>
      <div className={styles.welCard}>
        <div className={styles.welCardL}>
          <div className={styles.userImage}>
            <Avatar
              id="av"
              className={`${styles.av} ${welCardLOver}`}
              aria-label="Recipe"
              style={{ backgroundColor: bgColorbyName(imgName || "") }}
            >
              {img}
            </Avatar>
          </div>
          <div className={styles.userName}>
            <h3>{heading}</h3>
            <p>
              {subHeading1} | {subHeading2}
            </p>
          </div>
        </div>
        <div className={styles.welCardR}>
          {checkPermission(CAN_DEPOSIT) && connectedTo === "Wallet" && (
            <Button variant="contained" onClick={() => setOpenDepositModal(true)}>
              Deposit
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
