import { createSlice } from "@reduxjs/toolkit";
import store, { StoreDispatch } from "../store";
import { CardSearchRequest, cardSearch, changeCardPin } from "src/network/graphql/cardService";
import { Card } from "../cards/cardSlice";
import swal from "sweetalert2";
import { SHARED } from "src/components/constant/constant";

const initialCards: Card[] = [];

const changePinSlice = createSlice({
  name: "changeCardSlice",
  initialState: {
    cardLoading: false,
    error: null,
    cards: {
      list: initialCards,
      total: 0
    }
  },
  reducers: {
    fetchCardsStart: (state) => {
      state.cardLoading = true;
      state.error = null;
    },
    fetchCardsSuccess: (state, action) => {
      state.cardLoading = false;
      state.cards.list = action.payload?.list ?? [];
      state.cards.total = action.payload?.total || 0;
    },
    fetchCardsFail: (state, action) => {
      state.cardLoading = false;
      state.cards.list = [];
      state.cards.total = 0;
      state.error = action.payload;
    },
    changeCardPinStart: (state) => {
      state.cardLoading = true;
    },
    changeCardPinSuccess: (state) => {
      state.cardLoading = false;
    },
    changeCardPinFail: (state, action) => {
      state.cardLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchCardsStart,
  fetchCardsSuccess,
  fetchCardsFail,
  changeCardPinSuccess,
  changeCardPinStart,
  changeCardPinFail
} = changePinSlice.actions;

export const fetchCardListAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchCardsStart());
    try {
      const { auth } = store.getState();
      const response = await cardSearch(data);
      const structuredResponse = response?.data?.search_card.card_listing
        .filter((card: any) => card?.user_id === auth?.userInfo?.id || card?.card_type === SHARED)
        .map((card: any) => ({
          id: card?.card_id,
          cardStatus: card?.card_status,
          cardNumber: card?.ppan,
          user: {
            id: card?.user_id,
            fullName:
              card?.holder_name != null && card?.holder_name.trim() !== ""
                ? card?.holder_name
                : card?.user?.attributes.first_name + " " + card?.user?.attributes.last_name
          }
        }));

      dispatch(
        fetchCardsSuccess({
          list: structuredResponse,
          total: response.data.search_card.total_size
        })
      );
    } catch (error) {
      dispatch(fetchCardsFail(error));
    }
  };
};

export const changeCardPinAction = (cardId: string, CardNewPin: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(changeCardPinStart());
    try {
      const response = await changeCardPin(cardId, CardNewPin);

      dispatch(changeCardPinSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "CardPin Change Sucessfully",
        showConfirmButton: false,
        timer: 2500
      });
      return response;
    } catch (err) {
      dispatch(changeCardPinFail(err));
    }
  };
};

export default changePinSlice.reducer;
