import styles from "./newPayment.module.scss";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TollIcon from "@mui/icons-material/Toll";
import { useState } from "react";
import SinglePay from "src/components/newPaymentCards/SinglePay";
import PayCash from "src/components/newPaymentCards/PayCash";
import { useAppSelector } from "../store";
import InternalPay from "src/components/newPaymentCards/InternalPay";
import { checkPermission } from "src/utils/utils";
import {
  PARTICIPANT,
  CAN_REPORT_CASH_TRANSACTION,
  CAN_TRANSFER_BETWEEN_WALLET,
  CAN_LOAD_CARD
} from "src/components/constant/constant";

export default function NewPayment() {
  const { userInfo } = useAppSelector((state) => state.auth);
  const [singlePay, setSinglePay] = useState<boolean>(false);
  const [internalPay, setInternalPay] = useState<boolean>(false);
  const [cashPay, setCashPay] = useState<boolean>(false);

  const handleSinglePay = (value: boolean) => {
    setSinglePay(value);
  };

  const handleInternalPay = () => {
    setInternalPay(false);
  };

  const handleCashPay = () => {
    setCashPay(false);
  };

  return (
    <div className={styles.container}>
      {singlePay || internalPay || cashPay ? (
        <>
          {singlePay && <SinglePay onClose={handleSinglePay} />}
          {internalPay && <InternalPay onClose={handleInternalPay} />}
          {cashPay && <PayCash onClose={handleCashPay} />}
        </>
      ) : (
        <div className={styles.newPaymentButton}>
          {checkPermission(CAN_LOAD_CARD) && (
            <div
              className={styles.singlePayOp}
              onClick={() => {
                setSinglePay(true);
              }}
            >
              <CreditCardIcon />
              <p>{userInfo?.role === PARTICIPANT ? "Pay for an Expense" : "Pay for a Participant / Expense"}</p>
            </div>
          )}

          {checkPermission(CAN_REPORT_CASH_TRANSACTION) && (
            <div
              className={styles.singlePayOp}
              onClick={() => {
                setCashPay(true);
              }}
            >
              <CreditCardIcon />
              <p>Pay Cash Transaction</p>
            </div>
          )}

          {checkPermission(CAN_TRANSFER_BETWEEN_WALLET) && (
            <div
              className={styles.moneyBtwWallet}
              onClick={() => {
                setInternalPay(true);
              }}
            >
              <TollIcon />
              <p>Transfer Money Between Wallets</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
