import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { searchAsset, searchAssetRequest } from "src/network/graphql/transactionService";

export type InternalFiles = {
  assetId: string;
  assetType: string;
  bucketKey: string;
  bucketName: string;
  date: string;
  processStatus: string;
  fileName: string;
  totalRow: number;
};

const initialFile: InternalFiles[] = [];

const internalfileSlice = createSlice({
  name: "files",
  initialState: {
    loading: false,
    error: null,
    internalfiles: {
      list: initialFile,
      size: 0
    }
  },
  reducers: {
    fetchFilesStart: (state) => {
      state.loading = true;
    },
    fetchFilesSuccess: (state, action) => {
      state.loading = false;
      state.internalfiles = action.payload;
    },
    fetchFilesFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { fetchFilesStart, fetchFilesSuccess, fetchFilesFail } = internalfileSlice.actions;

export const fetchFilesAction = (data: searchAssetRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchFilesStart());
    try {
      const response = await searchAsset(data);
      const structuredResponse = response.data.search_file.files?.map((data: any) => ({
        assetId: data.file_id,
        bucketKey: data.attributes.key,
        bucketName: data.attributes.bucket_name,
        date: data.attributes.date,
        processStatus: data.process_status,
        assetType: data?.file_type,
        fileName: data?.attributes?.original_filename,
        totalRow: data.attributes.total_rows
      }));

      dispatch(
        fetchFilesSuccess({
          list: structuredResponse,
          size: response.data.search_file.total_size
        })
      );
    } catch (error) {
      dispatch(fetchFilesFail(error));
    }
  };
};

export default internalfileSlice.reducer;
