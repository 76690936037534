import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./cardFilter.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { resetCardFilters, setCardFilters } from "src/page/filtersSlice";
import { User } from "src/types/commonTypes";
import { debounce } from "lodash";
import { Wallet } from "src/page/wallets/walletSlice";
import { fetchUserListAction, setUsersAction, setWalletOptionsAction } from "src/page/internalcards/internalcardSlice";

interface FilterTransModalProps {
  handleClose: () => void;
}

export default function CardFilterModal({ handleClose }: FilterTransModalProps) {
  const { users } = useAppSelector((state) => state.cards);
  const { card: cardFilters } = useAppSelector((state) => state.filters);

  const [selectedWallet, setSelectedWallet] = useState<Wallet[]>(cardFilters.wallet || []);
  const [selectedUser, setSelectedUser] = useState<User[]>(cardFilters.user || []);
  const [status, setStatus] = useState<string | null>(cardFilters.status);
  const [cardAccessType, setCardAccessType] = useState<string | null>(cardFilters.type);
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    handleClose();
    dispatch(setUsersAction([]));
    dispatch(setWalletOptionsAction([]));
  };

  const handleApplyFilter = () => {
    dispatch(
      setCardFilters({
        user: selectedUser,
        wallet: selectedWallet,
        type: cardAccessType,
        status
      })
    );
    handleCancel();
  };

  const handleReset = () => {
    dispatch(resetCardFilters());
    setSelectedUser([]);
    setSelectedWallet([]);
    handleCancel();
  };

  const handleUserInput = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Filter Cards</h5>
            <button onClick={handleCancel} aria-label="close">
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.statusType}>
            <div className={styles.status}>
              <div className={styles.byUserHead}>
                <label className={styles.userlabel}>By Status</label>
              </div>
              <FormControl>
                <Select
                  value={status || ""}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    color: {
                      ACTIVE: "",
                      INACTIVE: "",
                      status: "grey",
                      default: "grey"
                    }[status != null ? status + "" : "default"]
                  }}
                >
                  <MenuItem value="" sx={{ color: "grey" }}>
                    Status
                  </MenuItem>
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.type}>
              <div className={styles.byUserHead}>
                <label className={styles.userlabel}>By Access Type</label>
              </div>
              <FormControl>
                <Select
                  value={cardAccessType || ""}
                  onChange={(e) => {
                    setCardAccessType(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    color: {
                      SHARED: "",
                      PRIVATE: "",
                      type: "grey",
                      default: "grey"
                    }[cardAccessType ?? "default"]
                  }}
                >
                  <MenuItem value="" sx={{ color: "grey" }}>
                    None
                  </MenuItem>
                  <MenuItem value="PRIVATE">Private</MenuItem>
                  <MenuItem value="SHARED">Shared</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.addUser}>
            <div className={styles.byUser}>
              <div className={styles.byUserHead}>
                <label>By User</label>
              </div>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={users?.list || []}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option?.firstName ?? option}
                filterOptions={(x) => x}
                onInputChange={handleUserInput}
                value={selectedUser}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Users...." />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>{option?.firstName + " " + option?.lastName}</span>
                    <span style={{ color: "#64748B" }}>{option?.email}</span>
                  </li>
                )}
              />
            </div>
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleReset}>
              Reset Filter
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
