import React, { useState } from "react";
import styles from "./userWelCard.module.scss";
import { Button } from "@mui/material";
import boxLogo from "src/assets/images/cube.png";
import fire from "src/assets/images/fire.png";
import Modalui from "../UI/modal/Modalui";
import VideoModal from "../UI/videoModal/VideoModal";

function UserWelCard() {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <Modalui open={open} handleClose={handleModal} modaluiOver={styles.modaluiOver}>
        <VideoModal handleClose={handleModal} videoLink={"https://www.youtube.com/embed/bgB0Z45dnTs?rel=0&loop=1"} />
      </Modalui>
      <div className={styles.welCard} aria-label="User Welcome Card">
        <div className={styles.welL}>
          <div className={styles.welText}>
            <div className={styles.welCardTitle}>
              Manage your users <img src={fire} alt="fire logo" />
            </div>
            <div className={styles.welCardText}>Keep on top of your users access and activity</div>
          </div>
          <div className={styles.welBtn}>
            <Button variant="contained" onClick={handleModal} aria-label="Watch demo video">
              Watch demo
            </Button>
          </div>
        </div>
        <div className={styles.welR}>
          <img src={boxLogo} alt="box logo" />
        </div>
      </div>
    </>
  );
}

export default UserWelCard;
