import { Button, CircularProgress, Stack } from "@mui/material";
import styles from "./depositModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { addFundsAction } from "src/page/deposit/depositSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface DepositModalProp {
  handleClose: () => void;
  cb?: () => void;
  wallet: {
    name: string;
    description: string;
    type: string;
    walletId: string;
    displayId: string;
  };
  particpant: {
    name: string;
    email: string;
    userId: string;
    displayId: string;
  };
}

export default function DepositModal({ handleClose, particpant, wallet, cb }: DepositModalProp) {
  const { addFundsLoading } = useAppSelector((Rstate) => Rstate.deposit);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<number>(0);
  const [error, setError] = useState("");

  const handleDeposit = () => {
    if (amount <= 0) {
      setError("Amount should be greater than 0");
      return;
    }
    dispatch(
      addFundsAction(
        {
          wallet_id: wallet?.walletId ?? "",
          amount: amount,
          remark: "Deposit"
        },
        () => {
          handleClose();
          if (cb) cb();
        }
      )
    );
  };

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Deposit</h5>
            <button onClick={handleClose} aria-label="close">
              <CloseIcon />
            </button>
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.userInfo}>
              <h4>Participant information</h4>
              <div className={styles.userdata}>
                <div className={styles.label}>
                  <label>Name : </label>
                  <div className={styles.value}>{particpant?.name}</div>
                </div>
                <div className={styles.label}>
                  <label>Email : </label>
                  <div className={styles.value}>{particpant?.email}</div>
                </div>

                <div className={styles.label}>
                  <label>User Display Id : </label>
                  <div className={styles.value}>{particpant?.displayId}</div>
                </div>
                <div className={styles.label}>
                  <label>User Id : </label>
                  <div className={styles.value}>{particpant?.userId}</div>
                </div>
              </div>
            </div>
            <div className={styles.walletInfo}>
              <h4>Wallet information</h4>
              <div className={styles.walletdata}>
                <div className={styles.label}>
                  <label>Name : </label>
                  <div className={styles.value}>{wallet?.name}</div>
                </div>
                <div className={styles.label}>
                  <label>Description : </label>
                  <div className={styles.value}>{wallet?.description}</div>
                </div>
                <div className={styles.label}>
                  <label>Wallet Type : </label>
                  <div className={styles.value}>{wallet?.type}</div>
                </div>
                <div className={styles.label}>
                  <label>Wallet Display Id : </label>
                  <div className={styles.value}>{wallet?.displayId}</div>
                </div>
                <div className={styles.label}>
                  <label>Wallet Id : </label>
                  <div className={styles.value}>{wallet?.walletId}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.amountCard}>
              <div className={styles.title}>How much do you need?</div>
              <div className={styles.amount}>
                <div className={styles.amtValue} onClick={() => setAmount(10)}>
                  $10
                </div>
                <div className={styles.amtValue} onClick={() => setAmount(20)}>
                  $20
                </div>
                <div className={styles.amtValue} onClick={() => setAmount(50)}>
                  $50
                </div>
                <div className={styles.amtValue} onClick={() => setAmount(100)}>
                  $100
                </div>
              </div>
              <div className={styles.amountInput}>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </div>
            </div>
            {error && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error}
              </div>
            )}
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleDeposit} disabled={addFundsLoading}>
              {addFundsLoading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Deposit"}
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
