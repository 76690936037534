import { createSlice } from "@reduxjs/toolkit";
import store, { StoreDispatch } from "../store";
import { SearchUserRequest, searchUser, getUserWallets, getUserCard } from "src/network/graphql/userService";
import { Users } from "../users/usersSlice";
// import { UserListRequest } from "src/network/graphql/userService";
import { SearchWalletRequest, getWalletCards, searchWallet } from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";
// import { walletListRequest } from "src/network/services/walletService";
import { Card } from "../cards/cardSlice";
import {
  SinglePayRequest,
  WalletTransferRequest,
  groupPay,
  groupPayRequest,
  internalWalletTransfer,
  singlePay
} from "src/network/graphql/paymentService";
import swal from "sweetalert2";
import {
  TransactionRequest,
  getAllTransaction,
  adjustTransactionRequest,
  adjustTransaction
} from "src/network/graphql/transactionService";
import { Transaction } from "../transactions/transactionSlice";
import { PARTICIPANT, SHARED, SUPPORTER } from "src/components/constant/constant";

const initialUsers: Users[] = [];
const initialWallets: Wallet[] = [];
const initialAllWallets: Wallet[] = [];
const initialCards: Card[] = [];
const initialSearchActivity: Transaction[] = [];

const newPaymentSlice = createSlice({
  name: "newPayment",
  initialState: {
    userLoading: false,
    walletLoading: false,
    walletAllLoading: false,
    userWalletloading: false,
    cardLoading: false,
    singlePayLoading: false,
    groupPayLoading: false,
    internalPayLoading: false,
    searchActivityLoading: false,
    transactionLoading: false,
    error: null,
    users: {
      list: initialUsers,
      total: 0
    },
    wallets: {
      list: initialWallets,
      total: 0
    },
    cards: {
      list: initialCards,
      total: 0
    },
    allWallets: {
      list: initialAllWallets,
      total: 0
    },
    userWallet: {
      list: initialAllWallets,
      total: 0
    },
    searchActivity: {
      list: initialSearchActivity,
      total: 0
    }
  },
  reducers: {
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    resetUsersAction: (state, action) => {
      state.users.list = action.payload.list;
      state.users.total = action.payload?.total;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = action.payload?.list ?? [];
      state.wallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = [];
      state.wallets.total = 0;
      state.error = action.payload;
    },
    resetwalletListAction: (state, action) => {
      state.wallets.list = action.payload.list;
      state.wallets.total = action.payload?.total;
    },
    fetchCardsStart: (state) => {
      state.cardLoading = true;
      state.error = null;
    },
    fetchCardsSuccess: (state, action) => {
      state.cardLoading = false;
      state.cards.list = action.payload?.list ?? [];
      state.cards.total = action.payload?.total || 0;
    },
    fetchCardsFail: (state, action) => {
      state.cardLoading = false;
      state.cards.list = [];
      state.cards.total = 0;
      state.error = action.payload;
    },
    resetCardListAction: (state, action) => {
      state.cards = action.payload ?? [];
    },
    singlePayStart: (state) => {
      state.singlePayLoading = true;
    },
    singlePaySuccess: (state) => {
      state.singlePayLoading = false;
      state.error = null;
    },
    singlePayFail: (state, action) => {
      state.singlePayLoading = false;
      state.error = action.payload;
    },
    internalPayStart: (state) => {
      state.internalPayLoading = true;
    },
    internalPaySuccess: (state) => {
      state.internalPayLoading = false;
      state.error = null;
    },
    internalPayFail: (state, action) => {
      state.internalPayLoading = false;
      state.error = action.payload;
    },
    fetchAllWalletsStart: (state) => {
      state.walletAllLoading = true;
    },
    fetchAllWalletsSuccess: (state, action) => {
      state.walletAllLoading = false;
      state.allWallets.list = action.payload?.list ?? [];
      state.allWallets.total = action.payload?.total ?? 0;
    },
    fetchAllWalletsFail: (state, action) => {
      state.walletAllLoading = false;
      state.allWallets.list = [];
      state.allWallets.total = 0;
      state.error = action.payload;
    },
    resetAllWalletAction: (state, action) => {
      state.allWallets.list = action.payload ?? [];
    },
    fetchUserWalletStart: (state) => {
      state.userWalletloading = true;
    },
    fetchUserWalletSuccess: (state, action) => {
      state.userWalletloading = false;
      state.userWallet.list = action.payload?.list ?? [];
      state.userWallet.total = action.payload?.total ?? 0;
    },
    fetchUserWalletFail: (state, action) => {
      state.userWalletloading = false;
      state.userWallet.list = [];
      state.userWallet.total = 0;
      state.error = action.payload;
    },
    groupPayStart: (state) => {
      state.groupPayLoading = true;
    },
    groupPaySuccess: (state) => {
      state.groupPayLoading = false;
    },
    groupPayFail: (state, action) => {
      state.groupPayLoading = false;
      state.error = action.payload;
    },
    searchActivityStart: (state) => {
      state.searchActivityLoading = true;
    },
    searchActivitySuccess: (state, action) => {
      state.searchActivityLoading = false;
      state.searchActivity = action.payload;
    },
    searchActivityFail: (state, action) => {
      state.searchActivityLoading = false;
      state.error = action.payload;
    },
    addTransactionStart: (state) => {
      state.transactionLoading = true;
      state.error = null;
    },
    addTransactionSuccess: (state) => {
      state.transactionLoading = false;
      state.error = null;
    },
    addTransactionFail: (state, action) => {
      state.transactionLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchCardsStart,
  fetchCardsSuccess,
  fetchCardsFail,
  singlePayStart,
  singlePaySuccess,
  singlePayFail,
  fetchAllWalletsStart,
  fetchAllWalletsSuccess,
  fetchAllWalletsFail,
  internalPayStart,
  internalPaySuccess,
  internalPayFail,
  groupPayStart,
  groupPaySuccess,
  groupPayFail,
  fetchUserWalletStart,
  fetchUserWalletSuccess,
  fetchUserWalletFail,
  searchActivityStart,
  searchActivitySuccess,
  searchActivityFail,
  addTransactionStart,
  addTransactionSuccess,
  addTransactionFail
} = newPaymentSlice.actions;

export const { resetwalletListAction, resetCardListAction, resetAllWalletAction, resetUsersAction } =
  newPaymentSlice.actions;

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        // user_filter: {
        //   is_active: data.,
        //   email: data.email,
        //   first_name: data.firstName,
        //   last_name: data.lastName,
        //   organisation_id: data.organisationId
        // },
        user_filter: data.user_filter,
        size: data.size,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing
        // .filter((user: any) => user.current_organisation_role === PARTICIPANT)
        .map((user: any) => ({
          id: user.display_id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.email,
          role: "not available",
          status: user.is_active,
          userId: user.user_id
        }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchWalletListAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await searchWallet({ walletFilter: { user_ids: [userId] } });

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        // status: wallet.wallet_status,
        walletType: wallet.wallet_type,
        name: wallet.name
        // participant: { role: "PARTICIPANT", fullName: wallet.description }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchCardListAction = (WalletId: string, userId?: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchCardsStart());
    try {
      const { auth } = store.getState();
      const response = await getWalletCards(WalletId);
      const structuredResponse = response?.data.get_wallet_cards
        ?.filter((card: any) => card?.card_type === SHARED && card?.card_status === "ACTIVE")
        ?.map((card: any) => ({
          id: card?.card_id,
          cardStatus: card?.card_status,
          cardNumber: card?.ppan,
          user: {
            id: card?.user_id,
            // eslint-disable-next-line no-nested-ternary
            fullName: card?.holder_name
              ? card?.holder_name
              : card?.user
                ? `${card?.user?.attributes.first_name} ${card?.user?.attributes.last_name}`
                : "---"
          }
        }));

      const role = auth?.userInfo?.role || "";
      const userIdToUse = [PARTICIPANT, SUPPORTER].includes(role) ? String(auth?.userInfo?.id) : String(userId);

      const userResponse = await getUserCard(
        userIdToUse,
        String(auth?.cognitoConfig?.currentOrganisation?.organisation_id)
      );

      const userStructuredResponse = userResponse?.data?.get_user_cards?.cards
        ?.filter((card: any) => card?.card_status === "ACTIVE")
        ?.map((card: any) => ({
          id: card?.card_id,
          cardStatus: card?.card_status,
          cardNumber: card?.ppan,
          user: {
            id: card?.user_id,
            // eslint-disable-next-line no-nested-ternary
            fullName: card?.holder_name
              ? card?.holder_name
              : card?.user
                ? `${card?.user?.attributes.first_name} ${card?.user?.attributes.last_name}`
                : "---"
          }
        }));

      const combinedResponses = [...structuredResponse, ...userStructuredResponse];
      const uniqueCardList = combinedResponses.reduce((acc, current) => {
        const x = acc.find((item: any) => item.id === current.id);

        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      dispatch(
        fetchCardsSuccess({
          list: uniqueCardList,
          total: response.data.get_wallet_cards.length
        })
      );
    } catch (error) {
      dispatch(fetchCardsFail(error));
    }
  };
};

export const singlePayAction = (data: SinglePayRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(singlePayStart());
    try {
      const response = await singlePay(data);

      dispatch(singlePaySuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Success! You're ready to pay",
        showConfirmButton: false,
        timer: 2500
      });
      if (cb) cb();
      return response;
    } catch (err) {
      dispatch(singlePayFail(err));
    }
  };
};

export const internalPayAction = (data: WalletTransferRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(internalPayStart());
    try {
      await internalWalletTransfer(data);

      swal.fire({
        position: "center",
        icon: "success",
        title: "Congratulations!  Transfer was completed successfully.",
        showConfirmButton: false,
        timer: 2500
      });
      if (cb) cb();
      dispatch(internalPaySuccess());
    } catch (err) {
      dispatch(internalPayFail(err));
    }
  };
};

export const fetchAllWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchAllWalletsStart());
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        name: wallet.name,
        participant: { role: "PARTICIPANT", fullName: wallet.description, email: wallet?.user?.email }
      }));

      dispatch(
        fetchAllWalletsSuccess({
          list: structuredResponse,
          total: response.data.search_wallet.total_size
        })
      );
    } catch (error) {
      dispatch(fetchAllWalletsFail(error));
    }
  };
};

export const groupPayAction = (data: groupPayRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(groupPayStart());
    try {
      const response = await groupPay(data);

      dispatch(groupPaySuccess());
      return response;
    } catch (err) {
      dispatch(groupPayFail(err));
    }
  };
};

export const fetchUserWalletAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserWalletStart());
    try {
      const response = await getUserWallets(userId);
      const spendingWallet = response.data.get_user_wallets.find((wallet: any) => wallet.wallet_type === "SPENDING");

      dispatch(
        fetchUserWalletSuccess({
          list: [
            {
              id: spendingWallet.wallet_id,
              displayId: spendingWallet.display_id,
              description: spendingWallet.description,
              balance: spendingWallet.balance,
              // status: wallet.wallet_status,
              walletType: spendingWallet.wallet_type,
              name: spendingWallet.name
            }
          ],
          total: 1
        })
      );
    } catch (error) {
      dispatch(fetchUserWalletFail(error));
    }
  };
};

export const searchActivityAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(searchActivityStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((activity: any) => ({
        id: activity.activity_id,
        data: JSON.parse(activity.data)
      }));

      dispatch(
        searchActivitySuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
    } catch (err) {
      dispatch(searchActivityFail(err));
    }
  };
};

export const addTransactionRecord = (data: adjustTransactionRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addTransactionStart());

    try {
      await adjustTransaction(data);

      dispatch(addTransactionSuccess());

      swal.fire({
        position: "center",
        icon: "success",
        title: "Success! Transaction Successfully Created",
        showConfirmButton: false,
        timer: 2500
      });

      if (cb) cb();
    } catch (error) {
      dispatch(addTransactionFail(error));
    }
  };
};

export default newPaymentSlice.reducer;
