import axios from "axios";
import { immediateToast } from "izitoast-react";
import config from "src/config/Config";

const api = axios.create({
  baseURL: config.PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json"
    // partner_id: config.PARTNER_ID
  }
});

api.interceptors.request.use(async (configuration) => {
  configuration.headers["x-api-key"] = config.X_API_KEY;
  return configuration;
});

api.interceptors.response.use((response) => {
  if (response.data.errors) {
    const errorMessage = response.data.errors[0].message;
    let messageObj: { message?: string } | undefined;

    try {
      messageObj = JSON.parse(errorMessage);
    } catch (error) {
      console.log(error);
      messageObj = undefined;
    }

    immediateToast("error", {
      message: messageObj?.message ?? errorMessage,
      timeout: 3000,
      position: "topCenter"
    });
    throw new Error(response.data.errors[0]);
  }
  return response.data;
});

const publicClient = (data: { query: string; variables?: any }) => {
  return api.post("/graphql", data);
};

export default publicClient;
