type ColorsByLetter = {
  [key: string]: string;
};

export const colorsByLetter: ColorsByLetter = {
  A: "#ADEFD1FF",
  B: "#00539CFF",
  C: "#2C5F2D",
  D: "#97BC62FF",
  E: "#89ABE3FF",
  F: "#A07855FF",
  G: "#333D79FF",
  H: "#F93822FF",
  I: "#00B1D2FF",
  J: "#9FC131FF",
  K: "#D7A9E3FF",
  L: "#8BBEE8FF",
  M: "#A8D5BAFF",
  N: "#FFA351FF",
  O: "#FFBE7BFF",
  P: "#EED971FF",
  Q: "#408EC6",
  R: "#7A2048",
  S: "#1E2761",
  T: "#28334AFF",
  U: "#E3B448",
  V: "#B85042",
  W: "#4F3466FF",
  X: "#A7BEAE",
  Y: "#6E8898FF",
  Z: "#9F6B99FF"
};

export const bgColorbyName = (firstName: string) => {
  const firstLetter = firstName?.charAt(0).toUpperCase();

  return colorsByLetter[firstLetter] || "#A2A2A1FF";
};

export const roleColors: { [key: string]: string } = {
  ADMIN: "#007bff",
  ORGANISATION_SUPER_ADMIN: "#28a745",
  ORGANISATION_OPERATIONS_MANAGER: "#ffc107",
  ORGANISATION_FINANCE_MANAGER: "#dc3545",
  GUARDIAN: "#17a589",
  PARTICIPANT: "#8e44ad",
  SUPPORTER: "#00bcd4",
  NOROLE: "#808080"
};

export const typeColors: { [key: string]: string } = {
  GENERAL: "#8e44ad",
  SPENDING: "#17a589",
  DIGITAL: "#007bff"
};

export const filterTagColor: { [key: string]: string } = {
  wallet: "#007bff",
  wallets: "#007bff",
  card: "#8e44ad",
  cards: "#8e44ad",
  user: "#28a745",
  users: "#28a745",
  date: "#dc3545",
  startDate: "#ffc107",
  endDate: "#dc3545",
  minAmount: "#17a589",
  maxAmount: "#8e44ad",
  amount: "#8e44ad",
  activityName: "#00bcd4",
  status: "#408EC6",
  type: "#9bff00",
  types: "#9bff00",
  category: "#ff6f61",
  standard: "#8e44ad"
};
