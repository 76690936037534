import styles from "./internalUser.module.scss";
import UserWelCard from "src/components/userWelCard/UserWelCard";
import InternalUserListCard from "src/components/InternalUserList/InternalUserListCard";

function InternalUsers() {
  return (
    <div className={styles.users}>
      <div className={styles.userWelCard}>
        <UserWelCard />
      </div>
      <div className={styles.userTableCard}>
        <InternalUserListCard />
      </div>
    </div>
  );
}

export default InternalUsers;
