import styles from "./OnBoardSuccessCard.form.module.scss";
//import HorizontalTimeline from "../../UI/horizontalTimeline/HorizontalTimeline";
// import Success from "../../../assets/images/AdminSuccess.png";

interface OnBoardingSuccessProps {
  onNext: () => void;
  onBack: () => void;
  heading: string;
  subHeading: string;
  secondaryButton?: boolean;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
  image: string;
}

export default function OnBoardingSuccess({
  onNext,
  onBack,
  heading,
  subHeading,
  secondaryButton = true,
  buttonText,
  image
}: OnBoardingSuccessProps) {
  return (
    <div>
      <div className={styles.formModal}>
        <div className={styles.modalContent}>
          <div className={styles.successModalTitle}>{heading}</div>
          <div className={styles.modalPara}>{subHeading}</div>
          <img alt="" src={image}></img>
          <div className={styles.successButtonsContainer}>
            {secondaryButton && (
              <button onClick={onBack} className={styles.skipSuccessButton}>
                {buttonText?.secondaryButton || "Skip for Now"}
              </button>
            )}
            <button onClick={onNext} className={styles.nextSuccessButton}>
              {buttonText?.primaryButton || "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
