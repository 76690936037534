import styles from "src/page/home/home.module.scss";
import { Button, MenuItem, Select, SelectChangeEvent, Skeleton } from "@mui/material";
import { dateTimeFormat, getDecimalNumber } from "src/utils/utils";
import { CardSetting, getUserCardAction, singlePayAction } from "src/page/home/homeSlice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/page/store";
import moment from "moment";

interface HomeWalletCardProp {
  value: number;
  activeCard: number;
  card: CardSetting;
  cardContainerOver?: string;
}

export default function HomeWalletCard({ value, activeCard, card, cardContainerOver }: HomeWalletCardProp) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const { wallets, walletDetails } = useAppSelector((state) => state.home);

  useEffect(() => {
    setSelectedWalletId(card?.wallet_id);
  }, [card?.wallet_id]);

  const handleOrgSelect = (event: SelectChangeEvent) => {
    setSelectedWalletId(event.target.value);
    const walletId = event.target.value;

    const data = {
      expireAt: moment().add(3, "hours").toISOString(),
      cardId: card?.card_id,
      walletId
    };

    dispatch(singlePayAction(data)).then(() => {
      dispatch(getUserCardAction());
    });
  };

  const walletDetail = walletDetails[card?.wallet_id];
  const walletList = wallets[card?.wallet_id]?.list || [];

  return (
    <div className={`${styles.cardContainer} ${cardContainerOver}`}>
      {value === activeCard ? (
        <div className={styles.card}>
          <div className={styles.indicator}>
            <div className={`${styles.greenCircle} ${card ? styles.greenactive : ""}`}></div>
            <div className={` ${styles.redCircle} ${!card ? styles.redactive : ""}`}></div>
          </div>
          {card ? (
            <>
              <div className={styles.cardContent}>
                <div className={styles.walletName}>Paying for {walletDetail?.wallet?.name}</div>
                <div className={styles.balance}>
                  ${walletDetail?.wallet?.balance ? getDecimalNumber(walletDetail?.wallet?.balance) : "0.00"}
                  Available
                </div>
                <div className={styles.balance}>
                  {walletDetail?.wallet?.participant?.firstName + " " + walletDetail?.wallet?.participant?.lastname}
                </div>
                <div className={styles.cardno}>Card ends in {card?.ppan?.slice(-4)}</div>
              </div>
              {card?.expires_at && (
                <div className={styles.caution}>
                  <span>
                    {`*Money Available On Card Until: `}
                    {dateTimeFormat(Number(card?.expires_at)).datetime}
                  </span>
                </div>
              )}
            </>
          ) : (
            <div className={styles.cardContent}>
              <div className={styles.balance}>No wallet selected</div>
              <div className={styles.cardno}>Please add a wallet to pay</div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.card}>
          <div className={styles.indicator}>
            <div className={`${styles.greenCircle}`}></div>
            <div className={` ${styles.redCircle}`}></div>
          </div>
          <div className={styles.cardContent}>
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "-webkit-fill-available" }} />
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "-webkit-fill-available" }} />
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "-webkit-fill-available" }} />
          </div>
          <div className={styles.caution}>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30vw" }} />
          </div>
        </div>
      )}

      <div className={styles.wallet}>
        {card ? (
          <>
            <span className={styles.label}>Select a different wallet</span>
            <Select
              className={styles.selectOption}
              value={selectedWalletId}
              onChange={handleOrgSelect}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: 50,
                minWidth: 150
              }}
            >
              {value === activeCard &&
                walletList?.map((option) => (
                  <MenuItem key={option.id} value={option.id.toString()}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </>
        ) : (
          <Button
            variant="contained"
            className={styles.btn}
            onClick={() => {
              navigate("/newPayment");
            }}
          >
            Select Wallet To Pay
          </Button>
        )}
      </div>
    </div>
  );
}
