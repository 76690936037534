import { useState, useEffect } from "react";
import styles from "./deposit.module.scss";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { Autocomplete, Button, ButtonGroup, InputLabel, MenuItem, Select, TextField, FormControl } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import { Users } from "../users/usersSlice";
import { Wallet } from "../wallets/walletSlice";
import {
  addFundsAction,
  fetchUserListAction,
  fetchWalletListAction,
  resetwalletListAction,
  revokeFundsAction,
  fetchWalletByIdAction
} from "./depositSlice";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import { PARTICIPANT, roleMapping } from "src/components/constant/constant";
import { useParams } from "react-router-dom";

export default function Deposit() {
  const dispatch = useAppDispatch();
  const { users, wallets, selectedWallet: fetchedWallet } = useAppSelector((state) => state.deposit);
  const [amount, setAmount] = useState<number>(0);
  const [selectedParticipant, setSelectedParticipant] = useState<Users | null>(null);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [actionType, setActionType] = useState<"Add" | "Remove">("Add");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [balance, setBalance] = useState<number>(0);
  const addFundsLoading = useAppSelector((state) => state.deposit.addFundsLoading);
  const { walletId } = useParams();

  useEffect(() => {
    if (!walletId) dispatch(fetchUserListAction({ size: 200, from: 0, user_filter: { role: PARTICIPANT } }));
  }, [dispatch]);

  useEffect(() => {
    if (walletId) {
      dispatch(fetchWalletByIdAction(walletId));
    }
  }, [walletId]);

  useEffect(() => {
    if (fetchedWallet) {
      setSelectedWallet(fetchedWallet);
      setBalance(fetchedWallet.balance);
      setSelectedOption("DEPOSIT");

      const participant: Users = {
        id: fetchedWallet.participant.display_id,
        initial: "",
        fullName: fetchedWallet.participant.fullName,
        firstName: fetchedWallet.participant.firstName,
        lastName: fetchedWallet.participant.lastname,
        dob: "",
        email: fetchedWallet.participant.email,
        gender: "",
        phone: fetchedWallet.participant.phone,
        line1: "",
        line2: "",
        city: "",
        state: "",
        postCode: "",
        Country: "",
        role: [
          {
            organisation_id: "",
            value: fetchedWallet.participant.role
          }
        ],
        currentOrganisationRole: fetchedWallet.participant.role,
        active: true,
        lastActive: "",
        userId: String(fetchedWallet.participant.id),
        createdAt: ""
      };
      setSelectedParticipant(participant);
    }
  }, [fetchedWallet]);

  useEffect(() => {
    if (!walletId) {
      setSelectedWallet(null);
      setAmount(0);
      if (selectedParticipant) {
        dispatch(fetchWalletListAction(String(selectedParticipant?.userId)));
      } else {
        dispatch(resetwalletListAction({ list: [] }));
      }
    }
  }, [selectedParticipant]);

  const resetFormFields = () => {
    // setSelectedParticipant(null);
    // setSelectedWallet(null);
    setAmount(0);
    setSelectedOption("");
    dispatch(fetchWalletListAction(String(selectedParticipant?.userId)));
  };

  useEffect(() => {
    if (selectedWallet) {
      const walletBalnce = wallets?.list.find((wallet: Wallet) => wallet?.id === selectedWallet?.id);

      if (walletBalnce) {
        setBalance(walletBalnce?.balance);
      }
    }
  }, [selectedWallet, wallets?.list]);

  const handleRevoke = () => {
    dispatch(
      revokeFundsAction(
        {
          wallet_id: String(selectedWallet?.id) ?? "",
          amount: amount * -1,
          remark: selectedOption
        },
        () => {
          resetFormFields();
        }
      )
    );
  };

  const handleDeposit = () => {
    if (selectedOption === "DEPOSIT") {
      dispatch(
        addFundsAction(
          {
            wallet_id: String(selectedWallet?.id) ?? "",
            amount: amount,
            remark: selectedOption
          },
          () => {
            resetFormFields();
          }
        )
      );
    } else {
      dispatch(
        revokeFundsAction(
          {
            wallet_id: String(selectedWallet?.id) ?? "",
            amount: amount,
            remark: selectedOption
          },
          () => {
            resetFormFields();
          }
        )
      );
    }
  };

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };

  const addPaymentTypes = [
    {
      id: "DEPOSIT",
      title: "Deposit"
    },
    {
      id: "CREDIT",
      title: "Credit - Balance Adjust"
    },
    {
      id: "CREDIT_MISSED_SEGTTLE_TRANSACTION",
      title: "Credit missed settled transaction"
    },
    {
      id: "BONUS",
      title: "Bonus/Reward"
    },
    {
      id: "OTHER",
      title: "Settled transaction missed"
    }
  ];

  const removePaymentTypes = [
    {
      id: "WITHDRAWAL",
      title: "Withdrawal"
    },
    {
      id: "DEBIT",
      title: "Debit balance adjust"
    },
    {
      id: "DEBIT_MISSED_SETTLEMENT_TRANSACTION",
      title: "Debit Missed settlement transaction"
    }
  ];

  return (
    <div className={styles.depositwrappper} aria-label="Deposit Money">
      {addFundsLoading && <LoadingModal />}
      <div className={styles.WelCard}>
        <TransactionWelCard heading={"Deposit Money"} subHeading={"Deposit and Revoke money from your wallet"} />
      </div>
      <div className={styles.depositContainer}>
        <div className={styles.container}>
          <ButtonGroup variant="outlined" aria-label="outlined button group" className={styles.actionTypeBtnGroup}>
            <Button
              style={{
                backgroundColor: actionType === "Add" ? "#1e293b" : "#fff",
                border: "1px solid #1e293b",
                color: actionType === "Add" ? "#FFFFFF" : "#1e293b"
              }}
              onClick={() => setActionType("Add")}
              aria-label="Add Funds"
            >
              Add Funds
            </Button>
            <Button
              style={{
                backgroundColor: actionType === "Remove" ? "#1e293b" : "#fff",
                border: "1px solid #1e293b",
                color: actionType === "Remove" ? "#FFFFFF" : "#1e293b"
              }}
              onClick={() => setActionType("Remove")}
              aria-label="Remove Funds"
            >
              Remove Funds
            </Button>
          </ButtonGroup>

          <Autocomplete
            options={users?.list}
            getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
            value={selectedParticipant}
            onChange={(event, newValue) => setSelectedParticipant(newValue)}
            disabled={Boolean(walletId)}
            renderInput={(params) => <TextField {...params} label="Select Participant" />}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <span>{`${option.firstName} ${option.lastName}`}</span>
                <span style={{ color: "#64748B" }}>{option.email}</span>
              </li>
            )}
          />

          {selectedParticipant ? (
            <Autocomplete
              options={wallets?.list}
              getOptionLabel={(option) => option.name || ""}
              value={selectedWallet}
              onChange={(event, newValue) => {
                setSelectedWallet(newValue);
                setBalance(newValue?.balance || 0);
              }}
              renderInput={(params) => <TextField {...params} label="Select Wallet" />}
              disabled={Boolean(walletId)}
            />
          ) : (
            <div></div>
          )}

          {selectedWallet && selectedParticipant && (
            <FormControl fullWidth>
              <InputLabel id="payment-type-label">Payment Reason</InputLabel>
              {actionType === "Add" ? (
                <Select
                  labelId="payment-reason-label"
                  id="payment-reason"
                  value={selectedOption}
                  label="Payment Reason"
                  onChange={handleOptionChange}
                  disabled={Boolean(walletId)}
                  inputProps={{ "aria-label": "Payment Reason" }}
                >
                  {addPaymentTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.title}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  labelId="payment-reason-label"
                  id="payment-reason"
                  value={selectedOption}
                  label="Payment Reason"
                  onChange={handleOptionChange}
                >
                  {removePaymentTypes.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          )}

          <div className={styles.amountCard}>
            <div className={styles.title}>How much do you need?</div>
            <div className={styles.amount}>
              <div className={styles.amtValue} onClick={() => setAmount(10)}>
                $10
              </div>
              <div className={styles.amtValue} onClick={() => setAmount(20)}>
                $20
              </div>
              <div className={styles.amtValue} onClick={() => setAmount(50)}>
                $50
              </div>
              <div className={styles.amtValue} onClick={() => setAmount(100)}>
                $100
              </div>
            </div>
            <div className={styles.amountInput}>
              <input
                type="number"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </div>
          </div>
          {selectedParticipant && selectedWallet && selectedOption && amount !== 0 ? (
            <div className={styles.btn}>
              {actionType === "Remove" ? (
                <Button className={styles.revokeBtn} variant="contained" onClick={handleRevoke}>
                  Revoke
                </Button>
              ) : (
                <Button className={styles.depositBtn} variant="contained" onClick={handleDeposit}>
                  Deposit
                </Button>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {selectedParticipant && (
          <div className={styles.detailContainer}>
            <div className={styles.userInfo}>
              <h4>Participant information</h4>
              <div className={styles.userdata}>
                <div className={styles.label}>
                  <label>Name : </label>
                  <div className={styles.value}>
                    {selectedParticipant?.firstName + " " + selectedParticipant?.lastName}
                  </div>
                </div>
                <div className={styles.label}>
                  <label>Email : </label>
                  <div className={styles.value}>{selectedParticipant?.email}</div>
                </div>
                <div className={styles.label}>
                  <label>Role : </label>
                  <div className={styles.value}>{roleMapping[selectedParticipant?.currentOrganisationRole]}</div>
                </div>
                <div className={styles.label}>
                  <label>Phone : </label>
                  <div className={styles.value}>{selectedParticipant?.phone}</div>
                </div>
                <div className={styles.label}>
                  <label>User Display Id : </label>
                  <div className={styles.value}>{selectedParticipant?.id}</div>
                </div>
                <div className={styles.label}>
                  <label>User Id : </label>
                  <div className={styles.value}>{selectedParticipant?.userId}</div>
                </div>
              </div>
            </div>
            {selectedWallet && (
              <div className={styles.walletInfo}>
                <h4>Wallet information</h4>
                <div className={styles.walletdata}>
                  <div className={styles.label}>
                    <label>Name : </label>
                    <div className={styles.value}>{selectedWallet?.name}</div>
                  </div>
                  <div className={styles.label}>
                    <label>Email : </label>
                    <div className={styles.value}>{selectedWallet?.participant?.email}</div>
                  </div>
                  <div className={styles.label}>
                    <label>Description : </label>
                    <div className={styles.value}>{selectedWallet?.description}</div>
                  </div>
                  <div className={styles.label}>
                    <label>Balance : </label>
                    <div className={styles.value}>${balance}</div>
                  </div>
                  <div className={styles.label}>
                    <label>Wallet Display Id : </label>
                    <div className={styles.value}>{selectedWallet?.displayId}</div>
                  </div>
                  <div className={styles.label}>
                    <label>Wallet Id : </label>
                    <div className={styles.value}>{selectedWallet?.id}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
