import { Transaction } from "src/page/transactions/transactionSlice";
import styles from "./depositCard.module.scss";
import Table, { ColumnType } from "rc-table";
import getColumns from "./column";

interface depositCardProp {
  walletId?: string;
  userId?: string;
  recentTransOver?: string;
  depositList: Transaction[];
}

export default function DepositCard({ walletId, userId, depositList, recentTransOver }: depositCardProp) {
  const columns: ColumnType<Transaction>[] = getColumns(walletId, userId);
  return (
    <>
      <div className={`${styles.recentTrans} ${recentTransOver}`}>
        <div className={styles.recentTransIn}>
          <div className={styles.recentTransHead}>
            <div className={styles.recentTransHeadIn}>
              <div className={styles.recentTransHeadD}>
                <h3>Deposits</h3>
                <p>These are the details of the deposit made</p>
              </div>
            </div>
          </div>
          <div className={styles.recentTranTable}>
            {depositList && depositList.length > 0 ? (
              <Table columns={columns} data={depositList} />
            ) : (
              <div className={styles.noData}>
                <p>No Data Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
