import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./userInfoModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { UserDetail, fetchUserDetailAction, updateUserDetailAction } from "src/page/userDetail/userDetailSlice";
// import { reverseRoleMapping, roleMapping } from "src/components/constant/constant";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
// import { debounce } from "lodash";
import { Gender, Initial, kycStatusType } from "src/types/commonTypes";
import { countryCodeData } from "src/types/countryCode";
import { phone } from "phone";
import { checkPermission } from "src/utils/utils";
import { CAN_UPDATE_EMAIL } from "src/components/constant/constant";
interface AddUserModalProps {
  handleClose: () => void;
  userDetail: UserDetail;
  onAddUser?: (_addUser: UserDetail, _close: boolean) => void;
}
export default function UserInfoEdiModal({ handleClose, userDetail }: AddUserModalProps) {
  // const { config } = useAppSelector((Rstate) => Rstate.auth);
  const dispatch = useAppDispatch();
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const [firstName, setFirstName] = useState(userDetail?.user?.firstName);
  const [lastName, setLastName] = useState(userDetail?.user?.lastName);
  const [countryCode, setCountryCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  // const [mobile, setMobile] = useState(userDetail?.user?.phoneNumber);
  const [email, setEmail] = useState(userDetail?.user?.email);
  const [gender, setGender] = useState<Gender>(userDetail?.user?.gender !== null ? userDetail?.user?.gender : "male");
  const [dob, setDob] = useState(userDetail?.user?.dob);
  const [kyc, setKyc] = useState<kycStatusType>(
    userDetail?.user?.kycStatus !== null ? userDetail?.user?.kycStatus : "Fail"
  );
  const initialFromUserDetail = userDetail?.user?.initial !== null ? userDetail?.user?.initial.replace(".", "") : "";
  const [initial, setInitial] = useState<Initial>((initialFromUserDetail as Initial) || "Mr");
  const [line1, setLine1] = useState(userDetail?.user?.line1);
  const [line2, setLine2] = useState(userDetail?.user?.line2);
  const [city, setCity] = useState(userDetail?.user?.city);
  const [state, setState] = useState(userDetail?.user?.state);
  const [postCode, setPostCode] = useState(userDetail?.user?.zipcode);
  // const [role, setRole] = useState(userDetail?.user?.role);
  const [error, setError] = useState({ firstName: "", lastName: "", dob: "", email: "" });
  const handleCancelClose = () => {
    handleClose();
  };

  // useEffect(() => {
  //   const phoneParts = userDetail?.user?.phoneNumber.split("-") || ["", ""];

  //   setCountryCode(phoneParts[0]);
  //   setPhoneNumber(phoneParts[1]);
  // }, [userDetail?.user?.phoneNumber]);

  useEffect(() => {
    const phoneData = phone(userDetail?.user?.phoneNumber);

    setCountryCode(phoneData?.countryCode || "");
    setPhoneNumber(phoneData?.phoneNumber?.replace(phoneData.countryCode || "", "") || "");
  }, [userDetail?.user?.phoneNumber]);

  const handleUpdateUser = () => {
    if (!firstName.trim() || !lastName.trim() || !dob.trim() || !email.trim()) {
      setError({
        firstName: firstName.trim() ? "" : "First name is required",
        lastName: lastName.trim() ? "" : "Last name is required",
        // role: role.trim() ? "" : "Role is Required",
        dob: dob.trim() ? "" : "Date of birth is required",
        email: email.trim() ? "" : "Email is required"
      });
      return;
    }

    const userData = {
      organisationId: cognitoConfig?.currentOrganisation?.organisation_id,
      id: userDetail.user.id,
      initial,
      firstName,
      lastName,
      phoneNumber: countryCode + phoneNumber,
      email,
      userName: userDetail.user.userName,
      gender,
      dob,
      state,
      city,
      line1,
      line2,
      zipcode: postCode,
      country: userDetail.user.country,
      // role: reverseRoleMapping[role],
      // guardianId: user?.id,
      profileImage: userDetail.user.profileImage,
      kycStatus: kyc
    };

    dispatch(
      updateUserDetailAction(userData, () => {
        dispatch(fetchUserDetailAction(userDetail.user.id.toString()));
        handleClose();
      })
    );
  };

  // const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
  //   setRole(value || "Participant");
  // };

  const handleKycChange = (e: SelectChangeEvent<kycStatusType>) => {
    const value = e.target.value;

    setKyc(value as kycStatusType);
  };

  const handleInitialChange = (e: SelectChangeEvent<Initial>) => {
    const value = e.target.value;

    setInitial(value as Initial);
  };

  const handlegenderChange = (e: SelectChangeEvent<Gender>) => {
    const value = e.target.value;

    setGender(value as Gender);
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>User Information</h5>
            <button onClick={handleCancelClose} aria-label="Close button">
              <CloseIcon onClick={handleCancelClose} />
            </button>
          </div>

          <div className={styles.name}>
            <div className={styles.initial}>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={initial}
                  onChange={handleInitialChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 49
                  }}
                >
                  <MenuItem value="Mr">Mr.</MenuItem>
                  <MenuItem value="Mrs">Mrs.</MenuItem>
                  <MenuItem value="Miss">Miss.</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.nameContainer}>
              <div className={styles.Fname}>
                <label htmlFor="firstname">First Name</label>
                <input
                  id="firstname"
                  type="text"
                  placeholder="Enter Text Here"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {error.firstName && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.firstName}
                  </div>
                )}
              </div>
              <div className={styles.Lname}>
                <label htmlFor="lastname">Last Name</label>
                <input
                  id="lastname"
                  type="text"
                  placeholder="Enter Text Here"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {error.lastName && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.lastName}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.genderNdob}>
            <div className={styles.gender}>
              <label htmlFor="gender">Gender:</label>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  id="gender"
                  aria-label="Select Gender"
                  value={gender}
                  onChange={handlegenderChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label", name: "gender" }}
                  sx={{
                    height: 49
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="nonbinary">Non Binary</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.dob}>
              <label htmlFor="dob">DOB:</label>
              <input
                id="dob"
                type="date"
                value={dob}
                name="dob"
                onChange={(e) => {
                  setDob(e.target.value);
                }}
              />
              {error.dob && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.dob}
                </div>
              )}
            </div>
            {userDetail?.canEditKycStatus && (
              <div className={styles.kyc}>
                <label htmlFor="kyc">KYC</label>
                <FormControl>
                  <Select
                    id="kyc"
                    aria-label="Select KYC Status"
                    value={kyc}
                    onChange={handleKycChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 50
                    }}
                  >
                    <MenuItem value="pass">Pass</MenuItem>
                    <MenuItem value="fail">Fail</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>

          <div className={styles.lineII}>
            <div className={styles.mobile}>
              <label htmlFor="mobile">Mobile</label>
              <div className={styles.mobileSelect}>
                <Select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  displayEmpty
                  className={styles.countryCodeSelect}
                >
                  {countryCodeData.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.code}
                    </MenuItem>
                  ))}
                </Select>
                <input
                  id="mobile"
                  type="tel"
                  className={styles.phoneNumberInput}
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </div>
            </div>
            <div className={styles.email}>
              <label htmlFor="email">User Email</label>
              <input
                id="email"
                disabled={!checkPermission(CAN_UPDATE_EMAIL)}
                type="email"
                placeholder="example@gmail.com"
                value={email}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{
                  background: !checkPermission(CAN_UPDATE_EMAIL) ? "#ced4da" : ""
                }}
              />
              {error.email && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.email}
                </div>
              )}
            </div>
          </div>
          <div className={styles.kycrow}></div>
          <div className={styles.lineIII}>
            <div className={styles.addressline1}>
              <label htmlFor="address-line1">Line 1</label>
              <input
                id="address-line1"
                type="text"
                placeholder="Enter Text Here"
                value={line1}
                onChange={(e) => setLine1(e.target.value)}
              />
            </div>
            <div className={styles.addressline2}>
              <label htmlFor="address-line2">Line 2</label>
              <input
                id="address-line2"
                type="text"
                placeholder="Enter Text Here"
                value={line2}
                onChange={(e) => setLine2(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.lineIV}>
            <div className={styles.city}>
              <label htmlFor="city">City</label>
              <input
                id="city"
                type="text"
                placeholder="Enter Text Here"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className={styles.state}>
              <label htmlFor="state">State</label>
              <input
                id="state"
                type="text"
                placeholder="Enter Text Here"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className={styles.postCode}>
              <label htmlFor="postcode">PostCode</label>
              <input
                id="postcode"
                type="number"
                placeholder="Enter Text Here"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.sendBtn} variant="contained" onClick={handleUpdateUser} aria-label="Submit">
              Update
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
