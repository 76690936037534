import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  SubsListRequest,
  SubsRequest,
  connectOrganisationToSubscription,
  createSubscription,
  getSubscription,
  getSubscriptionOrganisations,
  listSubscription,
  removeOrganisationFromSubscription
} from "src/network/graphql/subscriptionServices";
import { immediateToast } from "izitoast-react";
import { initialOrgList } from "../organisation/organisationSlice";
import { OrganisationFilterType, organisationFilter } from "src/network/graphql/organisationService";
import { SubsList, initialSubsDetail } from "../plans/plansSlice";

const initialSubsList: SubsList[] = [];

export type SubOrg = {
  organisationId: string;
  organisationName: string;
};

const initialSubOrgs: SubOrg[] = [];

const childrenPlansSlice = createSlice({
  name: "files",
  initialState: {
    loading: false,
    createSubLoading: false,
    detailLoading: false,
    subOrgsLoading: false,
    changeSubOrgsLoading: false,
    orgListLoading: false,
    error: null,
    recentCreatedPlanSubId: "",
    detail: initialSubsDetail,
    subscriptionList: {
      list: initialSubsList,
      size: 0
    },
    subOrgs: initialSubOrgs,
    orgLists: {
      list: initialOrgList,
      total: 0
    }
  },
  reducers: {
    fetchListSubscriptionStart: (state) => {
      state.loading = true;
    },
    fetchListSubscriptionSuccess: (state, action) => {
      state.loading = false;
      state.subscriptionList = action.payload;
    },
    fetchListSubscriptionFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createSubscriptionStart: (state) => {
      state.createSubLoading = true;
    },
    createSubscriptionSuccess: (state) => {
      state.createSubLoading = false;
    },
    createSubscriptionFail: (state, action) => {
      state.createSubLoading = false;
      state.error = action.payload;
    },
    setRecentCreatedPlanSubId: (state, action) => {
      state.recentCreatedPlanSubId = action.payload;
    },
    getSubscriptionStart: (state) => {
      state.detailLoading = true;
    },
    getSubscriptionSuccess: (state, action) => {
      state.detailLoading = false;
      state.detail = action.payload;
    },
    getSubscriptionFail: (state, action) => {
      state.detailLoading = false;
      state.detail = initialSubsDetail;
      state.error = action.payload;
    },
    fetchSubscriptionOrganisationStart: (state) => {
      state.subOrgsLoading = true;
    },
    fetchSubscriptionOrganisationSuccess: (state, action) => {
      state.subOrgsLoading = false;
      state.subOrgs = action.payload;
    },
    fetchSubscriptionOrganisationFail: (state, action) => {
      state.subOrgsLoading = false;
      state.subOrgs = initialSubOrgs;
      state.error = action.payload;
    },
    connectOrganisationToSubscriptionStart: (state) => {
      state.changeSubOrgsLoading = true;
    },
    connectOrganisationToSubscriptionSuccess: (state) => {
      state.changeSubOrgsLoading = false;
    },
    connectOrganisationToSubscriptionFail: (state, action) => {
      state.changeSubOrgsLoading = false;
      state.error = action.payload;
    },
    removeOrganisationToSubscriptionStart: (state) => {
      state.changeSubOrgsLoading = true;
    },
    removeOrganisationToSubscriptionSuccess: (state) => {
      state.changeSubOrgsLoading = false;
    },
    removeOrganisationToSubscriptionFail: (state, action) => {
      state.changeSubOrgsLoading = false;
      state.error = action.payload;
    },
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    removeOrgList: (state, action) => {
      state.orgLists.list = action.payload;
    }
  }
});

const {
  fetchListSubscriptionStart,
  fetchListSubscriptionSuccess,
  fetchListSubscriptionFail,
  createSubscriptionStart,
  createSubscriptionSuccess,
  createSubscriptionFail,
  setRecentCreatedPlanSubId,
  getSubscriptionStart,
  getSubscriptionSuccess,
  getSubscriptionFail,
  fetchSubscriptionOrganisationStart,
  fetchSubscriptionOrganisationSuccess,
  fetchSubscriptionOrganisationFail,
  connectOrganisationToSubscriptionStart,
  connectOrganisationToSubscriptionFail,
  connectOrganisationToSubscriptionSuccess,
  removeOrganisationToSubscriptionStart,
  removeOrganisationToSubscriptionFail,
  removeOrganisationToSubscriptionSuccess,
  fetchOrgListStart,
  fetchOrgListSuccess,
  fetchOrgListFail
} = childrenPlansSlice.actions;

export const { removeOrgList } = childrenPlansSlice.actions;

export const getSubscriptionAction = (id: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getSubscriptionStart());
    try {
      const response = await getSubscription(id);
      const structuredResponse = response.data.get_subscription;

      dispatch(
        getSubscriptionSuccess({
          subsId: structuredResponse.subscription_id,
          title: structuredResponse.title,
          subTitle: structuredResponse.subtitle,
          description: structuredResponse.description,
          date: structuredResponse.created_at,
          orgType: structuredResponse.organisation_type,
          status: structuredResponse.is_active
        })
      );
    } catch (error) {
      dispatch(getSubscriptionFail(error));
    }
  };
};

export const fetchListSubscription = (data: SubsListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchListSubscriptionStart());
    try {
      const response = await listSubscription(data);
      const structuredResponse = response.data.search_subscriptions.items?.map((data: any) => ({
        subsId: data.subscription_id,
        title: data.title,
        subTitle: data.subtitle,
        description: data.description,
        date: data.created_at,
        orgType: data.organisation_type,
        status: data.is_active
      }));

      dispatch(
        fetchListSubscriptionSuccess({
          list: structuredResponse,
          size: response.data.search_subscriptions.total_size
        })
      );
    } catch (error) {
      dispatch(fetchListSubscriptionFail(error));
    }
  };
};

export const createSubscriptionAction = (data: SubsRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(createSubscriptionStart());
    try {
      const response = await createSubscription(data);
      dispatch(setRecentCreatedPlanSubId(response.data.create_subscription.subscription_id));
      immediateToast("success", {
        message: "Subscription Plan Successfully Created.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(createSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(createSubscriptionFail(error));
    }
  };
};

export const fetchSubscriptionOrganisations = (id: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchSubscriptionOrganisationStart());
    try {
      const response = await getSubscriptionOrganisations(id);
      const structuredResponse = response.data.get_subscription_organisations?.map((data: any) => ({
        organisationId: data.organisation_id,
        organisationName: data.name
      }));

      dispatch(fetchSubscriptionOrganisationSuccess(structuredResponse));
    } catch (error) {
      dispatch(fetchSubscriptionOrganisationFail(error));
    }
  };
};

export const connectOrganisationToSubscriptionAction = (
  organisationId: string,
  subscriptionId: string,
  cb?: () => void
) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(connectOrganisationToSubscriptionStart());
    try {
      await connectOrganisationToSubscription(organisationId, subscriptionId);
      immediateToast("success", {
        message: "Organisation Successfully Connected to Subscription Plan.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(connectOrganisationToSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(connectOrganisationToSubscriptionFail(error));
    }
  };
};

export const removeOrganisationFromSubscriptionAction = (
  organisationId: string,
  subscriptionId: string,
  cb?: () => void
) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(removeOrganisationToSubscriptionStart());
    try {
      await removeOrganisationFromSubscription(organisationId, subscriptionId);
      immediateToast("success", {
        message: "Organisation Successfully Removed to Subscription Plan.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(removeOrganisationToSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(removeOrganisationToSubscriptionFail(error));
    }
  };
};

export const fetchOrgListAction = (data: OrganisationFilterType) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchOrgListStart());
    try {
      const response = await organisationFilter(data);

      const structuredResponse = response.data.search_organisation.organisation_listing.map((org: any) => ({
        organisationId: org.organisation_id,
        id: org.display_id,
        active: org.is_active,
        name: org.name,
        createdAt: org.created_at,
        referenceName: org.contact.name,
        phone: org.contact.phone
      }));

      dispatch(
        fetchOrgListSuccess({
          list: structuredResponse,
          total: response.data.search_organisation.total_size
        })
      );
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};

export default childrenPlansSlice.reducer;
