import styles from "./recentActivity.module.scss";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useRef } from "react";
import Button from "@mui/material/Button";
import { Transaction } from "src/page/transactions/transactionSlice";
import { dateTimeFormat, getDecimalNumber, processFileName, uploadFileToS3 } from "src/utils/utils";
import config from "src/config/Config";
// import { immediateToast } from "izitoast-react";

interface activityprop {
  card: Transaction;
}

export default function RecentActivity({ card }: activityprop) {
  const hiddenAddReceipt = useRef<HTMLInputElement>(null);
  const hiddenAddPhoto = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (file: File, type: "RECEIPT" | "PHOTO") => {
    const data = {
      file: file,
      fileName: processFileName(file.name, card?.id),
      S3Bucket: config?.S3_ASSET_BUCKET,
      MetaData: {
        object_id: card?.id,
        object_type: "TRANSACTION",
        asset_type: type
      }
    };
    await uploadFileToS3(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.head}>
          <span
            style={{
              color: card?.data?.amount < 0 ? "red" : "green"
            }}
          >
            {card?.data?.amount < 0
              ? `-$${getDecimalNumber(card.data.amount)}`
              : `$${getDecimalNumber(card.data.amount)}`}
          </span>
          {` Paid ${
            // eslint-disable-next-line no-nested-ternary
            card?.data?.transaction_user
              ? "by " +
                card?.data?.transaction_user?.attributes?.first_name +
                " " +
                card?.data?.transaction_user?.attributes?.last_name
              : card?.data?.user
                ? "by " + card?.data?.user?.attributes?.first_name + " " + card?.data?.user?.attributes?.last_name
                : ""
          } ${card?.data?.wallet ? " from " + card?.data?.wallet?.name + " wallet" : ""} `}
        </div>
        <div className={styles.bottom}>
          <div
            className={styles.status}
            style={{
              color: card?.activityStatus === "APPROVED" ? "green" : "red"
            }}
          >
            {card?.activityStatus}
          </div>
          <div className={styles.date}>{dateTimeFormat(card?.endDate)?.datetime}</div>
        </div>
      </div>
      <div className={styles.uploadphoto}>
        <Button
          variant="contained"
          className={styles.addReceipt}
          onClick={() => {
            if (hiddenAddReceipt.current) {
              hiddenAddReceipt.current.click();
            }
          }}
        >
          <div className={styles.logo}>
            <TextSnippetOutlinedIcon />
          </div>
          <div className={styles.text}>Add Receipt</div>
        </Button>
        <input
          type="file"
          accept="image/*,application/pdf"
          onChange={(event) => {
            const file = event.target.files?.[0];

            if (file) {
              handleFileUpload(file, "RECEIPT");
            }
          }}
          ref={hiddenAddReceipt}
          style={{ display: "none" }}
        />

        <Button
          variant="contained"
          className={styles.addPhoto}
          onClick={() => {
            if (hiddenAddPhoto.current) {
              hiddenAddPhoto.current.click();
            }
          }}
        >
          <div className={styles.logo}>
            <CameraAltOutlinedIcon />
          </div>
          <div className={styles.text}>Add Photo</div>
        </Button>
        <input
          type="file"
          accept="image/*,application/pdf"
          onChange={(event) => {
            const file = event.target.files?.[0];

            if (file) {
              handleFileUpload(file, "PHOTO");
            }
          }}
          ref={hiddenAddPhoto}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}
