import { Link } from "react-router-dom";
import styles from "./depositCard.module.scss";
import { Transaction } from "src/page/transactions/transactionSlice";
import { dateTimeFormat, getDecimalNumber, getTransactionValue } from "src/utils/utils";
import { ColumnType } from "rc-table";

const getColumns = (walletId?: string, userId?: string): ColumnType<Transaction>[] => {
  const baseColumns: ColumnType<Transaction>[] = [
    {
      title: "Date / Time",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (endDate: string) => {
        const dateValue = endDate;

        if (dateValue === undefined || null) {
          return (
            <>
              <div>{"No date available"}</div>
              <div>{"No time available"}</div>
            </>
          );
        }

        return (
          <>
            <div>{dateTimeFormat(dateValue)?.date}</div>
            <div>{dateTimeFormat(dateValue)?.time}</div>
          </>
        );
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number, data: Transaction) => (
        <div className={styles.tableAmount} style={{ color: getTransactionValue(data)?.amountColor }}>
          {getTransactionValue(data)?.amount}
        </div>
      )
    }
  ];

  const userColumn = {
    title: "User",
    dataIndex: "user",
    key: "user",
    render: (wallet: string, data: Transaction) => (
      <div className={styles.tableCategory}>
        {data?.data?.user?.user_id ? (
          <Link to={`/users/${data?.data?.user?.user_id}`}>
            {data?.data?.user
              ? `${data?.data?.user?.attributes?.first_name} ${data?.data?.user?.attributes?.last_name}`
              : ""}
          </Link>
        ) : (
          "---"
        )}
      </div>
    )
  };

  const walletColumn = [
    {
      title: "Wallet",
      dataIndex: "wallet",
      key: "wallet",
      render: (wallet: string, data: Transaction) => (
        <div className={styles.tableCategory}>
          {data?.data?.wallet_id ? (
            <Link to={`/wallets/${data?.data?.wallet_id}`}>
              {data?.data?.wallet ? data?.data?.wallet?.name : data?.data?.wallet_id}
            </Link>
          ) : (
            "---"
          )}
        </div>
      )
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance: number, data: Transaction) => {
        const cutOffDate = "2024-03-13T00:00:00Z";

        const renderBalance = () => {
          if (data?.endDate > cutOffDate) {
            if (data?.data?.wallet?.balance) {
              if (data?.data?.wallet?.balance > 0) {
                return "$" + getDecimalNumber(data?.data?.wallet?.balance);
              } else {
                return "-$" + getDecimalNumber(Math.abs(data?.data?.wallet?.balance));
              }
            } else {
              return "$0.00";
            }
          } else {
            return "---";
          }
        };

        return (
          <div
            className={styles.tableBalance}
            style={{ color: data?.endDate > cutOffDate && data?.data?.wallet?.balance > 0 ? "green" : "red" }}
          >
            {renderBalance()}
          </div>
        );
      }
    }
  ];

  if (walletId) {
    return [...baseColumns, userColumn] as ColumnType<Transaction>[];
  } else if (userId) {
    return [...baseColumns, ...walletColumn] as ColumnType<Transaction>[];
  } else {
    return [...baseColumns, userColumn, ...walletColumn] as ColumnType<Transaction>[];
  }
};

export default getColumns;
