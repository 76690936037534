import TablePagination from "@mui/material/TablePagination";
import styles from "./bulkUpload.module.scss";
import Button from "@mui/material/Button";
import Table from "rc-table";
import columns from "./bulkUploadConfig";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import { useSearchParams } from "react-router-dom";
import { BULKUPLOAD_LIMIT, BULKUPLOAD_PAGE } from "src/components/constant/queryConstants";
import { fetchBulkUploadAction } from "./bulkUploadSlice";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import Modalui from "src/components/UI/modal/Modalui";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { setBulkUploadFilter } from "../filtersSlice";
const BulkUploadModal = lazy(() => import("src/components/bulkUploadModal/BulkUploadModal"));

export default function BulkUpload() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);
  const { bulkUploads, loading } = useAppSelector((state) => state.bulkOrder);
  const { bulkUpload: bulkUploadFilters } = useAppSelector((state) => state.filters);
  const { fileType } = useAppSelector((state) => state.auth);
  const limit = searchParams.get(BULKUPLOAD_LIMIT) ?? "10";
  const page = searchParams.get(BULKUPLOAD_PAGE) ?? "0";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(BULKUPLOAD_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(BULKUPLOAD_LIMIT, event.target.value as string);
    searchParams.set(BULKUPLOAD_PAGE, "0");
    setSearchParams(searchParams);
  };

  const fetchBulkUploadsData = useCallback(() => {
    const filters: any = {};
    if (bulkUploadFilters?.fileType) {
      filters.fileType = bulkUploadFilters?.fileType;
    }
    const data = {
      size: Number(limit),
      from: Number(limit) * Number(page),
      fileTypes: filters.fileType
    };

    dispatch(fetchBulkUploadAction(data));
  }, [page, limit, dispatch, bulkUploadFilters]);

  useEffect(() => {
    fetchBulkUploadsData();
  }, [fetchBulkUploadsData]);

  return (
    <>
      <Modalui open={open} handleClose={() => setOpen(false)} modaluiOver={styles.filterModalOver}>
        <Suspense fallback={<CircularLoader />}>
          <BulkUploadModal handleClose={() => setOpen(false)} cb={fetchBulkUploadsData} />
        </Suspense>
      </Modalui>
      <div className={styles.wrapper}>
        <div className={styles.WelCard}>
          <TransactionWelCard heading={"View Your Bulk Upload"} subHeading={"Add & View all your bulk uploads"} />
        </div>
        <div className={styles.list}>
          <div className={styles.listHead}>
            <div className={styles.listHeadIn}>
              <div className={styles.listHeadD}>
                <h3>Bulk Upload list</h3>
                <p>Manage all the bulk orders</p>
              </div>
              <div className={styles.listHeadBtn}>
                <Button
                  variant="contained"
                  className={styles.button1}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Add Bulk Upload
                </Button>
              </div>
            </div>
            <div className={styles.fileListFilter}>
              <FormControl sx={{ minWidth: 180 }}>
                <InputLabel id="type-label">File Type</InputLabel>
                <Select
                  value={bulkUploadFilters?.fileType}
                  onChange={(e) => {
                    dispatch(
                      setBulkUploadFilter({
                        fileType: e.target.value
                      })
                    );
                  }}
                  labelId="type-label"
                  label="File Type"
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    color: {
                      NOVATTI: "",
                      DEPOSIT: "",
                      "": "grey",
                      default: "grey"
                    }[(bulkUploadFilters?.fileType && bulkUploadFilters?.fileType[0]) ?? "default"]
                  }}
                >
                  <MenuItem value="" sx={{ color: "grey" }}>
                    None
                  </MenuItem>
                  {fileType?.map((file) => (
                    <MenuItem key={file.id} value={file.id}>
                      {file.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.listTable}>
            <Table
              columns={columns()}
              data={loading ? [] : bulkUploads.list}
              className={styles.tableIn}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={bulkUploads.total ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(limit)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
