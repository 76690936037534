import { Avatar } from "@mui/material";
import styles from "../internalUserListCard.module.scss";
import { bgColorbyName } from "src/variable/randomColor";
import { Users } from "src/page/users/usersSlice";
import HighLighter from "src/components/UI/highlighter/HighLighter";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { lazy } from "react";
import { Link } from "react-router-dom";

const EditUserModal = lazy(() => import("../editUser/EditUserModal"));
// import { dateTimeFormat } from "src/utils/utils";

const columns = (searchText?: string) => [
  {
    title: "Id",
    dataIndex: "userId",
    key: "userId",
    render: (userId: number) => <div className={styles.id}>{userId}</div>
  },
  {
    title: "Display Id",
    dataIndex: "id",
    key: "id",
    render: (id: number) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Date / Time",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    render: (createdAt: string) => {
      if (createdAt === undefined || null) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }
      const formattedDate = moment(createdAt).format("DD/MM/YYYY");
      const formattedTIme = moment(createdAt).format("hh:mm A");

      return (
        <>
          <div className={styles.tableDate}>{formattedDate}</div>
          <div className={styles.tableDate}>{formattedTIme}</div>
        </>
      );
    }
  },
  {
    title: "Member",
    dataIndex: "fullName",
    key: "fullName",
    // width: 150,
    render: (email: string, data: Users) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberImg}>
          {/* <img src={} alt="Member" /> */}
          <Avatar id="av" aria-label="Recipe" style={{ backgroundColor: bgColorbyName(data?.firstName) }}>
            {/* {this.props.userName.charAt(0).toLocaleUpperCase()} */}
            {data?.firstName?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>
            <HighLighter searchText={searchText || ""} text={`${data?.firstName} ${data?.lastName}`} />
          </div>
          <div className={styles.tableMemberEmail}>
            <HighLighter searchText={searchText || ""} text={`${data?.email}`} />
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    // width: 150,
    render: (status: boolean) => (
      <div className={styles.tableStatus}>
        <div className={`${status ? styles.green : styles.grey}`}>{status ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (data: Users) => {
      return (
        <Link to={`/internal-users/${data.userId}`} aria-label={`View details for ${data.firstName} ${data.lastName}`}>
          <VisibilityIcon sx={{ color: "grey" }} />
        </Link>
      );
    }
  },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (data: Users) => {
      return <EditUserModal user={data} />;
    }
  }
];

export default columns;
