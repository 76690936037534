import { useState } from "react";
import styles from "./spendableVisaCard.module.scss";
import { VisaCard, visaBirdLogo } from "src/assets/images/visaCard";

export default function WalletPage() {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={styles.visaCard}>
      <div className={`${styles.cardInner} ${isFlipped ? styles.flipped : ""}`} onClick={handleCardFlip}>
        <div className={styles.cardFront}>{VisaCard}</div>
        <div className={styles.cardBack}>
          <div className={styles.cardBackTop}>
            <strong>RELODABLE</strong>
            <span>PLACARD44361880724</span>
          </div>
          <div className={styles.blacklStrap}></div>
          <div className={styles.cardBackBottom}>
            <div className={styles.instruction}>
              <span>{visaBirdLogo}</span>
              <span className={styles.instruction1}>
                Card issued by Novatti Group Ltd. pursuant to a licence by <strong>Visa Worldwide Pte Limited.</strong>
              </span>
              <strong className={styles.instruction2}>
                For any cardholder enquiries please visit www.spendable.com.au or email info@spendable.com.au The use of
                this card is your acceptance of the card terms and conditions and associated program materials.
              </strong>
            </div>
            <div className={styles.cardInfo}>
              <strong className={styles.cardNumber}>4820 1234 1234 4321</strong>
              <strong className={styles.cardHolderName}>JOHN CITIZEN</strong>
              <div className={styles.validitiy}>
                <div className={styles.validFrom}>
                  <label>Valid from</label>
                  <strong>01/22</strong>
                </div>
                <div className={styles.validTo}>
                  <label>Valid from</label>
                  <strong>01/22</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
