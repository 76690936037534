import styles from "./internalOrganisation.module.scss";
import OrgWelCard from "src/components/orgWelCard/OrgWelCard";
import InternalOrgListCard from "src/components/internalOrgListCard/InternalOrgListCard";

export default function InternalOrganisation() {
  return (
    <div className={styles.organisation}>
      <div className={styles.organisationWelCard}>
        <OrgWelCard />
      </div>
      <div className={styles.organisationTableCard}>
        <InternalOrgListCard />
      </div>
    </div>
  );
}
