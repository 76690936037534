import { CircularProgress } from "@mui/material";
import styles from "./pricingPlanCard.module.scss";
import { useAppSelector } from "src/page/store";
import { planCategoryMapping } from "src/components/constant/constant";

interface PricingPlanCardProps {
  title: string;
  subTitle: string;
  description: string;
  category: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  loading?: boolean;
  containerStyles?: string;
  titleStyles?: string;
  subTitleStyles?: string;
  buttonStyles?: string;
}

export default function PricingPlanCard({
  title,
  description,
  category,
  buttonLabel = "Upgrade",
  onButtonClick,
  loading = false,
  containerStyles,
  titleStyles,
  buttonStyles
}: PricingPlanCardProps) {
  const { upgradePlanFlag } = useAppSelector((state) => state.auth);

  return (
    <section className={`${styles.cardContainer} ${containerStyles}`} aria-labelledby="plan-title">
      <div className={styles.bookmark} style={{ backgroundColor: category === "BASE" ? "#02519e" : "#ff6f61" }}>
        <span>{planCategoryMapping[category]}</span>
      </div>
      <h2 id="plan-title" className={`${styles.planTitle} ${titleStyles}`}>
        {title}
      </h2>
      {/* <h1 className={`${styles.planPrice} ${subTitleStyles}`}>{subTitle}</h1> */}

      <div className={styles.planDescription} dangerouslySetInnerHTML={{ __html: description }} />
      <div className={styles.subsBtn}>
        <button
          className={`${styles.demoButton} ${buttonStyles}`}
          onClick={(e) => {
            e.preventDefault();
            if (onButtonClick) {
              onButtonClick();
            }
          }}
          disabled={loading || upgradePlanFlag}
        >
          {loading ? (
            <div className={styles.progress}>
              <CircularProgress sx={{ color: "#ffff" }} />
            </div>
          ) : (
            buttonLabel
          )}
        </button>
      </div>
    </section>
  );
}
