import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { SearchWalletRequest, searchWallet } from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";
import { Transaction } from "../transactions/transactionSlice";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";
import { getUserWallets, searchUser } from "src/network/graphql/userService";
import { Users } from "../users/usersSlice";

const initialWallets: Wallet[] = [];

const initialTransaction: Transaction[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: { [key: string]: walletTransaction } = {};

const initialUsers: Users[] = [];

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    walletLoading: false,
    transLoading: false,
    depositLoading: false,
    walletTransLoading: false,
    userLoading: false,
    participantLoading: false,
    participantWalletLoading: false,
    error: null,
    topWallets: {
      wallets: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    depositList: {
      list: initialTransaction,
      total: 0
    },
    walletTransaction: initialwalletTransaction,
    users: {
      list: initialUsers,
      total: 0
    },
    participants: {
      list: initialUsers,
      total: 0
    },
    participantWallet: {
      wallets: initialWallets,
      total: 0
    }
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.topWallets.wallets = action.payload?.list ?? [];
      state.topWallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.topWallets.wallets = [];
      state.topWallets.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchDepositListStart: (state) => {
      state.depositLoading = true;
    },
    fetchDepositListSuccess: (state, action) => {
      state.depositLoading = false;
      state.depositList.list = action.payload?.list ?? [];
      state.depositList.total = action.payload?.total ?? 0;
    },
    fetchDepositListFail: (state, action) => {
      state.depositLoading = false;
      state.depositList.list = [];
      state.depositList.total = 0;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = {};
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    fetchParticpantStart: (state) => {
      state.participantLoading = true;
    },
    fetchParticpantSuccess: (state, action) => {
      state.participantLoading = false;
      state.participants.list = action.payload?.list ?? [];
      state.participants.total = action.payload?.total ?? 0;
    },
    fetchParticpantFail: (state, action) => {
      state.participantLoading = false;
      state.participants.list = [];
      state.participants.total = 0;
      state.error = action.payload;
    },
    fetchParticipantWalletStart: (state) => {
      state.participantWalletLoading = true;
    },
    fetchParticipantWalletSuccess: (state, action) => {
      state.participantWalletLoading = false;
      state.participantWallet.wallets = action.payload?.list ?? [];
      state.participantWallet.total = action.payload?.total ?? 0;
    },
    fetchParticipantWalletFail: (state, action) => {
      state.participantWalletLoading = false;
      state.participantWallet.wallets = [];
      state.participantWallet.total = 0;
      state.error = action.payload;
    },
    resetParticipantWallet: (state, action) => {
      state.participantWallet.wallets = action.payload.list;
      state.participantWallet.total = action.payload?.total;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  fetchDepositListStart,
  fetchDepositListSuccess,
  fetchDepositListFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchParticpantStart,
  fetchParticpantSuccess,
  fetchParticpantFail,
  fetchParticipantWalletStart,
  fetchParticipantWalletSuccess,
  fetchParticipantWalletFail
} = dashboardSlice.actions;

export const { resetParticipantWallet } = dashboardSlice.actions;

export const fetchTopWalletAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        participant: { role: "PARTICIPANT", fullName: wallet.name }
      }));

      dispatch(fetchWalletsSuccess({ list: structuredResponse, total: structuredResponse.length }));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        approval_number: data.approval_number,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchTransListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};

export const fetchDepositListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchDepositListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        approval_number: data.approval_number,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchDepositListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchDepositListFail(err));
    }
  };
};

export const fetchWalletTransactionAction = (data: any) => {
  return data;
};

export const fetchParticpantAction = (
  data: {
    isActive?: boolean | null;
    email?: string;
    fullName?: string;
    organisationId?: string | null;
    limit?: number;
    from?: number;
    role?: string;
  },
  cb?: () => void
) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchParticpantStart());
    try {
      const response = await searchUser(data);

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user?.display_id,
        firstName: user?.attributes?.first_name,
        lastName: user?.attributes?.last_name,
        email: user?.email,
        role: user?.current_organisation_role,
        status: user?.is_active,
        userId: user?.user_id,
        createdAt: user?.created_at
      }));

      dispatch(
        fetchParticpantSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchParticpantFail(error));
    }
  };
};

export const fetchParticipantWalletAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchParticipantWalletStart());
    try {
      const response = await getUserWallets(userId);

      const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        // status: wallet.wallet_status,
        walletType: wallet.wallet_type,
        name: wallet.name,
        participant: {
          role: "PARTICIPANT",
          email: wallet?.user?.email,
          fullName: wallet?.user?.attributes?.first_name + " " + wallet?.user?.attributes?.last_name
        }
      }));

      dispatch(
        fetchParticipantWalletSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
    } catch (error) {
      dispatch(fetchParticipantWalletFail(error));
    }
  };
};

export const fetchUserListAction = (
  data: {
    isActive?: boolean | null;
    email?: string;
    fullName?: string;
    organisationId?: string | null;
    limit?: number;
    from?: number;
    role?: string;
  },
  cb?: () => void
) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        user_filter: {
          is_active: data.isActive,
          email: data.email,
          full_name: data.fullName,
          organisation_id: data.organisationId,
          role: data.role
        },
        size: data.limit,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user?.display_id,
        firstName: user?.attributes?.first_name,
        lastName: user?.attributes?.last_name,
        email: user?.email,
        role: user?.current_organisation_role,
        status: user?.is_active,
        userId: user?.user_id,
        createdAt: user?.created_at
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export default dashboardSlice.reducer;
