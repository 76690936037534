import CloseIcon from "@mui/icons-material/Close";
import styles from "./videoModal.module.scss";

type VideoModalProp = {
  handleClose: () => void;

  videoLink: string;
};

export default function VideoModal({ handleClose, videoLink }: VideoModalProp) {
  const handleCancelClose = () => {
    handleClose();
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <div className={styles.close}>
          <button onClick={handleCancelClose} aria-label="Close">
            <CloseIcon />
          </button>
        </div>
        <div>
          <iframe
            width="700"
            height="400"
            src={videoLink}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
