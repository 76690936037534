// import DownloadInternalFile from "src/components/downloadInternalFile/DownloadInternalFile";
import { dateTimeFormat } from "src/utils/utils";
import styles from "./childrenPlans.module.scss";
import PlanPreviewModal from "src/components/subscriptionPlan/planPreviewModal/PlanPreviewModal";
import { SubsList } from "../plans/plansSlice";

const columns = [
  {
    title: "Id",
    dataIndex: "subsId",
    key: "subsId"
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date: string) => <div>{dateTimeFormat(date).date}</div>
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "SubTitle",
    dataIndex: "subTitle",
    key: "subTitle"
  },
  {
    title: "Organisation Type",
    dataIndex: "orgType",
    key: "orgType"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    // width: 150,
    render: (status: boolean) => (
      <div className={styles.tableStatus}>
        <div className={`${status ? styles.green : styles.grey}`}>{status ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  {
    title: "Preview",
    dataIndex: "preview",
    key: "preview",
    render: (preview: string, data: SubsList) => (
      <PlanPreviewModal
        title={data.title}
        subTitle={data.subTitle}
        description={data.description}
        category={data.subscription_category}
      />
    )
  }
];

export default columns;
