import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./internalUserListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Button from "@mui/material/Button";
import Table from "rc-table";
import columns from "./listTable/internalUserListTable";
import Modalui from "src/components/UI/modal/Modalui";
import AddUserModal from "./addUserModal/AddUserModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { fetchUserListAction, addUserAction } from "src/page/internalUser/internalUserSlice";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { CAN_ADD_USER, roleMapping } from "../constant/constant";
import { CreateUserRequest } from "src/network/graphql/userService";
import { checkPermission, removeNull } from "src/utils/utils";
import CircularLoader from "../UI/circularLoader/CircularLoader";
import { setColumnFilter } from "src/page/filtersSlice";
import TableColumnFilter from "../UI/tableColumnFIlter/TableColumnFilter";
import SearchBar from "../UI/searchBar/SearchBar";
import { USER_LIMIT, USER_PAGE } from "../constant/queryConstants";
import { useSearchParams } from "react-router-dom";

export default function InternalUserListCard() {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const userLimit = searchParams.get(USER_LIMIT) ?? "10";
  const userPage = searchParams.get(USER_PAGE) ?? "0";
  const [role, setRole] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const { users, userLoading } = useAppSelector((state) => state.internalUser);
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const { column: columnFilter } = useAppSelector((state) => state.filters);

  const dispatch = useAppDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value !== "Role") {
      setRole(e.target.value);
    } else {
      setRole(null);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(USER_PAGE, newPage + "");
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(USER_LIMIT, event.target.value);
    searchParams.delete(USER_PAGE);
    setSearchParams(searchParams);
  };

  const fetchUserList = useCallback(() => {
    const rowsPerPage = parseInt(userLimit);
    const page = parseInt(userPage);
    const text = searchText.trim() === "" ? undefined : searchText.trim();
    const data = {
      limit: rowsPerPage,
      from: page * rowsPerPage,
      fullName: text,
      filterContext: role,
      // isActive: status,
      organisationId: undefined,
      role
    };

    dispatch(fetchUserListAction(removeNull(data)));
  }, [dispatch, userPage, role, userLimit, searchText]);

  const handleAddUser = (userData: CreateUserRequest, close: boolean, cb?: () => void) => {
    dispatch(
      addUserAction(userData, () => {
        fetchUserList();
        if (close) {
          handleClose();
        }
        if (cb) {
          cb();
        }
      })
    );
  };

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  const filteredColumns = useMemo(() => {
    return columns(searchText).filter((col) => columnFilter?.internalUser?.includes(col.key));
  }, [columnFilter?.internalUser, searchText]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        user: selectedColumns
      })
    );
  };
  // const onUnlinkWallet = (email: string, supporterId: number | string, removeItFromAll?: boolean, cb?: () => void) => {
  //   const data = {
  //     organisationId: cognitoConfig.currentOrganisation?.organisation_id ?? "",
  //     userId: String(supporterId)
  //   };

  //   dispatch(suspendUserAction(data)).then(() => {
  //     fetchUserList();
  //   });
  //   if (cb) {
  //     cb();
  //   }
  // };

  // const deleteInput = {
  //   buttonInfo: "Unlink",
  //   whomToRemove: "user's",
  //   headInfo: "Are you sure, you want to unlink this user?"
  // };

  return (
    <>
      <div className={styles.userList}>
        <div className={styles.userListHead}>
          <div className={styles.userListHeadIn}>
            <div className={styles.userListHeadD}>
              <h3>Users list</h3>
              <p>See information about all of your connected users</p>
            </div>
            <div className={styles.userListHeadBtn}>
              {checkPermission(CAN_ADD_USER) && (
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  className={styles.addUserBtn}
                  startIcon={<PersonAddAlt1Icon />}
                >
                  Add User
                </Button>
              )}
              <Modalui open={open} handleClose={handleClose} modaluiOver={styles.addUserModal}>
                <AddUserModal handleClose={handleClose} onAddUser={handleAddUser} />
              </Modalui>
            </div>
          </div>
          <div className={styles.userListFilter}>
            <div className={styles.filterSearch}>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={role ? role : "Role"}
                  onChange={handleRoleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  <MenuItem value="Role">Role</MenuItem>
                  {cognitoConfig?.rolesAllowedToAdd &&
                    cognitoConfig?.rolesAllowedToAdd.map((roles) => (
                      <MenuItem value={roles}>{roleMapping[roles]}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className={styles.userListSearch}>
              <div className={styles.searchBar}>
                <SearchBar onSearch={handleSearch} />
              </div>
            </div>
          </div>
          <TableColumnFilter
            columns={columns()}
            selectedColumn={columnFilter?.internalUser}
            mandatoryColumns={["userId", "id", "operations"]}
            onApply={handleColumnSelection}
          />
        </div>
        <div className={styles.userListTable}>
          <Table
            columns={filteredColumns}
            className={styles.tableIn}
            data={userLoading ? [] : users.list}
            components={
              userLoading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={users.total}
            page={parseInt(userPage)}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(userLimit)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
