import { Button, CircularProgress } from "@mui/material";
import styles from "./changeOrgModal.module.scss";
import { useAppSelector } from "src/page/store";

interface Iprops {
  handleClose: () => void;
  onConfirm: () => void;
}
function ChangeOrgModal({ handleClose, onConfirm }: Iprops) {
  const { switchOrgLoading } = useAppSelector((state) => state.auth);

  return switchOrgLoading ? (
    <div className={styles.progress}>
      <CircularProgress sx={{ color: "#0f172a" }} />
    </div>
  ) : (
    <div className={styles.referModalBox}>
      <header className={styles.referModalTop}>
        <h5>Confirmation</h5>
      </header>
      <main className={styles.body}>Are you sure, you want to change your organisation?</main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <div className={styles.btnL}>
            <Button className={styles.reportBtn} variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div className={styles.btnR}>
            <Button className={styles.sendBtn} variant="contained" onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default ChangeOrgModal;
