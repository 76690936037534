export const TimelineSteps = [
  {
    id: 1,
    name: "Add Organisation",
    subsections: [
      {
        id: "1a",
        name: "Organisation"
      }
    ]
  },
  {
    id: 2,
    name: "Add a Participant",
    subsections: [
      {
        id: "2a",
        name: "Participant Info"
      }
    ]
  },
  {
    id: 3,
    name: "Add Wallet",
    subsections: [
      {
        id: "3a",
        name: "Wallet Info"
      }
    ]
  }
];
