import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { searchAsset, searchAssetRequest } from "src/network/graphql/transactionService";
import { InternalFiles } from "../internalFile/internalIFileSlice";

const initialFile: InternalFiles[] = [];

const bulkUploadSlice = createSlice({
  initialState: {
    loading: false,
    error: null,
    bulkUploads: {
      list: initialFile,
      total: 0
    }
  },
  name: "cards",
  reducers: {
    fetchBulkUploadStart: (state) => {
      state.loading = true;
    },
    fetchBulkUploadSuccess: (state, action) => {
      state.loading = false;
      state.bulkUploads = action.payload;
    },
    fetchBulkUploadFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

const { fetchBulkUploadStart, fetchBulkUploadSuccess, fetchBulkUploadFail } = bulkUploadSlice.actions;

export const fetchBulkUploadAction = (data: searchAssetRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchBulkUploadStart());
    try {
      const response = await searchAsset(data);
      const structuredResponse = response.data.search_file.files?.map((data: any) => ({
        assetId: data.file_id,
        bucketKey: data.attributes.key,
        bucketName: data.attributes.bucket_name,
        date: data.attributes.date,
        processStatus: data.process_status,
        assetType: data?.file_type,
        fileName: data?.attributes?.original_filename,
        totalRow: data.attributes.total_rows
      }));

      dispatch(
        fetchBulkUploadSuccess({
          list: structuredResponse,
          total: response.data.search_file.total_size
        })
      );
    } catch (error) {
      dispatch(fetchBulkUploadFail(error));
    }
  };
};

export default bulkUploadSlice.reducer;
