import { useState, ReactElement } from "react";
import Modalui from "src/components/UI/modal/Modalui";
import PricingPlanCard from "../SubscriptionCard/PricingPlanCard";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Tooltip } from "@mui/material";

interface PlanPreviewModalProps {
  title: string;
  subTitle: string;
  description: string;
  category: string;
  icon?: ReactElement; // Add icon prop, optional and can accept any valid React component
}

export default function PlanPreviewModal({ title, subTitle, description, icon, category }: PlanPreviewModalProps) {
  const [preview, setPreview] = useState<boolean>(false);

  return (
    <>
      <Modalui open={preview} handleClose={() => setPreview(false)}>
        <PricingPlanCard title={title} subTitle={subTitle} description={description} category={category} />
      </Modalui>
      <div
        onClick={() => setPreview(true)}
        style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Tooltip title="Preview" placement="top" arrow>
          {icon || <VisibilityOutlinedIcon aria-label="preview plan" />}
        </Tooltip>
      </div>
    </>
  );
}
